import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip, TooltipReference, useTooltipState } from 'reakit/Tooltip';

const Wrapper = styled.div`
  font-size: 10px;
  max-height: 44px;
  line-height: 22px;
  padding: 0 8px;
  background-color: rgb(97, 97, 97);
  color: rgba(255, 255, 255, 0.87);
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 0.15s;
  opacity: 0;
  transform: scale(0);
  margin-left: ${(props) => props.margin};
  transform-origin: ${(props) => props.origin};
  [data-enter] & {
    opacity: 0.9;
    transform: scale(1);
  }
  white-space: pre;
  line-height: ${(props) => props.lineHeight || '2em'};
`;

const ToolTip = ({ children, tooltip, lineHeight, placement }) => {
  const reference = useTooltipState({ placement, animated: 250 });

  const margin = React.useMemo(() => {
    if (placement === 'top') return '-14px';
    if (placement === 'left') return '-14px';
    if (placement === 'right') return '14px';
    if (placement === 'bottom') return '14px';
    return '';
  }, [placement]);

  const origin = React.useMemo(() => {
    if (placement === 'top') return 'bottom center';
    if (placement === 'left') return 'right center';
    if (placement === 'right') return 'left center';
    if (placement === 'bottom') return 'top center';
    return '';
  }, [placement]);

  return (
    <TooltipReference {...reference}>
      {children}
      <Tooltip {...reference}>
        <Wrapper margin={margin} origin={origin} lineHeight={lineHeight}>
          {tooltip}
        </Wrapper>
      </Tooltip>
    </TooltipReference>
  );
};

ToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string.isRequired,
  placement: PropTypes.string,
  lineHeight: PropTypes.string,
};

ToolTip.defaultProps = {
  placement: 'top',
};

export default ToolTip;
