import styled from '@emotion/styled';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';

import Button, { LightButton } from '../../components/Button';
import Input from '../../components/Input';
import Modal, { ModalTitle, ModalText } from '../../components/Modal';
import Snackbar from '../../components/Snackbar';
import AuthService from '../../services/api/auth';

const StyledForm = styled(Form)`
  position: relative;
`;
const StyledSnackbar = styled(Snackbar)`
  position: absolute;
  bottom: 30px;
  max-width: 361px;
  right: 0;
`;
const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
    position: relative;
  }
`;

const StyledInput = (props) => (
  <Input {...props} wrapperStyle={{ margin: '0 0 30px 0', width: '100%' }} />
);

const FormSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  hour_value: Yup.string().min(6, 'Valor de hora inválido'),
});

function currencyFormatter(value) {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);

  return `${amount}`;
}

function onChangeFormater(value) {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);

  return `${amount}`;
}

export default function AddUserModal(props) {
  const { AllocationService, ProjectService, ...otherProps } = props;
  const [message, setMessage] = useState('');
  const [loggedUser, setLoggedUser] = useState(null);
  const [projects, setProjects] = useState([{ id: '' }]);
  const [focusedInputs, setFocusedInputs] = useState({});

  async function awaitProjetcsSet(filters = { all: true }) {
    const response = await props.ProjectService.getFilteredProjects(filters);
    setProjects(response.data);
  }

  function handleFocusedInputs(field) {
    if (!focusedInputs[field]) {
      setFocusedInputs({
        ...focusedInputs,
        [field.name]: true,
      });
    }
  }

  useEffect(() => {
    setLoggedUser(AuthService.getLoggedUser());

    awaitProjetcsSet({
      all: true,
    });
  }, []);

  async function doAddUser({
    name,
    email,
    role,
    hour_value,
    project_id,
    project_role,
  }) {
    try {
      const response = await AllocationService.saveAllocation(
        loggedUser.allocations[0].team,
        {
          email,
          name,
          role,
          hour_value: parseFloat(
            hour_value.replace('R$', '').replace(',', '.'),
          ),
          project_allocation: { project_id, role: project_role },
        },
      );
      return await response.data;
    } catch (e) {
      let errorMessage = e.data && e.data.message;
      if (typeof errorMessage !== 'string') {
        if (e.data.message.user) {
          errorMessage = e.data.message.user;
        } else {
          errorMessage = 'Ops! Não foi possível convidar este email.';
        }
      }

      throw new Error(errorMessage);
    }
  }

  return (
    <Modal
      {...otherProps}
      modalTrasformPosition="translate(-50%, -53%)"
      width="35%"
      height="80%"
      overflowY="scroll"
    >
      <ModalTitle>Adicionar Usuário</ModalTitle>
      <ModalText>
        Ao adicionar um novo usuário você está criando uma nova conta Esta
        pessoa receberá um email com uma senha para entrar no Labor e começar a
        lançar horas no projeto em que foi alocada.
      </ModalText>
      <Formik
        initialValues={{
          name: '',
          email: '',
          role: 'colaborador',
          hour_value: '',
          project_id: projects[0]?.id,
          project_role: 'colaborador',
        }}
        validationSchema={FormSchema}
        onSubmit={(values, { setSubmitting }) => {
          doAddUser(values)
            .then((json) => {
              props.onRequestClose({ json });

              setSubmitting(false);
            })
            .catch((e) => {
              setMessage(e.message);

              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <StyledForm>
            <Input
              type="text"
              name="name"
              value=""
              placeholder="Nome Completo"
              label="Nome do Usuário*"
              style={{ margin: '0 0 30px 0' }}
            />
            <Input
              type="email"
              name="email"
              value=""
              label="E-mail*"
              placeholder="Endereço de e-mail"
              style={{ margin: '0 0 30px 0' }}
            />
            <FormWrapper>
              <StyledInput
                as="select"
                name="role"
                value=""
                label="Perfil & Permissão*"
                style={{ marginRight: '30px' }}
              >
                <option value="administrador" disabled>
                  Administrador
                </option>
                <option value="gerente">Gestor</option>
                <option value="colaborador">Usuário</option>
              </StyledInput>
              <NumberFormat
                format={currencyFormatter}
                value={values.hour_value}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                prefix="R$"
                renderText={(value) => (
                  <StyledInput
                    type="text"
                    name="hour_value"
                    label="Salário (valor da hora)*"
                    placeholder="Exemplo: R$ 40,00"
                    value={value}
                    onChange={(e) =>
                      setFieldValue(
                        'hour_value',
                        onChangeFormater(
                          e.target.value.replace('R$', '').replace(',', ''),
                        ),
                      )
                    }
                    handleOnFocus={handleFocusedInputs}
                    focusedInputs={focusedInputs}
                  />
                )}
              />
            </FormWrapper>
            <FormWrapper>
              {/* <StyledInput
                as="select"
                name="project_id"
                value=""
                type="number"
                label="Projeto*"
                style={{ marginRight: '30px' }}
              >
                {projects.map((project, index) => (
                  <option key={index} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </StyledInput> */}
              <StyledInput
                as="select"
                name="project_role"
                value=""
                label="Função nos Projetos*"
                placeholder=""
              >
                <option value="gerente">Gerente</option>
                <option value="colaborador">Colaborador </option>
              </StyledInput>
            </FormWrapper>
            <LightButton
              style={{ margin: '0 30px 30px 0' }}
              disabled={isSubmitting}
              onClick={() => props.onRequestClose()}
            >
              Cancelar
            </LightButton>
            <Button type="submit" disabled={isSubmitting}>
              Salvar Alterações
            </Button>
            <StyledSnackbar isVisible={!!message}>{message}</StyledSnackbar>
          </StyledForm>
        )}
      </Formik>
    </Modal>
  );
}

AddUserModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  AllocationService: PropTypes.object.isRequired,
  ProjectService: PropTypes.object.isRequired,
};
