import styled from '@emotion/styled';
import { Form } from 'formik';

import Snackbar from '~/components/Snackbar';

export const StyledForm = styled(Form)`
  position: relative;
`;

export const StyledSnackbar = styled(Snackbar)`
  position: absolute;
  bottom: 0;
  max-width: 361px;
  right: 0;
`;
