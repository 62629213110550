import PropTypes from 'prop-types';
import React from 'react';

const InternalPage = ({ InternalService }) => {
  const saveData = (response) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    const blob = new Blob([response.data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'filename.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  function getUsers() {
    InternalService.getUsers({ format: 'csv', owners: true }).then(
      (response) => {
        saveData(response);
      },
      (error) => {
        console.log({ error }); // eslint-disable-line no-console
      },
    );
  }

  return (
    <button type="button" onClick={() => getUsers()}>
      Download CSV Usuários
    </button>
  );
};
InternalPage.propTypes = {
  InternalService: PropTypes.object,
};

export default InternalPage;
