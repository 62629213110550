import React from 'react';

import InputField, { TypesOfInput } from '../InputField';

import { DataContainer, SubTitleText } from './styles';

import type { InitialValuesType } from './index';

interface Props {
  onChange: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined,
  ) => void;
  values: InitialValuesType;
}

// TODO rever se os valores que devem ser iniciais como Não inicializado
// já seleciona por padrão funcionam
const ProjectData: React.FC<Props> = ({ values, onChange }: Props) => (
  <>
    <SubTitleText>Dados do projeto</SubTitleText>
    <DataContainer>
      <InputField
        name="name"
        title="Nome do Projeto*"
        type={TypesOfInput.textColorPicker}
        onChange={onChange}
        colorName={values.tagColor}
      />
      <InputField
        name="client"
        title="Cliente"
        value={values.client}
        type={TypesOfInput.textClient}
        onChange={onChange}
      />
    </DataContainer>
    <DataContainer>
      <InputField
        name="estimatedStartAt"
        title="Data de início estimadaⓘ"
        type={TypesOfInput.dateField}
        dateData={values.estimatedStartAt}
        onChange={(value: string) => {
          onChange('estimatedStartAt', value);
        }}
      />
      <InputField
        name="estimatedEndAt"
        title="Data de término estimadaⓘ"
        type={TypesOfInput.dateField}
        dateData={values.estimatedEndAt}
        onChange={(value: string) => onChange('estimatedEndAt', value)}
      />
    </DataContainer>
    <DataContainer>
      <InputField
        name="status"
        title="Status"
        type={TypesOfInput.statusList}
        onChange={onChange}
      />
      {values.category === 'external' ? (
        <InputField
          name="estimatedHours"
          onChange={onChange}
          title="Horas estimadas*"
          type={TypesOfInput.estimatedHours}
          initialHours={values.estimatedHours}
        />
      ) : null}
    </DataContainer>
    {values.category === 'external' ? (
      <>
        <SubTitleText>Dados para lucratividade</SubTitleText>

        <DataContainer>
          <InputField
            name="price"
            title="Valor cobrado*"
            type={TypesOfInput.totalCharged}
            onChange={onChange}
          />
          <InputField
            name="priceHour"
            title="Valor da hora"
            type={TypesOfInput.hourlyCharged}
            totalPrice={values.price}
            estimatedHours={values.estimatedHours}
          />
        </DataContainer>
      </>
    ) : null}
  </>
);

export default ProjectData;
