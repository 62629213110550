import styled from '@emotion/styled';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { MdArrowBack, MdInfoOutline } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';

import Button from '../../components/Button';
import Input from '../../components/Input';
import Snackbar from '../../components/Snackbar';
import { StepBackWrapper } from '../../components/StepBackWrapper';
import AuthService from '../../services/api/auth';
import Can, { ability } from '../components/Can';
import Header from '../components/Header';
import PageTitle from '../components/PageTitle';
import Toggle from '../components/Toggle';
import User from '../models/User';

import ProjectAllocationList from './ProjectAllocationList';
import UserAllocationModal from './components/UserAllocationModal';
import UserWageHistoryModal from './components/UserWageHistoryModal';


const StyledInput = (props) => (
  <Input {...props} wrapperStyle={{ margin: '0 30px 30px 0', width: '100%' }} />
);

const StyledForm = styled(Form)`
  position: relative;
`;

const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
    position: relative;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
    position: relative;
  }
`;

const CaptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Caption = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

const CaptionLink = styled.a`
  text-decoration: underline;
  color: #4f587a;
`;

const StyledSnackbar = styled(Snackbar)`
  position: absolute;
  bottom: 0;
  max-width: 361px;
  right: 0;
`;

const UserInfoWrapper = styled.div`
  margin: 0 60px 30px;
`;

const TooltipContent = styled.span`
  font-size: 12px;
  margin-left: 5px;
  font-color: #959595;
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FormSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  hour_value: Yup.string().transform((value) => value.replace(',', '.')),
});

const Border = styled.div`
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 20px;
  margin: '30px 0 60px 30px';
`;

const StyledButton = styled(Button)`
  margin: 30px 0 60px 60px;
`;

function currencyFormatter(value) {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);

  return `${amount}`;
}

function onChangeFormater(value) {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value / 100);

  return `${amount}`;
}

function initialCurrencyFormatter(value) {
  if (!Number(value)) return '';

  return value.toFixed(2);
}

function dateFormatter(year, month) {
  if (!(year && month)) return '';

  return new Date(year, month);
}

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Julho',
  'Junho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export default function UserProfile({
  $state,
  $stateParams,
  UserService,
  AllocationService,
  NotificationService,
  ProjectAllocationService,
  ProjectService,
  ReportService,
}) {
  const [loggedUser, setLoggedUser] = useState(null);
  const [message, setMessage] = useState('');
  const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);
  const [isAllocationModalOpen, setAllocationModalOpen] = useState(false);
  const [user, setUser] = useState([]);
  const [allocation, setAllocation] = useState({ enabled: false });

  useEffect(() => {
    setLoggedUser(AuthService.getLoggedUser());
  }, []);

  function redirectToUsersPage() {
    $state.go('app.main.users');
  }

  async function loadUser() {
    if (!loggedUser) return;
    const response = await UserService.getUser({
      id: $stateParams.userId,
    });
    setUser(response.data);
    setAllocation(
      response.data.allocations.find(
        (x) => x.team.id === loggedUser.allocations[0].team.id,
      ),
    );
    setMessage('');
    if (!(ability.can('read', 'User') || response.data.id === loggedUser.id)) {
      redirectToUsersPage();
    }
  }

  useEffect(() => {
    loadUser();
  }, [loggedUser]);

  function handleCloseHistoryModal() {
    setHistoryModalOpen(false);
  }

  function handleCloseAllocationModal() {
    setAllocationModalOpen(false);
  }

  function disabled() {
    return !(
      allocation.enabled && ability.can('manage', new User({ allocation }))
    );
  }

  function setUserWageParams(hour_value, year, month) {
    const formated_hour_value = parseFloat(
      hour_value.replace('R$', '').replace(',', '.'),
    );

    if (formated_hour_value === user.currentHourValue) return '';

    return {
      id: null,
      hour_value: formated_hour_value,
      start_date: dateFormatter(year, month),
    };
  }

  async function UpdateUser({
    name,
    email,
    role,
    hour_value,
    new_user_wage_month,
    new_user_wage_year,
  }) {
    try {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      if (
        new_user_wage_year === currentYear &&
        new_user_wage_month <= currentMonth
      ) {
        throw Error({
          data: { message: { userWages: ['não pode ser retroativo'] } },
        });
      } else {
        const response = await UserService.updateUser({
          user: {
            email,
            name,
            allocations_attributes: {
              id: allocation.id,
              role,
            },
            user_wages_attributes: setUserWageParams(
              hour_value,
              new_user_wage_year,
              new_user_wage_month,
            ),
            id: user.id,
          },
        });
        return await response.data;
      }
    } catch (e) {
      let errorMessage = e.data && e.data.message;
      if (typeof errorMessage !== 'string') {
        if (e.data.message.user) {
          errorMessage = e.data.message.user;
        } else if (e.data.message.userWages) {
          errorMessage = 'Ops! Não é possível alterar um salário retroativo';
        } else if (e.data.message['userWages.startDate']) {
          errorMessage =
            'Ops! Para adicionar um novo valor da hora é necessária a data de início do salário';
        } else {
          errorMessage = 'Ops! Não foi possível editar este usuário';
        }
      }

      throw new Error(errorMessage);
    }
  }

  async function handleRestoreAllocation(innerAllocation) {
    try {
      await AllocationService.restoreAllocation(
        innerAllocation.team,
        innerAllocation,
      );

      setAllocation({ ...innerAllocation, enabled: true });

      NotificationService.showNotification('Usuário ativado com sucesso!');
    } catch (e) {
      NotificationService.showNotification(
        'Ocorreu um erro ao alterar ativação do usuário!',
        'error',
      );
    }
  }

  async function handleEnabledChange({ enabled }) {
    if (enabled) {
      await handleRestoreAllocation(allocation);
    } else {
      await setAllocationModalOpen(true);
    }
  }

  async function handleDisableAllocation() {
    try {
      handleCloseAllocationModal();
      await AllocationService.disableAllocation(allocation.team, allocation);

      setAllocation({ ...allocation, enabled: false });

      NotificationService.showNotification('Usuário desativado com sucesso!');
    } catch (e) {
      NotificationService.showNotification(
        'Ocorreu um erro ao alterar ativação do usuário!',
        'error',
      );
    }
  }

  return (
    <>
      <StepBackWrapper label="Voltar">
        <MdArrowBack
          onClick={() => redirectToUsersPage()}
          style={{ cursor: 'pointer' }}
        />
      </StepBackWrapper>
      <Header style={{ margin: '0 60px 30px' }}>
        <PageTitle style={{ justifyContent: 'space-between' }}>
          {user.name}
          <Can do="disable" of="Allocation">
            <Toggle
              checked={allocation.enabled}
              onChange={(event) => {
                handleEnabledChange({ enabled: event.target.checked });
              }}
            />
          </Can>
        </PageTitle>
      </Header>
      <Formik
        initialValues={{
          name: user.name,
          email: user.email,
          role: user.role,
          hour_value: initialCurrencyFormatter(user.currentHourValue),
          new_user_wage_month: '',
          new_user_wage_year: '',
        }}
        validationSchema={FormSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          UpdateUser(values)
            .then(() => {
              loadUser();
              NotificationService.showNotification(
                'Usuário editado com sucesso!',
              );
              setSubmitting(false);
            })
            .catch((e) => {
              setMessage(e.message);

              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <StyledForm>
            <UserInfoWrapper>
              <FormWrapper>
                <StyledInput
                  type="text"
                  name="name"
                  disabled={disabled()}
                  value=""
                  placeholder="Nome do Usuário*"
                  label="Nome do Usuário*"
                />
                <StyledInput
                  type="email"
                  name="email"
                  disabled
                  value=""
                  label="E-mail*"
                  placeholder="Endereço de e-mail"
                />
              </FormWrapper>
              <FormWrapper>
                <CaptionWrapper style={{ margin: '0 30px 0 0' }}>
                  <InputWrapper>
                    <StyledInput
                      as="select"
                      name="role"
                      disabled={disabled()}
                      value=""
                      label="Perfil & Permissão*"
                    >
                      <option value="administrador">Administrador</option>
                      <option value="gerente">Gestor</option>
                      <option value="colaborador">Usuário</option>
                    </StyledInput>
                    <NumberFormat
                      format={currencyFormatter}
                      value={values.hour_value}
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      prefix="R$"
                      renderText={(value) => (
                        <Input
                          type="text"
                          name="hour_value"
                          label="Salário (valor da hora)*"
                          placeholder="Exemplo: R$ 40,00"
                          disabled={disabled()}
                          wrapperStyle={{ margin: '0 0 30px 0', width: '100%' }}
                          value={value}
                          onChange={(e) =>
                            setFieldValue(
                              'hour_value',
                              onChangeFormater(
                                e.target.value
                                  .replace('R$', '')
                                  .replace(',', ''),
                              ),
                            )
                          }
                        />
                      )}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Caption style={{ margin: '0px 30px 0px 0px' }} />
                    <Caption>
                      <CaptionLink onClick={() => setHistoryModalOpen(true)}>
                        Histórico de salários
                      </CaptionLink>
                    </Caption>
                  </InputWrapper>
                </CaptionWrapper>
                <CaptionWrapper style={{ margin: '0 30px 0 0' }}>
                  <InputWrapper>
                    <StyledInput
                      as="select"
                      name="new_user_wage_month"
                      disabled={disabled()}
                      value=""
                      type="number"
                      label="Data de Início do salário*"
                    >
                      <option defaultValue>Selecione</option>
                      {months.map((month, index) => (
                        <option key={index} value={index + 1}>
                          {month}
                        </option>
                      ))}
                    </StyledInput>
                    <Input
                      as="select"
                      name="new_user_wage_year"
                      disabled={disabled()}
                      value=""
                      placeholder=""
                      wrapperStyle={{ margin: '0 0 30px 0', width: '100%' }}
                    >
                      <option defaultValue>Selecione</option>
                      {Array(6)
                        .fill('')
                        .map((v, idx) => new Date().getFullYear() + idx)
                        .map((year, index) => (
                          <option key={index} value={year}>
                            {year}
                          </option>
                        ))}
                    </Input>
                  </InputWrapper>
                  <InputWrapper>
                    <TooltipWrapper>
                      <MdInfoOutline
                        style={{ fontSize: '12px', color: '#959595' }}
                      />
                      <TooltipContent>
                        A virada de novo salário acontece no primeiro dia do mês
                      </TooltipContent>
                    </TooltipWrapper>
                  </InputWrapper>
                </CaptionWrapper>
              </FormWrapper>
            </UserInfoWrapper>
            <ProjectAllocationList
              $state={$state}
              userId={$stateParams.userId}
              ProjectAllocationService={ProjectAllocationService}
              NotificationService={NotificationService}
              ProjectService={ProjectService}
              disabled={disabled()}
            />

            <Border />

            <StyledButton type="submit" disabled={isSubmitting || disabled()}>
              Salvar Alterações
            </StyledButton>
            <StyledSnackbar isVisible={!!message}>{message}</StyledSnackbar>
          </StyledForm>
        )}
      </Formik>

      <Can do="disable" of="Allocation">
        <UserAllocationModal
          isOpen={isAllocationModalOpen}
          onRequestClose={handleCloseAllocationModal}
          minWidth="500px"
          maxHeight="100%"
          maxWidth="100%"
          allocation={allocation}
          handleDisableAllocation={handleDisableAllocation}
        />
      </Can>

      <UserWageHistoryModal
        isOpen={isHistoryModalOpen}
        onRequestClose={handleCloseHistoryModal}
        minWidth="500px"
        maxHeight="100%"
        maxWidth="100%"
        UserService={UserService}
        ReportService={ReportService}
        userWages={user.userWages}
        userName={user.name}
        userId={$stateParams.userId}
      />
    </>
  );
}

UserProfile.propTypes = {
  UserService: PropTypes.object.isRequired,
  ProjectAllocationService: PropTypes.object.isRequired,
  ReportService: PropTypes.object.isRequired,
  AllocationService: PropTypes.object.isRequired,
  ProjectService: PropTypes.object.isRequired,
  $state: PropTypes.shape({
    go: PropTypes.func,
  }),
  $stateParams: PropTypes.shape({
    userId: PropTypes.string,
  }),
  NotificationService: PropTypes.shape({
    showNotification: PropTypes.func,
  }),
};
