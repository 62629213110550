import styled from '@emotion/styled';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { MdArrowForward } from 'react-icons/md';

import { LightButton } from '../../components/Button';
import IconContainer from '../../components/IconContainer';
import AuthService from '../../services/api/auth';
import Can from '../components/Can';
import { ListHeader, Item, Column } from '../components/List';
import Select from '../components/Select';

import CreateProjectAllocation from './components/CreateProjectAllocation';
import DeleteProjectAllocation from './components/DeleteProjectAllocation';


const ProjectAllocationListWrapper = styled.div`
  margin: 0 0 30px 0;
`;
const ProjectContainer = styled.div``;

const StyledListHeader = styled(ListHeader)`
  margin-left: 20px;
`;

const ButtonWrapper = styled.div`
  margin: 30px 0 60px 60px;
`;

const ProjectItem = styled(Item)`
  opacity: opactityProjectRow(projectAllocation);
  border: 1px solid rgba(149, 149, 149, 0.2);
  height: 50px;
  padding: 0 75px 0 60px;
  border-bottom: none;
  &:last-child {
    border: 1px solid rgba(149, 149, 149, 0.2);
  }
`;

export default function ProjectAllocationList({
  $state,
  userId,
  ProjectAllocationService,
  NotificationService,
  ProjectService,
  disabled,
}) {
  const [loggedUser, setLoggedUser] = useState(null);
  const [focusedProjectAllocation, setFocusedProjectAllocation] = useState();
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [projectAllocations, setProjectAllocations] = useState([]);
  const [selectedProjectAllocation, setSelectedProjectAllocation] = useState();

  const SelectDivStyle = {
    // margin: "-0.8rem 0 -0.8rem 0",
  };
  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      fontSize: '14px', // eslint-disable-next-line
      color: isSelected ? '#FFFFFF' : isFocused ? '#5F5F5F' : '#575551', // eslint-disable-next-line
    }),
    control: (provided) => ({
      ...provided,
      // width: 'auto',
      // margin: "-1.0rem 0 -1rem 0",
      borderTop: 'none',
      borderBottom: 'none',
      borderLeft: '1px solid rgba(149, 149, 149, 0.2)',
      borderRight: '1px solid rgba(149, 149, 149, 0.2)',
      height: '50px',
      borderRadius: '0px',
      boxShadow: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '14px',
      width: 'auto',
    }),
  };
  const roles = [
    { value: 'gerente', label: 'Gerente' },
    { value: 'colaborador', label: 'Colaborador' },
  ];

  useEffect(() => {
    setLoggedUser(AuthService.getLoggedUser());
  }, []);

  async function loadProjectAllocation() {
    if (!loggedUser) return;
    const response = await ProjectAllocationService.getAllProjectAllocatons({
      id: userId,
    });
    setProjectAllocations(response.data);
  }

  useEffect(() => {
    loadProjectAllocation();
  }, [loggedUser]);

  function redirectToProject(e, project) {
    $state.go('app.main.projects-dashboard.planned-tasks', {
      projectId: project.id,
    });
  }

  function handleOpenDeleteModal(projectAllocation) {
    setSelectedProjectAllocation(projectAllocation);
    setDeleteModalOpen(true);
  }

  function handleCloseDeleteModal() {
    setDeleteModalOpen(false);
  }

  function handleCloseCreateModal(params) {
    if (params && params.json) {
      loadProjectAllocation();
      NotificationService.showNotification('Sucesso! Novo projeto adicionado.');
    }

    setCreateModalOpen(false);
  }

  async function deleteProjectAllocation(ProjectAllocation) {
    try {
      await ProjectAllocationService.deleteProjectAllocation({
        id: ProjectAllocation.id,
        user: ProjectAllocation.user,
      });
      NotificationService.showNotification(
        'Sucesso! O usuário foi removido do projeto',
      );
      setDeleteModalOpen(false);
      loadProjectAllocation();
    } catch (e) {
      let message = e.data && e.data.message;
      if (typeof message !== 'string') {
        if (e.data.message.user) {
          message = e.data.message.user;
        } else {
          message = 'Ops! Não foi possível retirar esta alocação.';
        }
      }

      throw new Error(message);
    }
  }

  async function handleRoleChange({ projectAllocation, project_role }) {
    try {
      await ProjectAllocationService.updateProjectAllocation({
        id: projectAllocation.id,
        body: { role: project_role },
        user: projectAllocation.user.id,
      });

      NotificationService.showNotification(
        'Sucesso! Função do usuário no projeto foi editada',
      );
      loadProjectAllocation();
    } catch (e) {
      let message = e.data && e.data.message;
      if (typeof message !== 'string') {
        if (e.data.message.user) {
          message = e.data.message.user;
        } else {
          message = 'Ops! Não foi possível editar esta alocação.';
        }
      }

      throw new Error(message);
    }
  }

  return (
    <ProjectAllocationListWrapper>
      <StyledListHeader>
        <Column flex={3}>Projetos</Column>
        <Column flex={2}>Função</Column>
        <Column flex={1} />
        <Column style={{ flex: '0.1' }} />
      </StyledListHeader>
      <ProjectContainer>
        {projectAllocations.map((projectAllocation) => {
          const { role, project } = projectAllocation;
          return (
            <ProjectItem
              key={project.id}
              onMouseEnter={() =>
                setFocusedProjectAllocation(projectAllocation)
              }
              onMouseLeave={() => setFocusedProjectAllocation(null)}
            >
              <Column flex={1}>{project.name}</Column>
              <Column flex={0.5}>
                <div style={SelectDivStyle}>
                  <Select
                    options={roles}
                    customStyles={customStyles}
                    value={roles.find((r) => r.value === role)}
                    isDisabled={disabled}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(selected) =>
                      handleRoleChange({
                        projectAllocation,
                        project_role: selected.value,
                      })
                    }
                  />
                </div>
              </Column>
              <Column flex={0.5}>
                {focusedProjectAllocation &&
                  projectAllocation === focusedProjectAllocation &&
                  !disabled && (
                    <Can do="destroy" of="ProjectAllocation">
                      <LightButton
                        type="button"
                        style={{ padding: '5px 10px' }}
                        onClick={() => handleOpenDeleteModal(projectAllocation)}
                      >
                        Retirar deste Projeto
                      </LightButton>
                    </Can>
                  )}
              </Column>
              <Column style={{ height: '22px', flex: '0.1' }}>
                {focusedProjectAllocation &&
                projectAllocation === focusedProjectAllocation ? (
                  <IconContainer>
                    <MdArrowForward
                      data-testid="arrow-icon"
                      onClick={(e) => redirectToProject(e, project)}
                      style={{ cursor: 'pointer' }}
                    />
                  </IconContainer>
                ) : (
                  <></>
                )}
              </Column>
            </ProjectItem>
          );
        })}
      </ProjectContainer>
      <ButtonWrapper>
        <Can do="create" of="ProjectAllocation">
          <LightButton
            type="button"
            hasIcon
            disabled={disabled}
            onClick={() => {
              setCreateModalOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
            Adicionar Projeto
          </LightButton>
        </Can>
      </ButtonWrapper>

      <Can do="create" of="ProjectAllocation">
        <CreateProjectAllocation
          isOpen={isCreateModalOpen}
          onRequestClose={handleCloseCreateModal}
          minWidth="500px"
          maxHeight="100%"
          maxWidth="100%"
          userId={userId}
          ProjectService={ProjectService}
          ProjectAllocationService={ProjectAllocationService}
        />
      </Can>

      <Can do="delete" of="ProjectAllocation">
        <DeleteProjectAllocation
          isOpen={isDeleteModalOpen}
          onRequestClose={handleCloseDeleteModal}
          minWidth="500px"
          maxHeight="100%"
          maxWidth="100%"
          ProjectAllocation={selectedProjectAllocation}
          deleteProjectAllocation={deleteProjectAllocation}
        />
      </Can>
    </ProjectAllocationListWrapper>
  );
}

ProjectAllocationList.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  userId: PropTypes.string,
  ProjectService: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  $state: PropTypes.shape({
    go: PropTypes.func,
  }),
  ProjectAllocationService: PropTypes.shape({
    getAllProjectAllocaton: PropTypes.func,
    deleteProjectAllocation: PropTypes.func,
    updateProjectAllocation: PropTypes.func,
    getAllProjectAllocatons: PropTypes.func,
  }),
  NotificationService: PropTypes.shape({
    showNotification: PropTypes.func,
  }),
};
