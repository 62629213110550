import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import Button from '~/components/Button';
import Input from '~/components/Input';
import Modal, { ModalTitle, ModalText } from '~/components/Modal';
import API from '~/services/api';

import * as S from './styles';

interface ForgotPasswordModalProps {
  isOpen: boolean;
  onRequestClose: (data?: object) => void;
  width: string;
}

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
});

async function doForgotPassword({ email }) {
  const response = await API.auth.forgotPassword({
    email,
  });

  const json = await response.json();

  if (response.status !== 200) {
    throw new Error('Este email não está cadastrado.');
  }

  return json;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  onRequestClose,
  ...rest
}) => {
  const [message, setMessage] = useState<string>('');

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Modal {...rest} onRequestClose={onRequestClose}>
      <ModalTitle>Esqueci minha senha</ModalTitle>
      <ModalText>
        Insira o seu e-mail para enviarmos instruções para a restauração de sua
        senha.
      </ModalText>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          doForgotPassword(values)
            .then((json) => {
              onRequestClose({ json });

              setSubmitting(false);
            })
            .catch((e) => {
              setMessage(e.message);

              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <S.StyledForm>
            <Input
              type="email"
              name="email"
              value=""
              placeholder="E-mail"
              style={{ margin: '0 0 30px 0' }}
            />
            <Button type="submit" disabled={isSubmitting}>
              Enviar
            </Button>
            <S.StyledSnackbar isVisible={!!message}>{message}</S.StyledSnackbar>
          </S.StyledForm>
        )}
      </Formik>
    </Modal>
  );
};

export default ForgotPasswordModal;
