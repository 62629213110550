import React from 'react';


import formatCurrency from '~/app/utils/currency';
import { convertDate } from '~/helpers/date';
import useTeamStore from '~/store/team';

import * as S from './styles';

import type { State } from '~/models/types';

interface SubscriptionInfoProps {
  $state: State;
  setTeamModal: Function;
  setTeamModalCancelConfirm: Function;
}

const SubscriptionInfo: React.FC<SubscriptionInfoProps> = ({
  $state,
  setTeamModal,
  setTeamModalCancelConfirm,
}: SubscriptionInfoProps) => {
  const { team } = useTeamStore();
  const navigateForSubscribe = () => {
    $state.go('app.main.subscribe', {});
    setTeamModal(false);
  };

  if (team.subscription.validForever)
    return (
      <S.Container>
        <S.Paragraph>
          Sua assinatura não tem prazo para acabar. Aproveite!
        </S.Paragraph>
        <S.Paragraph>
          Qualquer dúvida entre em contato conosco pelo e-mail:{' '}
          <a href="mailto:contato@getlabor.com.br">contato@getlabor.com.br</a>.
        </S.Paragraph>
      </S.Container>
    );

  return (
    <S.Container>
      {team.subscription.currentPlan !== 'Trial' ? (
        <S.Paragraph>
          <strong>
            Seu time está no Plano{' '}
            {team.subscription.currentPlan === 'Mensal' ? 'Mensal' : 'Anual'} no
            valor de
            {` ${formatCurrency(team.subscription.price)}`}/
            {team.subscription.currentPlan === 'Mensal' ? 'mês' : 'ano'}.
          </strong>
        </S.Paragraph>
      ) : (
        <S.Paragraph>
          <strong>Seu time está no Período de Teste.</strong>
        </S.Paragraph>
      )}
      {team.subscription.currentPlan !== 'Trial' ? (
        <S.Paragraph>
          A próxima cobrança será em{' '}
          <strong>
            {` ${convertDate(team.subscription.nextChargeOn, 'DD-MM-YYYY')}`}.
          </strong>
          .
        </S.Paragraph>
      ) : (
        <S.Paragraph>
            Seu acesso está liberado até
            <strong>
              {` ${convertDate(
                team.subscription.trialExpiresOn,
                'DD-MM-YYYY',
              )}`}
              .
            </strong>
          </S.Paragraph>
      )}

      {team.subscription.currentPlan === 'Trial' ? (
        <S.StyledButton onClick={navigateForSubscribe}>
            Assinar plano
          </S.StyledButton>
      ) : (
        <>
          <S.SmallText>
            Caso deseje cancelar a sua assinatura clique no botão abaixo:
          </S.SmallText>
          <S.StyledCancellButton
            onClick={() => {
              setTeamModalCancelConfirm(() => true);
              setTeamModal(() => false);
            }}
          >
            <S.SmallTextButton>Cancelar minha assinatura</S.SmallTextButton>
          </S.StyledCancellButton>
        </>
      )}
    </S.Container>
  );
};

export default SubscriptionInfo;
