import React, { useEffect, useState } from 'react';

import Button, { LightButton } from '~/components/Button';
import project from '~/services/api/project';
import team from '~/services/api/team';
import trello from '~/services/api/trello';

import UnconnectedTrelloSettings from './UnconnectedTrello';
import * as TS from './UnconnectedTrello/styles';
import * as S from './styles';

const selectStyles = {
  option: (base) => ({
    ...base,
    backgroundColor: 'white',
    color: '#5F5F5F',
    ':hover': {
      backgroundColor: '#F6F7FB',
    },
  }),
};
const selectComponents = {
  IndicatorSeparator: () => null,
};
const formatOptionLabel = (data, context) =>
  context.context === 'value' || data.id !== context.selectValue['0']?.id ? (
    <div style={{ display: 'flex' }}>
      <div>{data?.name}</div>
    </div>
  ) : (
    <div style={{ display: 'flex' }}>
      <div>
        {data.name}
        <svg
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: '10px' }}
        >
          <path
            d="M4.0001 7.7799L1.2201 4.9999L0.273438 5.9399L4.0001 9.66656L12.0001 1.66656L11.0601 0.726562L4.0001 7.7799Z"
            fill="#959595"
          />
        </svg>
      </div>
    </div>
  );
const formatTagColorOptionLabel = (data, context) =>
  context.context === 'value' ? (
    <div
      style={{
        display: 'flex',
        backgroundColor: data.value,
        color: 'black',
        width: '100%',
        height: '100%',
        fontWeight: 'bold',
      }}
    >
      <div>{data.label}</div>
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        backgroundColor: data.value,
        width: '100%',
        height: '100%',
        color: 'black',
        fontWeight: 'bold',
        justifyContent: 'space-between',
        textAlign: 'end',
        alignItems: 'center',
      }}
    >
      <div>{data.label}</div>
      <div>
        <svg
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: '10px' }}
          display={
            context.selectValue['0']?.value === data.value ? 'block' : 'none'
          }
        >
          <path
            d="M4.0001 7.7799L1.2201 4.9999L0.273438 5.9399L4.0001 9.66656L12.0001 1.66656L11.0601 0.726562L4.0001 7.7799Z"
            fill="#FFFFFF"
          />
        </svg>
      </div>
    </div>
  );

const TrelloSettings = ({ projectId }) => {
  const [board, setBoard] = useState<{ id: string; name: string, prefs: unknown } | null>(null);
  const [columns, setColumns] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState<
    { id: string; name: string }[]
  >([
    { id: '', name: '' },
    { id: '', name: '' },
  ]);
  const [tagColor, setTagColor] = useState<{
    value: string;
    label: string;
    id: string;
  }>({ value: '', label: '', id: '' });
  const [tagColorOptions, setTagColorOptions] = useState([]);

  const getBoard = async () => {
    const apiResponse = await project.getTrelloBoard(projectId);
    const connectedBoard = await apiResponse.json();
    if (connectedBoard.boardId !== undefined) {
      const response = await trello.getBoard(connectedBoard.boardId, true);
      const newBoard = await response.json();
      const { lists } = newBoard;
      const newLists = [
        { id: '', name: '' },
        { id: '', name: '' },
      ];
      if (connectedBoard.todoListId) {
        newLists[0] = lists.find((l) => l.id === connectedBoard.todoListId);
      }
      if (connectedBoard.doneListId) {
        newLists[1] = lists.find((l) => l.id === connectedBoard.doneListId);
      }
      const newTagColorOptions = newBoard.labels.map((l) => ({
        value: l.color,
        label: l.name,
        id: l.id,
      }));
      const newTagColor = newTagColorOptions.find(
        (t) => t.id === connectedBoard.projectLabelId,
      );
      setTagColorOptions(newTagColorOptions);
      if (newTagColor !== undefined)
        setTagColor({
          value: newTagColor.value,
          label: newTagColor.label,
          id: newTagColor.id,
        });
      setColumns(lists);
      setBoard(newBoard);
      setSelectedColumn(newLists);
    }
  };
  const getStyle = ({ prefs }) => {
    let style = {
      ...prefs,
    };
    if (prefs.backgroundImageScaled && prefs.backgroundImageScaled.length) {
      const tileImage = prefs.backgroundImageScaled.find(
        (img) => img.width === 480,
      );
      if (tileImage) {
        style = { 'background-image': `url(${tileImage.url})` };
      }
    } else if (prefs.backgroundColor) {
      style = { 'background-color': prefs.backgroundColor };
    }
    return style;
  };
  const handleDisconnect = async () => {
    await trello.deleteTrelloFromProject(projectId);
    setBoard(null);
    setColumns([]);
    setSelectedColumn([
      { id: '', name: '' },
      { id: '', name: '' },
    ]);
  };
  const handleSave = async () => {
    const body = {
      todoListId: selectedColumn[0]?.id,
      doneListId: selectedColumn[1]?.id,
      projectLabelId: tagColor.id,
      boardId: board?.id,
      project_id: projectId,
    };

    await team.change_project_trello_board(body, projectId);
  };

  useEffect(() => {
    getBoard();
  }, []);

  // return unconnectedTreloSettings if board is null
  if (!board) {
    return <UnconnectedTrelloSettings projectId={projectId} />;
  }
  return (
    <S.Container>
      <S.SectionTitle className="board">
        Projeto conectado ao Trello
      </S.SectionTitle>
      <S.Description>
        Este projeto está conectado a um quadro. Configure-o por aqui ou pelo
        Trello.
      </S.Description>
      <S.Row>
        <S.Container className="board">
          <S.SectionTitle className="board">Quadro</S.SectionTitle>
          <S.ColumnLabel>Quadro</S.ColumnLabel>
          <TS.TrelloCell style={getStyle({ prefs: board.prefs})} className="trelloCell">
            <TS.TrelloDetails>
              <TS.TrelloTitle>{board?.name}</TS.TrelloTitle>
            </TS.TrelloDetails>
          </TS.TrelloCell>
        </S.Container>

        <S.Container>
          <S.SectionTitle>Colunas</S.SectionTitle>
          <S.ColumnLabel>Coluna de tarefas a fazer*</S.ColumnLabel>
          <S.Select
            classNamePrefix="columnSelect"
            className="columnSelect"
            styles={selectStyles}
            components={selectComponents}
            defaultValue={selectedColumn[0]?.name}
            isSearchable={false}
            formatOptionLabel={formatOptionLabel}
            options={columns}
            placeholder={selectedColumn[0]?.name}
            onChange={(e: { id: string; name: string }) => {
              setSelectedColumn([e, selectedColumn[1]]);
            }}
          />
          <S.ColumnLabel>Coluna de tarefas finalizadas*</S.ColumnLabel>
          <S.Select
            classNamePrefix="columnSelect"
            className="columnSelect"
            styles={selectStyles}
            components={selectComponents}
            defaultValue={selectedColumn[1]?.name}
            isSearchable={false}
            formatOptionLabel={formatOptionLabel}
            options={columns}
            placeholder={selectedColumn[1]?.name}
            onChange={(e: { id: string; name: string }) => {
              setSelectedColumn([selectedColumn[0], e]);
            }}
          />
        </S.Container>
        <S.Container>
          <S.SectionTitle>Etiqueta</S.SectionTitle>
          <S.ColumnLabel>Etiqueta de identificação</S.ColumnLabel>
          <S.Select
            classNamePrefix="columnSelect"
            className="columnSelect"
            styles={{
              option: (base, state) => ({
                ...base,
                backgroundColor: (state.data as any).value,
                cursor: 'pointer',
                height: '40px',
              }),
              control: (base, state) => ({
                ...base,
                cursor: 'pointer',
                height: '47px',
                backgroundColor: `${(state.getValue()[0] as any)?.value} !important`,
              }),
            }}
            components={selectComponents}
            defaultValue={tagColor}
            isSearchable={false}
            formatOptionLabel={formatTagColorOptionLabel}
            options={tagColorOptions}
            placeholder={tagColor.label}
            onChange={(e: { value: string; label: string; id: string }) => {
              setTagColor(e);
            }}
          />
        </S.Container>
      </S.Row>
      <S.ButtonRow>
        <LightButton
          style={{
            padding: '10px 22px',
            marginTop: '38px',
            fontSize: '16px',
          }}
          onClick={() => {
            handleDisconnect();
          }}
        >
          Desconectar Trello
        </LightButton>
        <Button
          style={{
            padding: '10px 22px',
            marginTop: '38px',
            fontSize: '16px',
          }}
          onClick={() => {
            handleSave();
          }}
        >
          Salvar Alterações
        </Button>
      </S.ButtonRow>
    </S.Container>
  );
};

export default TrelloSettings;
