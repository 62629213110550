import React from 'react';

import * as S from './styles';

interface NotificationModalProps {
  closeModal: (status: boolean) => void;
  header?: string;
  body?: string;
  info?: string;
  buttonText?: string;
  margin?: string;
  modalWasSeen: string;
}

const NotificationModal: React.FC<NotificationModalProps> = ({
  closeModal,
  header,
  body,
  info,
  buttonText,
  margin,
  modalWasSeen,
}) => {
  const onClickModalClose = (wasSeen) => {
    localStorage.setItem(wasSeen, 'true');
    closeModal(false);
  };

  const redirectToInfo = (wasSeen) => {
    window.open('https://blog.getlabor.com.br/novidades-no-labor', '_blank');
    localStorage.setItem(wasSeen, 'true');
    closeModal(false);
  };

  return (
    <S.Wrapper margin={margin}>
      <S.HeaderWrapper>
        {header}
        <S.ModalClose>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            onClick={() => onClickModalClose(modalWasSeen)}
          >
            <path
              fill="#959595"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </S.ModalClose>
      </S.HeaderWrapper>
      <S.BodyWrapper>{body}</S.BodyWrapper>
      <S.InfoWrapper>{info}</S.InfoWrapper>
      <S.StyledButton onClick={() => redirectToInfo(modalWasSeen)}>
        {buttonText}
      </S.StyledButton>
    </S.Wrapper>
  );
};

export default NotificationModal;
