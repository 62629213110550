import React from 'react';

import useProjectsTasksStore from '~/store/projectsTasks';

import DailyTasks from './DailyTasks';
import { NoTaskText } from './styles';

const TasksList = ({ $stateParams }) => {
  const { projectsTasks } = useProjectsTasksStore();
  return (
    <>
      {Object.entries(projectsTasks)
        .reverse()
        .map(([dailyTasksDate, dailyTasks]) => (
          <DailyTasks
            projectId={$stateParams.projectId}
            date={dailyTasksDate}
            duration={dailyTasks.duration}
            tasksInTheDay={dailyTasks.tasks.filter((task) => {
              if (!$stateParams.projectId) return true;
              if ($stateParams.projectId === task.projectId) return true;
              return false;
            })}
            key={dailyTasksDate}
          />
        ))}
      {Object.keys(projectsTasks).length === 0 && (
        <NoTaskText>
          Não existem tarefas registradas para o período atual.
        </NoTaskText>
      )}
    </>
  );
};

export default TasksList;
