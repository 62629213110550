import React, { useState } from 'react';

import Modal from '~/components/Modal';
import useOnboardingStore from '~/store/onboarding';

import * as S from './styles';

const Content = ({
  modalIsWecome,
  changeTourIsRunning,
  changemodalIsWecome,
  changeIsDone,
}) => {
  if (modalIsWecome) {
    return (
      <>
        <S.BodyWrapper>
          <S.Paragraph>
            Preparamos um breve tour para ajudar você a registrar sua primeira
            tarefa no Labor. Vamos lá?
          </S.Paragraph>
        </S.BodyWrapper>
        <S.Buttons>
          <S.StyledButton
            onClick={() => {
              changeTourIsRunning();
              changemodalIsWecome(false);
            }}
          >
            Começar
          </S.StyledButton>
          <S.NextWrapper
            onClick={() => {
              changeIsDone();
            }}
          >
            Pular e explorar
          </S.NextWrapper>
        </S.Buttons>
      </>
    );
  }
  return (
    <S.BodyWrapper>
      <S.Paragraph>
        Agora você poderá organizar suas tarefas dentro de projetos e otimizar
        seu tempo usando o cronômetro.
      </S.Paragraph>
      <S.Paragraph>
        Caso precise de ajuda, não hesite em contatar a nossa equipe de suporte.{' '}
      </S.Paragraph>
      <S.Paragraph>Estamos muito felizes em ter você aqui!</S.Paragraph>
    </S.BodyWrapper>
  );
};

const TourModal: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(true);

  const changemodalIsWecome = useOnboardingStore(
    (state) => state.changemodalIsWecome,
  );
  const changeTourIsRunning = useOnboardingStore(
    (state) => state.changeIsRunning,
  );
  const changeIsDone = useOnboardingStore((state) => state.changeIsDone);
  const modalIsWecome = useOnboardingStore((state) => state.modalIsWecome);

  const closeModal = () => {
    setModalIsOpen(false);
    changeIsDone();
  };

  return (
    <Modal
      onRequestClose={() => closeModal()}
      isOpen={modalIsOpen}
      hasCloseButton={false}
      blurBackground={false}
      styles={S.Container}
    >
      <S.HeaderWrapper>
        {modalIsWecome ? (
          <>Boas vindas!</>
        ) : (
          <>Parabéns, você concluiu o tour!</>
        )}
        <S.ModalClose>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            onClick={() => closeModal()}
          >
            <path
              fill="#959595"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </S.ModalClose>
      </S.HeaderWrapper>
      <Content
        modalIsWecome={modalIsWecome}
        changeIsDone={changeIsDone}
        changeTourIsRunning={changeTourIsRunning}
        changemodalIsWecome={changemodalIsWecome}
      />
    </Modal>
  );
};

export default TourModal;
