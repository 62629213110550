import * as Yup from 'yup';

import API from '~/services/api';

let timeoutRequestEmail;
let response;
async function checkEmail(email) {
  clearTimeout(timeoutRequestEmail);

  timeoutRequestEmail = setTimeout(async () => {
    response = await API.user.check_email(email);
  }, 500);

  return response?.status !== 403;
}

const RegisterInviteSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    email: Yup.string()
      .email('Email inválido')
      .required('Campo obrigatório')
      .test(
        'checkEmailRegister',
        'Este e-mail já está em uso. Por favor, informe outro.',
        (email) => {
          if (email) return checkEmail(email);
          return false;
        },
      ),
    password: Yup.string()
      .required('Campo obrigatório')
      .min(6, 'A senha deve ter ao menos 6 caracteres'),
    phone: Yup.string(),
    acceptedTerms: Yup.bool().oneOf(
      [true],
      'É necessário aceitar os termos de uso',
    ),
  });

export default RegisterInviteSchema;
