import * as icons from '@mui/icons-material';
import React, { useState, useEffect } from 'react';

import useOnboardingStore from '~/store/onboarding';
import useProjectStore from '~/store/projects';

import DropdownMenu from '../DropdownMenu';

import * as S from './styles';

import type { UserServiceType } from '~/models/ServicesTypes';
import type { State, ProjectService } from '~/models/types';

export interface MenuProps {
  UserService: UserServiceType;
  $state: State;
  itemMenu: ItemMenuProps[];
  projectMenu: ProjectMenuProps;
  route: string;
  setRoute: Function;
  ProjectService: ProjectService;
}

export type ItemMenuProps = {
  icon: string;
  name: string;
  isNovatics?: boolean;
  url: string;
  adminOnly: boolean;
};

export type ProjectMenuProps = {
  name: string;
  isOpenIcon: string;
  isClosedIcon: string;
  adminOnly: boolean;
  url?: string;
};

const Menu: React.FC<MenuProps> = ({
  $state,
  UserService,
  itemMenu,
  projectMenu,
  route,
  setRoute,
  ProjectService,
}: MenuProps) => {
  const [userRole, setUserRole] = useState('');
  const [isNovatics, setIsNovatics] = useState(false);
  const { activeProjectId, setActiveProjectId } = useProjectStore();

  const increaseStepIndex = useOnboardingStore(
    (state) => state.increaseStepIndex,
  );
  const tourIsRunning = useOnboardingStore((state) => state.isRunning);

  useEffect(() => {
    setUserRole(UserService.loggedUser.role);
    setIsNovatics(UserService.loggedUser.allocations[0].team.id === 1);
  });

  const navigateFor = (url: string, projectId?: number) => {
    setRoute(url);
    $state.go(url, { projectId });
    if (tourIsRunning) increaseStepIndex();
    if (!projectId) setActiveProjectId(projectId);
  };

  const ItemMenu = React.useCallback(
    (props: ItemMenuProps) => {
      const Icon = icons[props.icon];
      if (props.adminOnly && userRole !== 'administrador') return null;
      if (props.isNovatics === false && props.url === 'app.main.payments')
        return null;
      return (
        <S.Item
          id={props.name}
          onClick={() => navigateFor(props.url)}
          isActive={props.url === route}
        >
          <S.ItemIcon>
            <Icon style={S.Icon} />
          </S.ItemIcon>
          <S.ItemText>{props.name}</S.ItemText>
        </S.Item>
      );
    },
    [userRole],
  );

  return (
    <S.Container>
      <DropdownMenu
        ProjectService={ProjectService}
        setActiveProjectId={setActiveProjectId}
        activeProjectId={activeProjectId}
        navigateFor={navigateFor}
        projectMenu={projectMenu}
        route={route}
        userRole={userRole}
      />
      {itemMenu.map((item) => (
        <ItemMenu
          key={item.name}
          name={item.name}
          url={item.url}
          icon={item.icon}
          adminOnly={item.adminOnly}
          isNovatics={isNovatics}
        />
      ))}
    </S.Container>
  );
};

export default Menu;
