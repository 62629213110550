import styled from '@emotion/styled';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import {
  usePopoverState as usePopoverStateBase,
  Popover as BasePopover,
  PopoverDisclosure as PopoverDisclosureBase,
  PopoverArrow as PopoverArrowBase,
} from 'reakit/Popover';

const PopoverContainer = styled(BasePopover)`
  position: absolute;
  background: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 14px;
  z-index: 999;
`;

const PopoverArrowIcon = styled(FontAwesomeIcon)`
  font-size: 35px;
  margin-bottom: -7px;
  color: #fff;
`;

export const Popover = ({ children, ...props }) => (
    <PopoverContainer {...props}>
      <PopoverArrowBase styles={{ color: 'white' }} {...props}>
        <PopoverArrowIcon icon={faCaretUp} />
      </PopoverArrowBase>
      {children}
    </PopoverContainer>
  )

export const usePopoverState = usePopoverStateBase;
export const PopoverDisclosure = PopoverDisclosureBase;

Popover.propTypes = {
  children: PropTypes.object.isRequired,
};
