import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

import BoxContainer from './BoxContainer';
import CumulativeChart from './charts/CumulativeChart';
import DailyChart from './charts/DailyChart';

const Column = styled.div`
  flex: 1;
  padding-right: 70px;
  &:last-of-type {
    padding: 0;
  }
`;

const ColumnTitle = styled.h2`
  font-size: 1rem;
  margin: 0 0 30px 0;
`;

export default function ProfitabilityCharts({ dailyHours, fillColor }) {
  if (!dailyHours) return '';

  return (
    <BoxContainer>
      <Column>
        <ColumnTitle>Horas Acumuladas (Realizadas x Previstas)</ColumnTitle>
        <CumulativeChart dailyHours={dailyHours} fillColor={fillColor} />
      </Column>
      <Column>
        <ColumnTitle>Horas diárias (Realizadas x Previstas)</ColumnTitle>
        <DailyChart dailyHours={dailyHours} fillColor={fillColor} />
      </Column>
    </BoxContainer>
  );
}

ProfitabilityCharts.propTypes = {
  dailyHours: PropTypes.shape(
    PropTypes.shape({
      accomplished: PropTypes.number,
      cumulativeAccomplished: PropTypes.number,
      cumulativeExpected: PropTypes.number,
      expected: PropTypes.number,
    }),
  ),
  fillColor: PropTypes.string,
};
