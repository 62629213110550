import { toast } from 'react-toastify';

import TaskAlert from '.';

import type { User } from '~/models/types';

const verifyRatio = (ratio: number, flag: string) => {
  if (ratio >= 0.8 && ratio < 0.9) return `${flag}_80`;
  if (ratio >= 0.9 && ratio < 1) return `${flag}_90`;
  if (ratio >= 1) return `${flag}_100`;
  return '';
};

const checkAndResetLocalStorage = () => {
  let alerts = JSON.parse(localStorage.getItem('alerts') || '{}');
  if (new Date(alerts.updated_at).getMonth() !== new Date().getMonth()) {
    alerts = {};
    alerts.updated_at = new Date();
    localStorage.setItem('alerts', JSON.stringify(alerts));
  }
};
const Alert = (alertType) => {
  toast(TaskAlert({ alertType }), {
    position: 'top-right',
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    containerId: 2,
  });
};
const checkAlerts = async (user: User, alerts?: Record<string, any>) => {
  const alertTypeTemp: string[] = [];
  const isNovatics = user.allocations?.some(
    (allocation) => allocation.team.id === 1,
  );
  const updateLocalStorage = (alertFlag: string) => {
    const parsedAlerts = JSON.parse(localStorage.getItem('alerts') || '{}');

    if (!parsedAlerts[alertFlag]) {
      Alert(alertFlag);
      parsedAlerts[alertFlag] = true;
      parsedAlerts.updated_at = new Date();
      localStorage.setItem('alerts', JSON.stringify(parsedAlerts));
    }
  };

  if (isNovatics) {
    checkAndResetLocalStorage();
    // alerta o usuario
    if (alerts) {
      if (alerts.daysOff && alerts.daysOff.alert) {
        alertTypeTemp.push('dayOffHours');
      }
      if (alerts.pdi && alerts.pdi.alert) {
        alertTypeTemp.push(verifyRatio(alerts.pdi.ratio, 'pdiHours'));
      }
      if (alerts.exceedingHours) {
        alertTypeTemp.push(
          verifyRatio(alerts.exceedingHours.ratio, 'exceedingHours'),
        );
      }
    }
  }

  // Removing empty strings
  const alertType = alertTypeTemp.filter((alert) => alert);
  if (alertType.length > 0) {
    alertType.forEach((alert) => {
      updateLocalStorage(alert);
    });
  }
};

export default checkAlerts;
