import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import FilterSelect from 'react-select';

import { LightButton } from '../../../components/Button';
import Modal, { ModalTitle, ModalText } from '../../../components/Modal';
import { ListHeader, Item, Column } from '../../components/List';
import formatCurrency from '../../utils/currency';
import { msToTime, msToHours } from '../../utils/duration';

const Divider = styled.div`
  border-bottom: 2px solid #e3e5e8;
  margin-bottom: 10px;
`;

const StyledModalTitle = styled(ModalTitle)`
  margin-top: 20px;
`;

const months = [
  { value: 1, label: 'Janeiro' },
  { value: 2, label: 'Fevereiro' },
  { value: 3, label: 'Março' },
  { value: 4, label: 'Abril' },
  { value: 5, label: 'Maio' },
  { value: 6, label: 'Junho' },
  { value: 7, label: 'Julho' },
  { value: 8, label: 'Agosto' },
  { value: 9, label: 'Setembro' },
  { value: 10, label: 'Outubro' },
  { value: 11, label: 'Novembro' },
  { value: 12, label: 'Dezembro' },
];

const initialYear = new Date().getFullYear();
const years = Array(6)
  .fill('')
  .map((v, idx) => ({ value: initialYear - idx, label: initialYear - idx }));

export default function UserWageHistoryModal(props) {
  const { UserService, userName, userId, ...otherProps } = props;
  const [selectedYear, setSelectedYear] = useState(years[0]);
  const [reportList, setReportList] = useState([]);

  const getReports = (year) => {
    props.ReportService.getReports('', year, '', userId).then(
      (response) => {
        setReportList(response.data);
      },
      (error) => {
        props.$log.log('error', error);
      },
    );
  };

  useEffect(() => {
    getReports(selectedYear.value);
  }, []);

  async function handleFilterChange(year) {
    setSelectedYear(year);
    getReports(year.value);
  }

  function defineReportItem(month) {
    const defaultObject = {
      hourValue: 'R$ 0,00',
      totalTime: '00:00:00',
      totalValue: 'R$ 0,00',
    };
    if (!reportList) return defaultObject;

    const report = reportList.find(
      (innerReport) =>
        new Date(innerReport.referenceDate).getUTCMonth() + 1 === month.value,
    );

    if (!report) return defaultObject;

    return {
      hourValue: formatCurrency(report.hourValue),
      totalTime: msToTime(report.duration),
      totalValue: formatCurrency(msToHours(report.duration) * report.hourValue),
    };
  }

  return (
    <Modal {...otherProps} width="35%" height="100%">
      <StyledModalTitle>Histórico de Salários</StyledModalTitle>
      <ModalText>
        {`Confira o histórico anual do valor hora, e o comparativo com as horas computadas do Labor para ${userName}.`}
      </ModalText>
      <FilterSelect
        classNamePrefix="years-select"
        className="years-select"
        isSearchable={false}
        onChange={handleFilterChange}
        value={selectedYear}
        placeholder="Ano"
        options={years}
      />
      <Divider />
      <>
        <ListHeader>
          <Column flex={3}>{selectedYear.label}</Column>
          <Column flex={2}>Valor da Hora</Column>
          <Column flex={1}>Horas</Column>
          <Column flex={1}>Valor Total</Column>
          <Column style={{ flex: '0.2' }} />
        </ListHeader>
        {months.map((month) => {
          const report = defineReportItem(month);
          return (
            <Item key={month.value}>
              <Column flex={3}>{month.label}</Column>
              <Column flex={2}>{report.hourValue}</Column>
              <Column flex={1}>{report.totalTime}</Column>
              <Column flex={1}>{report.totalValue}</Column>
            </Item>
          );
        })}
      </>
      <LightButton
        type="button"
        style={{ margin: '30px 30px 30px 0' }}
        onClick={() => props.onRequestClose()}
      >
        Fechar
      </LightButton>
    </Modal>
  );
}

UserWageHistoryModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  UserService: PropTypes.object.isRequired,
  userName: PropTypes.string,
  userId: PropTypes.string,
  ReportService: PropTypes.shape({
    getReports: PropTypes.func,
  }),
  $log: PropTypes.shape({
    log: PropTypes.func,
  }),
};
