import styled from '@emotion/styled';

import Link from '~/components/Link';
import { mq } from '~/helpers/responsive';

export const HomePageLink = styled(Link)`
  ${mq({
    display: 'block',
    marginBottom: '28px',
  })}
`;