import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Button, { LightButton } from '~/components/Button';

const saveCancelBtn = css`
  width: 469px;
  height: 250px;
`;

export const SelectWrapper = styled.div`
  width: 311px;
  height: 237px;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${(props: { saveCancelBtn?: boolean }) =>
    props.saveCancelBtn && saveCancelBtn}
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #d7d7d7;
  margin: 8px 0 16px 0;
`;

export const SelectAllContainer = styled.div`
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  margin-top: 27px;
  display: flex;
  gap: 12px;
`;

const disabledBtn = css`
  cursor: default;
  opacity: 0.5;
`;

export const SaveButton = styled(Button)`
  ${(props: { disabled?: boolean }) => props.disabled && disabledBtn}
`;

export const CancelButton = styled(LightButton)`
  ${(props: { disabled?: boolean }) => props.disabled && disabledBtn}
`;
