import React, { useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import SearchableSelect from '~/app/components/Select/SearchableSelect';
import useReportsStore from '~/store/reports/reports';
import useTeamStore from '~/store/team';
import useUserStore from '~/store/user';

import { ListIcon } from '../styles';

import type {
  Item,
  OptionsType,
} from '~/app/components/Select/SearchableSelect';


const UserSelect = () => {
  const [users, setUsers] = useState<Item[]>([]);

  const { user: loggedUser } = useUserStore((state) => ({ user: state.user }));
  const isLoggedUserAdmin = useUserStore((state) => state.isLoggedUserAdmin);

  const { teamUsers } = useTeamStore((state) => ({
    teamUsers: state.teamUsers,
  }));
  const { setSelectedUsersIds, hasChangeFlags } = useReportsStore(
    (state) => ({
      setSelectedUsersIds: state.setSelectedUsersIds,
      hasChangeFlags: state.hasChangeFlags,
    }),
    shallow,
  );

  const getUsers = (): Item[] => {
    if (isLoggedUserAdmin()) {
      return teamUsers.map((item) => ({
        ...item,
        checked: item.id === loggedUser.id,
      }));
    }
    return [{ name: loggedUser.name, id: loggedUser.id, checked: true }];
  };

  useEffect(() => {
    setUsers(getUsers());
  }, [teamUsers]);

  const selectedUsers = users.filter((user) => user.checked);

  const calculateDisplayName = (): string => {
    if (users.length === 1) return users[0].name || 'Usuário';
    if (selectedUsers.length === users.length || selectedUsers.length === 0)
      return 'Todos os colaboradores';
    if (selectedUsers.length === 1) {
      return selectedUsers[0].name || 'Usuário';
    }
    return 'Múltiplos colaboradores';
  };

  const onClose = () => {
    setSelectedUsersIds(selectedUsers.map((user) => user.id));
  };

  const options: OptionsType[] = [
    {
      text: 'Todos os colaboradores',
      onClick: () => {
        setUsers(users.map((user) => ({ ...user, checked: true })));
      },
      onClickAction: 'CLOSE_DROPDOWN',
      disabled: users.length <= 1,
    },
    {
      text: 'Selecionar colaboradores',
      icon: <ListIcon />,
      onClickAction: 'OPEN_COMPONENT',
    },
  ];

  const emptyMessage = <div>Nenhum usuário disponível.</div>;

  return (
    <SearchableSelect
      data={users}
      setData={(data) => setUsers(data)}
      displayName={calculateDisplayName()}
      onClose={onClose}
      emptyMessage={emptyMessage}
      searchPlaceholder="Pesquisar membros"
      options={options}
      isPending={hasChangeFlags.users}
    />
  );
};

export default UserSelect;
