import styled from '@emotion/styled';

import theme from '~/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.a<any>`
  display: flex;
  align-content: center;
  text-decoration: none;  
  padding: 0 0 15px 20px;
  :hover {
    color: ${theme.colors.primary};
  }
  color: ${theme.colors.black1};
  cursor: pointer;
  text-decoration: none;
  flex-wrap: wrap;
`;

export const ItemText = styled.span`
  font-size: 14px;
  font-weight: normal;
`;

export const ItemIcon = styled.div`
  background-repeat: "no-repeat";
  fill: "currentColor";
  height: "24px";
  width: "24px";
  min-height: "24px";
  min-width: "24px";
  margin-left: 4px;
`

// Style for icon
export const Icon = (isFocusActive: boolean) => ({
    fontSize: "20px",
    marginRight: "8px",
    color: isFocusActive ? theme.colors.primary : theme.colors.black,
    opacity: isFocusActive? '1' : '0.5',
  });
