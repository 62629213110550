import { Formik, Form } from 'formik';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';

import Button from '~/components/Button';
import Checkbox from '~/components/Checkbox';
import Input from '~/components/Input';
import Link from '~/components/Link';
import PasswordInput from '~/components/PasswordInput';
import saveLoginUserDataLocalStorage from '~/helpers/login';
import { ShowBeforeMedium } from '~/helpers/responsive';
import API from '~/services/api';
import AmplitudeUserStore from '~/services/api/amplitude';
import ForgotPasswordModal from '~/site/LoginPage/ForgotPasswordModal';
import loginMobileImg from '~/site/LoginPage/images/login-bg-mobile.png';
import Container from '~/site/components/Container';
import Page from '~/site/components/Page';
import PageTitle from '~/site/components/PageTitle';
import SocialMediaSignIn from '~/site/components/SocialMediaSignIn/index';

import * as S from './styles';

import type NotificationServiceType from '~/models/ServicesTypes';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email inválido').required('Campo obrigatório'),
  password: Yup.string().required('Campo obrigatório'),
});
let amplitude;

interface LoginPageProps {
  AMPLITUDE_API_KEY: string;
  NotificationService: NotificationServiceType;
}
const LoginPage: React.FC<LoginPageProps> = ({
  AMPLITUDE_API_KEY,
  NotificationService,
}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    amplitude = new AmplitudeUserStore(null, AMPLITUDE_API_KEY);
  }, []);

  const saveUserDataAndRedirect = (json, headers) => {
    saveLoginUserDataLocalStorage(
      json.data,
      json.rules,
      headers,
      () => amplitude.setUserId,
    );
  };

  async function handleLogin({ email, password }) {
    const response = await API.auth.login({ email, password });

    if (response) {
      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.errors ? json.errors[0] : json.error);
      }

      const { headers } = response;

      saveUserDataAndRedirect(json, headers);
    }
  }

  const handleCloseModal = (params) => {
    if (params && params.json) {
      setMessage(params.json.message);
    }

    setModalOpen(false);
  };

  return (
    <Page>
      <Container>
        <S.InnerWraper>
          <ShowBeforeMedium>
            <img src={loginMobileImg} style={{ width: '100%' }} alt="Login" />
          </ShowBeforeMedium>
          <S.FormWrapper>
            <PageTitle style={{ marginBottom: '45px' }}>Log In</PageTitle>
            <SocialMediaSignIn
              AMPLITUDE_API_KEY={AMPLITUDE_API_KEY}
              NotificationService={NotificationService}
              googleText="Entrar com Google"
              microsoftText="Entrar com Microsoft"
            />
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={LoginSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleLogin(values).catch((e) => {
                  setMessage(e.message);

                  setSubmitting(false);
                });
              }}
            >
              {({ values, isSubmitting }) => (
                <Form noValidate style={{ position: 'relative' }}>
                  <S.StyledSnackbar isVisible={!!message}>
                    {message}
                  </S.StyledSnackbar>
                  <Input
                    name="email"
                    type="email"
                    value=""
                    placeholder="E-mail"
                  />
                  <PasswordInput
                    name="password"
                    value={values.password}
                    placeholder="Senha"
                  />

                  <S.ActionsWrapper>
                    <Checkbox
                      name="keep_connected"
                      label="Continuar conectado"
                      style={{ margin: 0 }}
                    />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link onClick={() => setModalOpen(true)}>
                      Esqueci a senha
                    </Link>
                  </S.ActionsWrapper>

                  <S.SubmitWrapper>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      style={{ minWidth: '168px' }}
                    >
                      Entrar
                    </Button>
                    <S.RegisterText>
                      Não é cadastrado?{' '}
                      <Link href="/cadastrar" callToAction>
                        Comece Agora
                      </Link>
                    </S.RegisterText>
                  </S.SubmitWrapper>
                </Form>
              )}
            </Formik>
          </S.FormWrapper>
          {/* <ImageWrapper /> */}
          <ForgotPasswordModal
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            width="538px"
          />
        </S.InnerWraper>
      </Container>
    </Page>
  );
};

export default LoginPage;
