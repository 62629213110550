import React from 'react';

import {
  CheckLabel,
  FormControlLabel,
  StyledCheckbox,
  ItemsWrapper,
} from './styles';

// Will display name. Displays description when name is undefined
export type Item = {
  id: number;
  name?: string;
  description?: string | null;
  checked: boolean;
  disabled?: boolean;
};

type Props = {
  item: Item;
  checked: boolean;
  handleOnClick: () => void;
  bold?: boolean;
  disabled?: boolean;
};

export { ItemsWrapper };

const CheckItem = ({ item, checked, handleOnClick, bold, disabled }: Props) => {
  const handleClickDiv = (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>,
  ) => {
    handleOnClick();
    e.preventDefault();
  };
  return (
    <FormControlLabel
      onClick={(e) => !disabled && handleClickDiv(e)}
      disabled={disabled}
      label={
        <CheckLabel bold={bold}>
          {item.name ? item.name : item.description}
        </CheckLabel>
      }
      control={
        <StyledCheckbox
          disableRipple
          checked={checked}
          name={item.id.toString()}
          onChange={() => {}}
          size="medium"
          sx={{
            color: '#959595',
            '&.Mui-checked': {
              color: '#FF3C58',
            },
          }}
        />
      }
    />
  );
};

export default CheckItem;
