import angular from 'angular';
import { react2angular } from 'react2angular';
import { ColorpickerModule } from './colorpicker/colorpicker.module';
import { DatetimepickerModule } from './datetimepicker/datetimepicker.module';
import { DateRangePickerModule } from './date-range-picker/date-range-picker.module';
import { DropdownModule } from './dropdown/dropdown.module';
import { PopoverModule } from './popover/popover.module';
import { ProjectsModule } from './projects/projects.module';
import { ReportsModule } from './reports/reports.module';
import { TasksModule } from './tasks/tasks.module';
import { ListSelectModule } from './list-select/list-select.module';
import { MainComponent } from './main.component';
import { UsersModule } from './users/users.module';

// New React Components
import SubscribePage from '~/app/SubscribePage';
import UsersPage from '~/app/UsersPage';
import UserProfile from '~/app/UserProfile';
import ProfitabilityPage from '~/app/ProfitabilityPage';
import PaymentsPage from '~/app/PaymentsPage';
import LogoutPage from '~/app/Logout';
import SideBar from '~/components/SideBar';
import PeriodicNotification from '~/components/NotificationModal/PeriodicNotification/PeriodicNotification.tsx';
import LoadingBar from '~/app/components/LoadingBar';

import CookiesBanner from '~/app/components/CookiesBanner';

export const MainModule = angular
  .module('root.components.app.components.main', [
    ColorpickerModule,
    DateRangePickerModule,
    DatetimepickerModule,
    DropdownModule,
    PopoverModule,
    ProjectsModule,
    ReportsModule,
    TasksModule,
    UsersModule,
    ListSelectModule,
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $stateProvider.state('app.main', {
      abstract: true,
      url: '/main',
      component: 'main',
      resolve: {
        auth: ($auth) => {
          return $auth.validateUser();
        },
      },
    });

    $stateProvider.state('app.main.subscribe', {
      url: '/subscribe',
      component: 'subscribe',
      resolve: {
        auth: ($auth) => {
          return $auth.validateUser();
        },
      },
    });

    $stateProvider.state('app.main.users', {
      url: '/users',
      component: 'users',
      resolve: {
        auth: ($auth) => {
          return $auth.validateUser();
        },
      },
    });

    $stateProvider.state('app.main.user', {
      url: '/user/:userId',
      component: 'userProfile',
      resolve: {
        auth: ($auth) => {
          return $auth.validateUser();
        },
        userId: ($stateParams) => {
          return $stateParams.userId;
        },
      },
    });

    $stateProvider.state('app.main.profitability', {
      url: '/profitability',
      component: 'profitability',
      resolve: {
        auth: ($auth) => {
          return $auth.validateUser();
        },
      },
    });

    $stateProvider.state('app.main.payments', {
      url: '/payments',
      component: 'payments',
      resolve: {
        auth: ($auth) => {
          return $auth.validateUser();
        },
      },
    });

    $stateProvider.state('app.main.logout', {
      url: '/logout',
      component: 'logout',
      resolve: {
        auth: ($auth) => {
          return $auth.validateUser();
        },
      },
    });

    $urlRouterProvider.when('/app/main', '/app/main/projects');
  })
  .component(
    'subscribe',
    react2angular(SubscribePage, [], ['AMPLITUDE_API_KEY']),
  )
  .component(
    'users',
    react2angular(
      UsersPage,
      [],
      ['AllocationService', 'NotificationService', 'ProjectService', '$state'],
    ),
  )
  .component(
    'userProfile',
    react2angular(
      UserProfile,
      [],
      [
        '$state',
        '$stateParams',
        'AllocationService',
        'UserService',
        'NotificationService',
        'ProjectAllocationService',
        'ProjectService',
        'ReportService',
      ],
    ),
  )
  .component(
    'cookies',
    react2angular(CookiesBanner, [], ['COOKIES_PRIVACY_URL']),
  )
  .component(
    'profitability',
    react2angular(ProfitabilityPage, [], ['ProjectService', 'TeamService']),
  )
  .component('logout', react2angular(LogoutPage, [], ['LoginService']))
  .component(
    'payments',
    react2angular(
      PaymentsPage,
      [],
      [
        'ReportService',
        'UserService',
        '$log',
        '$document',
        '$window',
        'TeamService',
        'NotificationService',
      ],
    ),
  )
  .component(
    'sidebar',
    react2angular(
      SideBar,
      [],
      [
        '$state',
        '$stateParams',
        'AllocationService',
        'UserService',
        'NotificationService',
        'ProjectAllocationService',
        'ProjectService',
        'ReportService',
        'TeamService',
        'LoginService',
        '$rootScope',
      ],
    ),
  )
  .component(
    'reactPeriodicNotification',
    react2angular(PeriodicNotification, [], ['$state']),
  )
  .component('reactLoadingBar', react2angular(LoadingBar))
  .component('main', MainComponent).name;
