import type { ProjectMenuProps, ItemMenuProps } from './Menu';

export const SidebarProject: ProjectMenuProps = {
  name: 'Projetos',
  isOpenIcon: 'KeyboardArrowDown',
  isClosedIcon: 'KeyboardArrowRight',
  adminOnly: false,
  url: 'app.main.projects',
};

export const SidebarData: ItemMenuProps[] = [
  {
    name: 'Cronômetro',
    url: 'app.main.tasks',
    icon: 'Alarm',
    adminOnly: false,
  },
  {
    name: 'Relatórios',
    url: 'app.main.reports',
    icon: 'ContentCopy',
    adminOnly: false,
  },
  {
    name: 'Lucratividade',
    url: 'app.main.profitability',
    icon: 'TrendingUp',
    adminOnly: true,
  },
  {
    name: 'Usuários',
    url: 'app.main.users',
    icon: 'Group',
    adminOnly: false,
  },
  {
    name: 'Pagamentos',
    url: 'app.main.payments',
    icon: 'Payment',
    adminOnly: false,
  },
];
