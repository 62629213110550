import styled from '@emotion/styled';
import { Field as FormikField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const SelectStyled = styled.select`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  padding: 14px 20px;
  border: solid 1px rgba(149, 149, 149, 0.2);
  background-color: #ffffff;
  outline: none;
  :focus {
    border-color: rgba(70, 128, 254, 0.5);
  }
  ${(p) =>
    p.hasError &&
    `
    color: #ff6340;
    border-color: currentColor;
  `}
`;
const Label = styled.label`
  flex: 1;
  color: #7d7d7d;
  font-weight: 600;
  font-size: 0.9em;
`;

const Wrapper = styled.label`
  display: flex;
  margin: ${(props) =>
    props.customMargin ? props.customMargin : '0 0 20px 0'};
  flex-direction: column;
`;

const ErrorMessage = styled.div`
  color: #ff6340;
  font-size: 12px;
  font-weight: 500;
`;

const SelectHandler = (props) => {
  const {
    error,
    style,
    selectStyle,
    options,
    placeholder,
    customMargin,
    ...otherProps
  } = props;

  return (
    <Wrapper style={style} customMargin={customMargin}>
      <Label>{props.label}</Label>
      <SelectStyled
        data-testid="select"
        style={selectStyle}
        {...otherProps}
        hasError={!!error}
      >
        {placeholder && <option value={false}>{placeholder}</option>}
        {(options || []).map(({ value, label }) => (
          <option data-testid="select-option" key={value} value={value}>
            {label}
          </option>
        ))}
      </SelectStyled>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
}

SelectHandler.propTypes = {
  error: PropTypes.string,
  style: PropTypes.object,
  customMargin: PropTypes.string,
  placeholder: PropTypes.string,
  selectStyle: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  label: PropTypes.any,
};

const normalize = (value) =>
  typeof value === 'string' || typeof value === 'number' ? value : '';

export default function Select(props) {
  const { name, ...otherProps } = props;

  return (
    <FormikField name={name}>
      {({ field, form }) => (
        <SelectHandler
          {...field}
          {...otherProps}
          value={normalize(props.value || field.value)}
          error={form.touched[name] && form.errors[name]}
        />
      )}
    </FormikField>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  label: PropTypes.string,
  customMargin: PropTypes.string,
};
