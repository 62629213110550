import type {
  RegisterInputDto,
  RegisterOutputDto,
  CompleteRegisterInputDto,
  CompleteRegisterOutputDto,
  CheckEmailInputDto,
  CheckEmailOutputDto,
  GetTasksInputDto,
  GetTasksOutputDto,
  DeleteTaskInputDto,
  DeleteTaskOutputDto,
  EditTaskProjectInputDto,
  EditTaskProjectOutputDto,
  GetUserAllocationsInputDto,
  GetUserAllocationsOutputDto,
  EditTaskDescriptionInputDto,
  EditTaskDescriptionOutputDto,
  EditPlannedTaskInputDto,
  EditPlannedTaskOutputDto,
  EditTaskTimeInputDto,
  EditTaskTimeOutputDto,
  ChangeProjectAllocationInputDto,
  ChangeProjectAllocationOutputDto,
  GetUserInputDto,
  GetUserOutputDto,
  UpdateUserInputDto,
  UpdateUserOutputDto,
  ValidateCompleteSigninInputDto,
  ValidateCompleteSigninOutputDto,
  IUserAPI,
  IApiService,
} from '~/common/domain/interfaces/services/api';

export class UserApi implements IUserAPI {
  constructor(private readonly apiService: IApiService) {}

  async register(params: RegisterInputDto): Promise<RegisterOutputDto> {
    const { segment, segmentDetail, teamSize, ...userData } = params.userData;
    return this.apiService.fetch({
      method: 'POST',
      path: '/users',
      body: {
        user: {
          ...userData,
          passwordConfirmation: userData.password,
        },
        team: {
          segment,
          segmentDetail,
          size: teamSize,
          codInvite: params.codInvite,
        },
      },
    });
  }

  async completeRegister(
    params: CompleteRegisterInputDto,
  ): Promise<CompleteRegisterOutputDto> {
    return this.apiService.fetch({
      method: 'PUT',
      path: `/users/${params.userId}/complete_signin`,
      body: {
        user: {
          name: params.name,
          password: params.password,
          passwordConfirmation: params.password,
        },
      },
    });
  }

  async checkEmail(params: CheckEmailInputDto): Promise<CheckEmailOutputDto> {
    return this.apiService.fetch({
      method: 'GET',
      path: '/users/valid_email',
      headers: {
        'User-Email': params.email,
      },
    });
  }

  async getTasks(params: GetTasksInputDto): Promise<GetTasksOutputDto> {
    const {
      starting,
      ending,
      projectIds,
      plannedTaskIds,
      page,
      perPage,
      userIds,
      ...rest
    } = params;
    const urlParams = {
      starting: starting?.toISOString() || '',
      ending: ending?.toISOString() || '',
      userId: userIds?.map(String) || '',
      projectId: projectIds?.map(String) || '',
      plannedTaskId: plannedTaskIds.map(String) || '',
      page: page?.toString() || '',
      perPage: perPage?.toString() || '',
      ...rest,
    };

    if (!userIds) {
      return this.apiService.fetch({
        method: 'GET',
        path: '/tasks',
        searchParams: {
          ...urlParams,
          includes: [...(urlParams.includes || []), 'user'],
        },
      });
    }
    return this.apiService.fetch({
      method: 'GET',
      path: '/tasks',
      searchParams: urlParams,
    });
  }

  async deleteTask(params: DeleteTaskInputDto): Promise<DeleteTaskOutputDto> {
    return this.apiService.fetch({
      method: 'DELETE',
      path: `/tasks/${params.taskId}`,
    });
  }

  async editTaskProject(
    params: EditTaskProjectInputDto,
  ): Promise<EditTaskProjectOutputDto> {
    return this.apiService.fetch({
      method: 'PUT',
      path: `/tasks/${params.taskId}`,
      body: {
        projectId: params.projectId,
        plannedTaskId: null,
      },
    });
  }

  async getAllocations(
    params: GetUserAllocationsInputDto,
  ): Promise<GetUserAllocationsOutputDto> {
    return this.apiService.fetch({
      method: 'GET',
      path: `/teams/${params.allocationId}/allocations`,
    });
  }

  async editTaskDescription(
    params: EditTaskDescriptionInputDto,
  ): Promise<EditTaskDescriptionOutputDto> {
    return this.apiService.fetch({
      method: 'PUT',
      path: `/tasks/${params.taskId}`,
      body: {
        description: params.description,
      },
    });
  }

  async editPlannedTask(
    params: EditPlannedTaskInputDto,
  ): Promise<EditPlannedTaskOutputDto> {
    return this.apiService.fetch({
      method: 'PUT',
      path: `/tasks/${params.taskId}`,
      body: {
        plannedTaskId: params.plannedTaskId,
      },
    });
  }

  async editTaskTime(
    params: EditTaskTimeInputDto,
  ): Promise<EditTaskTimeOutputDto> {
    return this.apiService.fetch({
      method: 'PUT',
      path: `/tasks/${params.taskId}`,
      body: {
        start: params.startTime.toISOString(),
        end: params.endTime.toISOString(),
      },
    });
  }

  async changeProjectAllocation(
    params: ChangeProjectAllocationInputDto,
  ): Promise<ChangeProjectAllocationOutputDto> {
    return this.apiService.fetch({
      method: 'PUT',
      path: `/users/${params.userId}/project_allocations/${params.allocationId}`,
      body: params.projectAllocation,
    });
  }

  async getUser(params: GetUserInputDto): Promise<GetUserOutputDto> {
    return this.apiService.fetch({
      method: 'GET',
      path: `/users/${params.userId}`,
    });
  }

  async updateUser(params: UpdateUserInputDto): Promise<UpdateUserOutputDto> {
    return this.apiService.fetch({
      method: 'PUT',
      path: `/users/${params.id}`,
      body: params,
    });
  }

  async validateCompleteSignin(
    params: ValidateCompleteSigninInputDto,
  ): Promise<ValidateCompleteSigninOutputDto> {
    return this.apiService.fetch({
      method: 'GET',
      path: `/users/validate_complete_signin?email=${params.email}`,
    });
  }
}
