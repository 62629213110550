import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect } from 'react';
import ReactModal from 'react-modal';

import Button from '~/components/Button';
import API from '~/services/api';

import * as S from './styles';

interface ModalProps {
  width?: string;
  height?: string;
  maxWidth?: string;
  padding?: string;
  backgroundColor?: string;
  isOpen?: boolean;
  onRequestClose: (list?: Object[]) => void;
  modalTransformPosition?: string;
  blurBackground?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  maxHeight?: string;
  overflowY?: string;
  overflowX?: string;
  styles?: Object;
}

const ImportTemplateModal = ({
  height,
  width,
  maxHeight = '754px',
  backgroundColor,
  shouldCloseOnOverlayClick = true,
  isOpen,
  blurBackground = false,
  modalTransformPosition = 'translate(-50%, -80%)',
  onRequestClose,
  padding = '0 0 0 0',
  maxWidth = '800px',
  overflowY = 'visible',
  overflowX = 'visible',
  styles,
  ...otherProps
}: ModalProps) => {
  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: `${
        blurBackground ? 'rgba(79, 88, 122, 0.7)' : 'rgba(246, 247, 251, 0.97)'
      }`,
      zIndex: 100,
    },
    content: {
      position: 'relative',
      top: '60%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: modalTransformPosition,
      backgroundColor: `${backgroundColor ? `${backgroundColor}` : 'white'}`,
      borderRadius: 0,
      border: 'none',
      padding,
      maxWidth,
      maxHeight,
      width,
      height,
      overflow: 'visible',
      overflowY,
      overflowX,
    },
  };
  const [value, setValue] = React.useState('1');
  const [templates, setTemplates] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const [selected, setSelected] = React.useState<number | null>(null);
  const ProjectId = parseInt(window.location.pathname.split('/')[4], 10);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue.toString());
  };
  const getTemplates = async () => {
    const response = await API.team.get_planned_templates();
    const data = await response.json();
    setTemplates(data);
  };
  const getProjects = async () => {
    const response = await API.team.get_projects_with_params({
      all: true,
      has_planned_tasks: true,
    });
    const data = await response.json();
    setProjects(data);
  };
  const handleSelect = (template: { id: number }) => {
    setSelected(template.id);
  };
  useEffect(() => {
    getTemplates();
    getProjects();
  }, [isOpen]);
  return (
    <ReactModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={styles || customStyles}
      ariaHideApp={false}
    >
      <S.ModalContent>
        <Tabs
          value={value}
          onChange={handleChange}
          className="tabs"
          variant="fullWidth"
        >
          <Tab label="Importar de Template" value="1" />
          <Tab label="Importar de Projeto" value="2" />
          <S.ModalClose>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              onClick={() => onRequestClose()}
            >
              <path
                fill="#959595"
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </S.ModalClose>
        </Tabs>
        <S.TabContent>
          {value === '1' && (
            <>
              <S.TabMessage>
                Escolha abaixo o template de tarefas planejadas desejado:
              </S.TabMessage>
              <S.TemplatesContainer>
                {templates.map((template: { id: number; title: string }) => (
                  <S.Template
                    onClick={() => {
                      handleSelect(template);
                    }}
                    className={selected === template.id ? 'selected' : ''}
                  >
                    {template.title}
                    {selected === template.id && <S.CheckIcon />}
                  </S.Template>
                ))}
              </S.TemplatesContainer>
            </>
          )}
          {value === '2' && (
            <>
              <S.TabMessage>
                Escolha abaixo o projeto que você deseja importar as tarefas
                planejadas:
              </S.TabMessage>
              <S.TemplatesContainer>
                {projects.map((project: { id: number; name: string }) => (
                  <S.Template
                    onClick={() => {
                      handleSelect(project);
                    }}
                    className={selected === project.id ? 'selected' : ''}
                  >
                    {project.name}
                    {selected === project.id && <S.CheckIcon />}
                  </S.Template>
                ))}
              </S.TemplatesContainer>
            </>
          )}
          <Button
            onClick={async () => {
              if (selected) {
                if (value === '1') {
                  const response = await API.team.import_planned_template(
                    ProjectId,
                    {
                      projectId: ProjectId,
                      planned_template_id: selected,
                    },
                  );
                  const data = await response.json();
                  onRequestClose(data);
                } else {
                  const response = await API.team.import_planned_from_project(
                    ProjectId,
                    {
                      projectId: ProjectId,
                      importProjectId: selected,
                    },
                  );
                  const data = await response.json();
                  onRequestClose(data);
                }
              }
            }}
            style={{ marginTop: '24px', padding: '10px 50px' }}
          >
            Confirmar Escolha
          </Button>
        </S.TabContent>
      </S.ModalContent>
    </ReactModal>
  );
};
export default ImportTemplateModal;
