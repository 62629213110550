import React from 'react';

import * as S from './styles';

export type OptionsType = {
  text: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
  onClickAction?: 'OPEN_COMPONENT' | 'CLOSE_DROPDOWN';
  component?: React.ReactNode;
  disabled?: boolean;
};

type Props = {
  options: OptionsType[];
  openComponent: (index: number) => void;
  closeDropdown?: () => void;
  handleOptionWithCloseDropdown: () => void;
};

const OptionsSelectDropdown = ({
  options,
  openComponent,
  closeDropdown,
  handleOptionWithCloseDropdown,
}: Props) => {
  const composeOnClick = (option: OptionsType, index: number) => () => {
    if (option.disabled) return;
    if (option.onClickAction === 'OPEN_COMPONENT') openComponent(index);
    if (
      (option.onClickAction === 'CLOSE_DROPDOWN' || !option.onClickAction) &&
      closeDropdown
    ) {
      closeDropdown();
      handleOptionWithCloseDropdown();
    }
    if (option.onClick) option.onClick();
  };
  return (
    <S.SelectWrapper>
      {options.map((option, index) => (
        <S.Item
          key={option.text?.toString()}
          onClick={composeOnClick(option, index)}
          disabled={option.disabled}
        >
          {option.icon}
          {option.text}
        </S.Item>
      ))}
    </S.SelectWrapper>
  );
};

export default OptionsSelectDropdown;
