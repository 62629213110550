import React, { useState, useEffect } from 'react';

import Modal from '~/components/Modal';

import AuthService from '../../../services/api/auth';
import ExtensionModal from '../ExtensionModal';

import PageIndex from './PageIndex';
import LaborExampleSvg from './images/laborExample';
import { Container, Title, HeaderText, LaborButton } from './styles';

type FirstPageProps = {
  setStep: (number) => void;
};

const FirstPage = ({ setStep, ...otherProps }: FirstPageProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [userName, setUserName] = useState('usuário');
  const [, setTeamName] = useState('');

  const onRequestClose = () => {
    localStorage.setItem('welcomeModalSeen', 'true');
    setModalIsOpen(false);
  };
  useEffect(() => {
    const loggedUser = AuthService.getLoggedUser();
    setTeamName(loggedUser.allocations[0].team.name);
    setUserName(loggedUser.name);
  }, []);
  return (
    <Modal
      hasCloseButton
      width="689px"
      shouldCloseOnOverlayClick={false}
      backgroundColor="#FFFFFF"
      onRequestClose={onRequestClose}
      isOpen={modalIsOpen}
      blurBackground
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      <Container>
        <Title>Olá, {userName}</Title>
        <HeaderText>
          Boas vindas ao Labor! A partir de agora você poderá trabalhar em suas
          tarefas e compartilhar seu andamento com o toda a equipe do seu
          projeto.
        </HeaderText>
        <LaborExampleSvg />
        <PageIndex currentPage={1} numberOfPages={2} />
        <LaborButton
          type="button"
          onClick={() => {
            setStep(2);
          }}
        >
          Próximo
        </LaborButton>
      </Container>
    </Modal>
  );
};

const WelcomeModal = ({ ...otherProps }) => {
  const [step, setStep] = useState(1);

  return step === 1 ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FirstPage {...otherProps} setStep={setStep} />
  ) : (
    <ExtensionModal hasStepComponent />
  );
};

export default WelcomeModal;
