import styled from '@emotion/styled';
import ReactSelect from 'react-select';

export const SectionTitle = styled.span`
  color: #7d7d7d;
  font-weight: bold;
  margin: 13.28px 0;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: 40px;
  margin-right: 40px;
  &.board {
    margin-left: 0px;
  }
  .trelloCell {
    margin-top: 10px;
    width: 100%;
  }
`;
export const Description = styled.span`
  color: #5f5f5f;
  margin-bottom: 40px;
`;
export const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;
export const ColumnLabel = styled.label`
  display: block;
  color: rgba(0, 0, 0, 0.38);
  font-size: 13px;
  margin-bottom: 5px;
`;
export const Select = styled(ReactSelect)`
  -webkit-appearance: none;
  border: none;
  background: none;
  color: inherit;
  text-align: right;
  background-color: white;
  width: 226px;
  height: 36px;
  font-size: 14px;
  display: inline-block;
  /* margin-left: 8px; */
  margin-right: 21px;
  margin-bottom: 38px;

  .columnSelect__single-value {
    color: hsl(0, 0%, 50%);
    margin-left: 2px;
    margin-right: 2px;
    overflow: visible;
  }
  .columnSelect__control {
    background-color: inherit;
    border: 1px solid rgba(149, 149, 149, 0.2);
    border-radius: 0;
    :hover {
      border: 1px solid #4f587a;
    }
    align-items: center;
  }
  .columnSelect__control--menu-is-open {
    box-shadow: none;
    border: 1px solid #4f587a;
  }

  .columnSelect__control--is-focused {
    box-shadow: none;
  }
  .columnSelect__menu {
    background-color: inherit;
    border: 1px solid rgba(149, 149, 149, 0.2);
    border-radius: 0;
    text-align: left;
  }
  .columnSelect__indicators {
    .columnSelect__dropdown-indicator {
      color: #7d7d7d;
      padding: 8px 20px 8px 8px;
    }
  }
  .columnSelect__value-container {
    margin-left: 4px;
    overflow: visible;
    width: 21px;
    padding: 14px 20px;
  }
  .columnSelect__placeholder {
    text-align: start;
  }
`;
export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid rgba(150, 150, 150, 0.3);
  margin-top: 40px;
  gap: 16px;
  font-size: 16px;
`;
