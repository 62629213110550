import styled from '@emotion/styled';
import React from 'react';

import { mq } from '../../../helpers/responsive';
import Container from '../Container';

import BottomFooter from './BottomFooter';


const StyledFooter = styled.footer`
  ${mq({
    borderTop: '1px solid #e2e5f1',
    display: 'flex',
    flexDirection: 'column',
    padding: ['25px 0', '28px 0'],
  })}
`;

export default function Footer() {
  return (
    <StyledFooter>
      <Container style={{ display: 'flex', flexDirection: 'column' }}>
        <BottomFooter />
      </Container>
    </StyledFooter>
  );
}
