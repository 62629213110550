import React, { useEffect, useState } from 'react';

import useProjectsTasksStore from '~/store/projectsTasks';

import TaskDurationBar from './TaskDurationBar';
import TasksList from './TasksList';
import {
  LoadMoreTasksButton,
  ButtonContainer,
  StyledCircularProgress,
} from './styles';

const TasksPage = ({ $stateParams }) => {
  const {
    fetchProjectsTasks,
    increaseNumberOfLoadMorePressed,
    removeNumberOfLoadMorePressed,
  } = useProjectsTasksStore();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    removeNumberOfLoadMorePressed();
    fetchProjectsTasks($stateParams.projectId);
    return () => {
      removeNumberOfLoadMorePressed();
    };
  }, []);
  return (
    <div>
      {!$stateParams.projectId && <TaskDurationBar />}
      {TasksList({ $stateParams })}

      <ButtonContainer>
        {loading ? (
          <StyledCircularProgress color="warning" />
        ) : (
          <LoadMoreTasksButton
            onClick={() => {
              setLoading(true);
              increaseNumberOfLoadMorePressed();
              fetchProjectsTasks($stateParams.projectId);
              setTimeout(() => {
                setLoading(false);
              }, 500);
            }}
          >
            Carregar Mês Anterior
          </LoadMoreTasksButton>
        )}
      </ButtonContainer>
    </div>
  );
};

export default TasksPage;
