import '@babel/polyfill';
import { Global } from '@emotion/react';
import React from 'react';

import Footer from '~/site/components/Footer';

import * as S from './styles'

interface ErrorPageProps{
    children: React.ReactNode,
}

const ErrorPage: React.FC<ErrorPageProps> = ({
    children,
} : ErrorPageProps) => (
    <S.PageDiv>
      <Global styles={S.GlobalStyle}/>
        <S.PageContainer>
            <S.Container> 
                {children}
            </S.Container>
            <Footer/>
        </S.PageContainer>
    </S.PageDiv>
);

export default ErrorPage;