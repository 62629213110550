import apiFetch from '~/helpers/apiFetch';

export type ProjectsParams = {
  all?: boolean;
  has_planned_tasks?: boolean;
  status?: '' | 'not_started' | 'running' | 'finished';
  order?: 'alphabetical' | 'oldest' | 'newest';
};

class TeamAPI {
  get_teams(): Promise<Response> {
    return apiFetch('/teams');
  }

  get_team_details(teamId: number): Promise<Response> {
    return apiFetch(`/teams/${teamId}`);
  }

  check_code(cod_invite) {
    // @ts-ignore
    return fetch(`${API_URL}/teams/valid_cod_invite`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        cod_invite,
      },
    });
  }

  get_projects(): Promise<Response> {
    return apiFetch(`/projects?all=true`);
  }

  get_planned_tasks(projectId: number | string): Promise<Response> {
    return apiFetch(`/projects/${projectId}/planned_tasks`);
  }

  change_planned_task(body,projectId: number, plannedTaskId: number): Promise<Response> {
    return apiFetch(`/projects/${projectId}/planned_tasks/${plannedTaskId}`, 'PUT', null, body);
  }

  add_planned_task(body, projectId: number): Promise<Response> {
    return apiFetch(`/projects/${projectId}/planned_tasks`, 'POST', null, body);
  }

  remove_planned_task(projectId: number, plannedTaskId: number): Promise<Response> {
    return apiFetch(`/projects/${projectId}/planned_tasks/${plannedTaskId}`, 'DELETE');
  }

  get_projects_with_params(params: ProjectsParams): Promise<Response> {
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    return apiFetch(`/projects?${queryString}`);
  }

  getTeamUsers(teamId: number): Promise<Response> {
    return apiFetch(`/teams/${teamId}/allocations/team_users`);
  }

  create_project(project) {
    return apiFetch('/projects', 'POST', null, project);
  }

  change_project(body, projectId: number): Promise<Response> {
    return apiFetch(`/projects/${projectId}`, 'PUT', null, body);
  }

  change_project_trello_board(body, projectId: number): Promise<Response> {
    return apiFetch(`/projects/${projectId}/update_trello_board`, 'PUT', null, body);
  }

  add_multiple_users(teamId: number, body): Promise<Response> {
    return apiFetch(
      `/teams/${teamId}/allocations/create_multiple`,
      'POST',
      null,
      body,
    );
  }

  get_planned_templates() : Promise<Response> {
    return apiFetch(`/planned_templates`);
  }

  import_planned_template(projectId: number, body) : Promise<Response> {
    return apiFetch(`/projects/${projectId}/planned_tasks/import_template`, 'POST', null, body);
  }

  import_planned_from_project(projectId: number, body) : Promise<Response>{
    return apiFetch(`/projects/${projectId}/planned_tasks/import_project`, 'POST', null, body);
  }

  reorder_planned_tasks(projectId: number, body) : Promise<Response>{
    return apiFetch(`/projects/${projectId}/planned_tasks/reorder`, 'PUT', null, body);
  }
}

export default new TeamAPI();
