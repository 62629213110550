import { faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';

import Modal from '~/components/Modal';
import api from '~/services/api';

import ExtensionModal from './ExtensionModal';
import InviteTeam from './InviteTeam';
import ProjectCreation from './ProjectCreation';
import StepProgress from './StepProgress';
import { HeaderModal, CloseButton } from './styles';

import type NotificationServiceType from '~/models/ServicesTypes';

type OnboardingModalProps = {
  NotificationService: NotificationServiceType;
  trialMode: boolean;
  minWidth: string;
  maxHeight: string;
  maxWidth: string;
  blurBackground: boolean;
};
const OnboardingModal = ({
  NotificationService,
  trialMode,
  ...otherProps
}: OnboardingModalProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [isOnBoardingModalClosed, setIsOnBoardingModalClosed] = useState(false);
  const [multipleUsers, setmultipleUsers] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [projectIdCreated, setProjectIdCreated] = useState<number>();

  const onRequestClose = () => {
    setModalIsOpen(false);
    setIsOnBoardingModalClosed(true);
  };
  useEffect(() => {
    const {
      team: { id: teamId },
    } = JSON.parse(localStorage.getItem('loggedUser') || '{}');
    api.team
      .get_team_details(teamId)
      .then((response) => response.json())
      .then(({ size }) => {
        if (size > 1) setmultipleUsers(true);
      });
  }, []);

  return (
    <>
      <Modal
        hasCloseButton={false}
        width="689px"
        shouldCloseOnOverlayClick={false}
        backgroundColor="#FFFFFF"
        onRequestClose={onRequestClose}
        isOpen={modalIsOpen}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      >
        {multipleUsers && (
          <HeaderModal>
            <StepProgress
              currentStep={currentStep}
              steps={['Criar projeto', 'Convidar equipe']}
            />
            {projectIdCreated && (
              <CloseButton
                size="lg"
                icon={faTimes}
                onClick={() => onRequestClose()}
              />
            )}
          </HeaderModal>
        )}

        {currentStep === 1 ? (
          <ProjectCreation
            setCurrentStep={setCurrentStep}
            multipleUsers={multipleUsers}
            setProjectIdCreated={setProjectIdCreated}
            onRequestModalClose={onRequestClose}
            NotificationService={NotificationService}
          />
        ) : (
          <InviteTeam
            projectIdCreated={projectIdCreated}
            userInTrial={trialMode}
            onRequestModalClose={onRequestClose}
            NotificationService={NotificationService}
          />
        )}
      </Modal>
      {isOnBoardingModalClosed && (
        <ExtensionModal isOnBoardingModalClosed={isOnBoardingModalClosed} />
      )}
    </>
  );
};
export default OnboardingModal;
