import React, { useState } from 'react';

import Modal from '~/components/Modal';
import useOnboardingStore from '~/store/onboarding';

import PageIndex from '../WelcomeModal/PageIndex';
import LaborExtensionExample from '../WelcomeModal/images/LaborExtensionExample';

import {
  Container,
  Title,
  HeaderText,
  LaborButton,
  RemindButton,
} from './styles';


type ExtensionModalProps = {
  hasStepComponent?: boolean;
  isOnBoardingModalClosed?: boolean;
};
const ExtensionModal = ({
  hasStepComponent = false,
  isOnBoardingModalClosed = false,
}: ExtensionModalProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const changeOpenWelcomeTourModal = useOnboardingStore(
    (state) => state.changeOpenWelcomeTourModal,
  );
  const onRequestClose = () => {
    setModalIsOpen(false);
    localStorage.removeItem('needToCompleteOnboarding');
    localStorage.setItem('extensionModalSeen', 'true');
    changeOpenWelcomeTourModal(true);
    if (isOnBoardingModalClosed) window.location.reload();
  };
  return (
    <Modal
      hasCloseButton
      width="689px"
      maxHeight="100%"
      maxWidth="100%"
      shouldCloseOnOverlayClick={false}
      backgroundColor="#FFFFFF"
      onRequestClose={onRequestClose}
      isOpen={modalIsOpen}
      blurBackground
    >
      <Container>
        <Title>Acompanhe seu andamento</Title>
        <HeaderText>
          É muito mais simples trabalhar com o Labor te acompanhando. Baixe
          agora a extensão para o Chrome para cronometrar suas horas.
        </HeaderText>
        <LaborExtensionExample />
        {hasStepComponent && <PageIndex currentPage={2} numberOfPages={2} />}
        <LaborButton
          type="button"
          onClick={() => {
            window.open(
              'https://chrome.google.com/webstore/detail/labor/ljdglolfladhpiemjpmiclhhchjfgehe',
            );
            onRequestClose();
          }}
        >
          Baixar a extensão
        </LaborButton>
        <RemindButton
          type="button"
          onClick={() => {
            const remindDate = new Date();
            // Three days later
            remindDate.setDate(new Date().getDate() + 3);
            localStorage.setItem('remindExtension', remindDate.toISOString());
            onRequestClose();
          }}
        >
          Lembre-me depois
        </RemindButton>
      </Container>
    </Modal>
  );
};

export default ExtensionModal;
