import chroma from 'chroma-js';
import PropTypes from 'prop-types';
import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import AxisLabel from './CustomAxisLabel';
import CustomLegendContent from './CustomLegendContent';

function formatDate(string) {
  const parts = string.match(/(\d{4})(\d{2})(\d{2})/);
  const month = parts[2];
  const day = parts[3];

  return `${day}/${month}`;
}

export default function CumulativeChart({ dailyHours, fillColor }) {
  const data = Object.entries(dailyHours).map(([date, values]) => ({
    date: formatDate(date),
    ...Object.entries(values).reduce(
      (acc, [key, value]) => ({
        ...acc, // eslint-disable-next-line
        [key]: parseInt(value),
      }),
      {},
    ),
  }));
  return (
    <ResponsiveContainer width="100%" height={350}>
      <AreaChart
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="7 7" />
        <XAxis dataKey="date" tick={{ fontSize: '0.8em' }} />
        <YAxis
          tick={{ fontSize: '0.8em' }}
          label={
            <AxisLabel axisType="yAxis" x={10} y={70} height={200}>
              Quantidade de Horas
            </AxisLabel>
          }
        />
        <Tooltip />
        <Legend verticalAlign="top" height={40} content={CustomLegendContent} />
        <Area
          type="basis"
          dataKey="cumulativeAccomplished"
          stroke={fillColor || '#8884d8'}
          fillOpacity={1}
          fill={fillColor || '#8884d8'}
          name="Horas realizadas"
        />
        <Area
          type="basis"
          dataKey="cumulativeExpected"
          stroke={chroma(fillColor || '#8884d8')
            .brighten(1.5)
            .hex()}
          fillOpacity={0.2}
          fill={chroma(fillColor || '#8884d8')
            .brighten(1.5)
            .hex()}
          name="Horas previstas"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

CumulativeChart.propTypes = {
  dailyHours: PropTypes.shape(
    PropTypes.shape({
      accomplished: PropTypes.number,
      cumulativeAccomplished: PropTypes.number,
      cumulativeExpected: PropTypes.number,
      expected: PropTypes.number,
    }),
  ),
  fillColor: PropTypes.string,
};
