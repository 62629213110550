import apiFetch from '~/helpers/apiFetch';

class ProjectAPI {
    getAllocations(id) {
        return apiFetch(`/projects/${id}/allocations`);
    }

    getTrelloBoard(id){
        return apiFetch(`/projects/${id}/trello_board`);
    }

    change_allocation(body, projectId: number, allocationId: number): Promise<Response> {
        return apiFetch(`/projects/${projectId}/allocations/${allocationId}`, 'PUT', null, body);
    }

    delete_allocation(projectId: number, allocationId: number): Promise<Response> {
        return apiFetch(`/projects/${projectId}/allocations/${allocationId}`, 'DELETE');
    }

    remove_multiple_users(projectId: number, body){
        return apiFetch(`/projects/${projectId}/remove_multiple_users`, 'PUT', null, body);
    }


}
export default new ProjectAPI();
