import React, { useState, useEffect } from 'react';
import shallow from 'zustand/shallow';

import SearchableSelect from '~/app/components/Select/SearchableSelect';
import API from '~/services/api';
import useReportsStore from '~/store/reports/reports';

import type {
  Item,
} from '~/app/components/Select/SearchableSelect';
import type { PlannedTask } from '~/models/types';

const PlannedSelect = () => {
  const [planneds, setPlanneds] = useState<Item[]>([]);
  const [fetchFlag, setFetchFlag] = useState(true);

  const { setSelectedPlannedsIds, selectedProjectsIds, hasChangeFlags } =
    useReportsStore(
      (state) => ({
        setSelectedPlannedsIds: state.setSelectedPlannedsIds,
        selectedProjectsIds: state.selectedProjectsIds,
        hasChangeFlags: state.hasChangeFlags,
      }),
      shallow,
    );

  const selectedPlanneds = planneds.filter((planned) => planned.checked);

  const calculateDisplayName = (): string => {
    if (
      selectedPlanneds.length === planneds.length ||
      selectedPlanneds.length === 0
    )
      return 'Todas as tarefas';
    if (selectedPlanneds.length === 1) {
      return selectedPlanneds[0].description || 'Tarefa';
    }
    return 'Múltiplas tarefas';
  };

  const onClose = () => {
    const plannedsIds = selectedPlanneds.map((planned) => planned.id);

    // If 'all' is selected, [] needs to be used, otherwise tasks
    // without a planned task will be excluded
    if (plannedsIds.length === planneds.length) setSelectedPlannedsIds([]);
    else setSelectedPlannedsIds(plannedsIds);
  };

  const fetchPlanned = async () => {
    let data: Item[];
    if (selectedProjectsIds.length === 1) {
      const response = await API.team.get_planned_tasks(selectedProjectsIds[0]);
      const rawData = await response.json();
      data = rawData.map((item: PlannedTask) => ({
        ...item,
        checked: true,
      }));
    } else data = [];
    setPlanneds(data);
  };

  const onOpen = () => {
    if (fetchFlag) {
      fetchPlanned();
      setFetchFlag(false);
    }
  };

  useEffect(() => {
    // Fetching is only done when modal opens
    setFetchFlag(true);
  }, [selectedProjectsIds]);

  const emptyMessage =
    selectedProjectsIds.length > 1 ? (
      <div>
        <strong>Selecione um projeto</strong> específico para filtrar por
        tarefa.
      </div>
    ) : (
      <div>
        <strong>Não existem terefas planejadas</strong> para o projeto
        selecionado
      </div>
    );

  return (
    <SearchableSelect
      data={planneds}
      setData={(data) => setPlanneds(data)}
      displayName={calculateDisplayName()}
      onOpen={onOpen}
      onClose={onClose}
      searchPlaceholder="Pesquisar tarefas"
      emptyMessage={emptyMessage}
      isPending={hasChangeFlags.planneds}
    />
  );
};

export default PlannedSelect;
