import { FormControlLabel } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import React, { useState, useEffect } from 'react';

import Input from '~/components/Input';
import Modal from '~/components/Modal';
import API from '~/services/api';

import * as S from './styles';

import type NotificationServiceType from '~/models/ServicesTypes';

type PeriodicNotificationConfigProps = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  NotificationService: NotificationServiceType;
};

interface FormProps {
  notification: boolean;
  frequency: string;
  monthday: string;
  weekday: string;
  hour: string;
  email: boolean;
}

const DropdownInput = (props) => (
  <Input
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    wrapperStyle={{ margin: '0 0 40px 0', width: '28%', maxWidth: '185px' }}
  />
);

const PeriodicNotificationConfig = ({
  setIsModalOpen,
  isModalOpen,
  NotificationService,
}: PeriodicNotificationConfigProps) => {
  const [notificationData, setNotificationData] = useState<FormProps>({
    notification: true,
    frequency: 'monthly',
    monthday: '1',
    weekday: 'monday',
    hour: 'h12',
    email: false,
  });

  const getNotificationData = async () => {
    const response = await API.report.get_reports_notifications();
    const data = response.status === 200 ? await response.json() : null;
    setNotificationData(data);
  };

  const updateNotifications = async (value) => {
    if (notificationData) {
      API.report
        .update_reports_notifications(
          value.notification,
          value.frequency,
          value.monthday,
          value.weekday,
          value.hour,
          value.email,
        )
        .then((response) => {
          if (response.status === 200) {
            getNotificationData();
            NotificationService.showNotification(
              'Notificação criada com sucesso!',
              'success',
            );
            setIsModalOpen(false);
          } else {
            NotificationService.showNotification(
              'Ocorreu um erro ao criar a notificação',
              'error',
            );
          }
          return response.json();
        });
    } else {
      API.report
        .create_reports_notifications(
          value.notification,
          value.frequency,
          value.monthday,
          value.weekday,
          value.hour,
          value.email,
        )
        .then((response) => {
          if (response.status === 201) {
            getNotificationData();
            NotificationService.showNotification(
              'Notificação criada com sucesso!',
              'success',
            );
            setIsModalOpen(false);
          } else {
            NotificationService.showNotification(
              'Ocorreu um erro ao criar a notificação',
              'error',
            );
          }
          return response.json();
        });
    }
  };

  const onRequestClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) getNotificationData();
  }, [isModalOpen]);

  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={isModalOpen}
      blurBackground
      backgroundColor="#ffffff"
      width="750px"
    >
      <S.ModalContainer>
        <S.HeaderText>Notificações periódicas</S.HeaderText>
        <S.Text>
          Escolha quando quer receber as notificações para os relatórios de um
          período escolhido.
        </S.Text>

        <Formik
          enableReinitialize
          initialValues={
            notificationData || {
              notification: false,
              frequency: 'monthly',
              monthday: '1',
              weekday: 'monday',
              hour: 'h12',
              email: false,
            }
          }
          onSubmit={(values) => {
            updateNotifications(values);
          }}
        >
          {({ values }) => (
            <Form>
              <S.DropdownContainer>
                <DropdownInput
                  as="select"
                  name="frequency"
                  value=""
                  style={{ marginRight: '20px', paddingRight: '16px' }}
                >
                  <option value="monthly">Mensal</option>
                  <option value="weekly">Semanal</option>
                </DropdownInput>
                {values.frequency === 'monthly' && (
                  <DropdownInput
                    as="select"
                    name="monthday"
                    value=""
                    style={{ marginRight: '20px', paddingRight: '16px' }}
                  >
                    <option value="1">Dia 1</option>
                    <option value="2">Dia 2</option>
                    <option value="3">Dia 3</option>
                    <option value="4">Dia 4</option>
                    <option value="5">Dia 5</option>
                    <option value="6">Dia 6</option>
                    <option value="7">Dia 7</option>
                  </DropdownInput>
                )}
                {values.frequency === 'weekly' && (
                  <DropdownInput
                    as="select"
                    name="weekday"
                    value=""
                    style={{ marginRight: '20px', paddingRight: '16px' }}
                  >
                    <option value="monday">Segunda</option>
                    <option value="tuesday">Terça</option>
                    <option value="wednesday">Quarta</option>
                    <option value="thursday">Quinta</option>
                    <option value="friday">Sexta</option>
                  </DropdownInput>
                )}

                <DropdownInput
                  as="select"
                  name="hour"
                  value=""
                  style={{ marginRight: '0', paddingRight: '16px' }}
                >
                  <option value="h8">Manhã (8h)</option>
                  <option value="h12">Tarde (12h)</option>
                  <option value="h18">Noite (18h)</option>
                </DropdownInput>
              </S.DropdownContainer>

              <Field
                as={FormControlLabel}
                type="checkbox"
                name="email"
                control={
                  <S.CheckboxInput
                    sx={{
                      color: '#959595',
                      '&.Mui-checked': {
                        color: '#FF3C58',
                      },
                    }}
                  />
                }
                label="Receber relatório por email (PDF)"
              />
              <S.SaveButton type="submit">Salvar</S.SaveButton>
            </Form>
          )}
        </Formik>
      </S.ModalContainer>
    </Modal>
  );
};

export default PeriodicNotificationConfig;
