import React from 'react';

import Button from '~/components/Button';
import Modal from '~/components/Modal';
import API from '~/services/api';
import useProjectsTasksStore from '~/store/projectsTasks';

import { Text, HeaderText, DeleteModalContainer } from './styles';

type DeleteTaskModalProps = {
  taskId: number;
  setModalOpen: (boolean) => void;
  modalIsOpen: boolean;
};
const DeleteTaskModal = ({
  taskId,
  setModalOpen,
  modalIsOpen,
}: DeleteTaskModalProps) => {
  const { fetchProjectsTasks } = useProjectsTasksStore();
  const onRequestClose = () => {
    setModalOpen(false);
  };
  const handleDeleteTask = (id) => {
    API.user.delete_task(id).then(() => {
      fetchProjectsTasks();
    });

    onRequestClose();
  };
  return (
    <Modal
      onRequestClose={onRequestClose}
      isOpen={modalIsOpen}
      blurBackground
      backgroundColor="#ffffff"
      width="340px"
    >
      <DeleteModalContainer>
        <HeaderText>Deseja realmente remover a tarefa?</HeaderText>
        <Text>Essa ação não pode ser desfeita.</Text>
        <Button
          onClick={() => {
            handleDeleteTask(taskId);
          }}
        >
          Confirmar
        </Button>
      </DeleteModalContainer>
    </Modal>
  );
};

export default DeleteTaskModal;
