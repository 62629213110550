import styled from '@emotion/styled';
import { MdPlayArrow, MdClose, MdLock } from 'react-icons/md';

import PlannedTaskSelect from '~/app/components/Select/PlannedTaskSelect';
import ProjectSelect from '~/app/components/Select/ProjectSelect';

type IconContainerProps = {
  focused: boolean;
};

type CloseIconProps = {
  disabled: boolean;
};

export const plannedTaskSelectStyle = {
  control: () => ({
    display: 'flex',
    cursor: 'pointer',
    outline: 'none',
    addingBottom: '10px',
    margin: '0 10px 0 10px',
    marginBottom: '-1px',
    borderBottom: 'solid 1px transparent',
  }),

  indicatorSeparator: () => null,
  // eslint-disable-next-line
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    minWidth: '250px',
    width: 'auto',
    whiteSpace: 'initial',
    // eslint-disable-next-line
    backgroundColor: isDisabled ? null : isFocused ? '#f6f7fb' : null,
    color: '#959595',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    fontSize: '0.75em',
    padding: '10px 20px 10px 20px',

    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && (isSelected ? '#f6f7fb' : 'none'),
    },
  }),
  loadingMessage: (styles) => ({
    ...styles,
    fontSize: '0.8em',
    color: '#959595',
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: '0.8em',
    color: '#959595',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.8em',
    color: '#959595',
  }),
  menu: (styles) => ({
    ...styles,
    whiteSpace: 'nowrap',
    width: 'auto',
    minWidth: '250px',
    right: 0,
    padding: '10px 0',
    borderRadius: 0,
    boxShadow: '0 2px 18px 4px rgba(0, 0, 0, 0.03)',
    backgroundColor: '#fff',
    zIndex: 100,
  }),
};
export const StyledPlannedTasksSelect = styled(PlannedTaskSelect)`
  margin-right: 20px;
  svg {
    font-size: 0.6rem;
  }
  /*   div {
    height: 30px;
  } */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
export const StyledProjectSelect = styled(ProjectSelect)`
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  svg {
    visibility: hidden;
  }
  &:hover: {
    border: none;
  }
`;
export const CloseIcon = styled(MdClose)<CloseIconProps>`
  color: #d7d7d7 !important;
  &:hover {
    color: #959595 !important;
  }
  visibility: ${(props) => props.disabled && 'hidden'};
`;
export const PlayIcon = styled(MdPlayArrow)`
  color: #d7d7d7 !important;
  &:hover {
    color: #959595 !important;
  }
`;

export const LockIcon = styled(MdLock)`
  color: #d7d7d7 !important;
  &:hover {
    color: #959595 !important;
  }
`;
export const TaskContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #eee;
  padding: 0.5rem 27px 0.5rem 40px;
`;

export const TaskInterval = styled.div`
  margin-right: 2rem;
  font-size: 0.8em;
  align-items: center;
`;

export const TaskDuration = styled.div`
  font-size: 1rem;
`;

export const TaskDescription = styled.input`
  flex: 1;
  border: none;
  color: #959595;
  &:disabled {
    background: #ffffff;
  }
  &:focus {
    outline-width: 0;
  }
`;

export const IconsContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  visibility: ${(props) => (props.focused ? 'visible' : 'hidden')};
`;

export const LockContainer = styled.div`
  height: 24px;
  margin-right: 15px;
`;

export const CalendarButton = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
`;
