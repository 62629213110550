import React, { useState } from 'react';

import Button, { LightButton } from '~/components/Button';
import Modal from '~/components/Modal';

import * as S from './styles';

import type { ProjectAllocationWithCheck, User } from '~/models/types';

const Member = ({ user }: { user: User }) => (
  <S.ModalUser>
    <S.ModalUserName>{`${user.name}, `}</S.ModalUserName>
    <S.ModalUserRole>
      {user.role === 'colaborador' ? 'Colaborador(a)' : 'Gerente'}
    </S.ModalUserRole>
  </S.ModalUser>
);

const DeleteUserModal = ({
  isOpen,
  onClose,
  onConfirm,
  projectAllocations,
}: {
  isOpen: boolean;
  onClose(): void;
  onConfirm(): void;
  projectAllocations: ProjectAllocationWithCheck[];
}) => {
  const [confirmed, setConfirmed] = useState(false);

  if (confirmed === false)
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        blurBackground
        overflowY="auto"
        backgroundColor="#FFFFFF"
      >
        <S.ModalTitle>
          {projectAllocations.length > 1
            ? 'Retirar membros do projeto '
            : 'Retirar membro do projeto '}
          <S.ModalProjectName>
            {projectAllocations[0].project.name}
          </S.ModalProjectName>
        </S.ModalTitle>
        <hr />
        {projectAllocations.length > 1 ? (
          <S.StaticText>
            <p>
              Ao retirar os membros, eles não poderão contabilizar horas
              trabalhadas no projeto e novas tarefas não poderão ser alocadas a
              esses membros.
            </p>

            <p>Tem certeza de que deseja retirar os membros?</p>
          </S.StaticText>
        ) : (
          <S.StaticText>
            <p>
              Ao retirar o membro, ele não poderá contabilizar horas trabalhadas
              no projeto e novas tarefas não poderão ser alocadas a esse membro.
            </p>

            <p>Tem certeza de que deseja retirar o membro?</p>
          </S.StaticText>
        )}
        <S.ModalUserContainer>
          {projectAllocations.map((obj) => (
            <Member user={obj.user} key={obj.user.id} />
          ))}
        </S.ModalUserContainer>

        <S.ModalButtonContainer>
          <LightButton onClick={onClose}>Cancelar</LightButton>
          <Button
            onClick={() => {
              onConfirm();
              setConfirmed(true);
            }}
          >
            <S.RemovePerson className="removeProject" />
            <span>Retirar deste projeto</span>
          </Button>
        </S.ModalButtonContainer>
      </Modal>
    );
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      blurBackground
      overflowY="auto"
      backgroundColor="#FFFFFF"
    >
      <S.ModalTitle>
        {projectAllocations.length > 1
          ? 'Retirar membros do projeto '
          : 'Retirar membro do projeto '}
        <S.ModalProjectName>
          {projectAllocations[0].project.name}
        </S.ModalProjectName>
      </S.ModalTitle>
      <hr />
      {projectAllocations.length > 1 ? (
        <S.StaticText>
          <p>Membros retirados.</p>
        </S.StaticText>
      ) : (
        <S.StaticText>
          <p>Membro retirado.</p>
        </S.StaticText>
      )}
      <S.ModalButtonContainer>
        <Button onClick={onClose}>
          <span>Fechar</span>
        </Button>
      </S.ModalButtonContainer>
    </Modal>
  );
};

export default DeleteUserModal;
