import styled from '@emotion/styled';

import theme from '~/theme';

export const ModalClose = styled.div`
  display: flex;
  cursor: pointer;
  width: 24px;
  margin-right: 14px;
`;

export const ModalContainer = styled.div`
  background-color: ${theme.colors.white};
  box-shadow: 0 0 8px 12px rgba(0, 0, 0, 0.02);
  min-width: 580px;
  min-height: 285px;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  background-color: ${theme.colors.containerBg};
  border-color: rgba(0,0,0,0.12);
  border-width: 0 0 1px;
  border-style: solid;
`;

export const NavBar = styled.div`
  display: flex;
  height: 48px;
`;

export const TabBar = styled.a<{ selected: boolean }>`
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: ${(props) => props.selected ? theme.colors.primary : theme.colors.gray23};
  border-bottom: ${(props) => props.selected ? `2px solid ${theme.colors.primary}` : '0'};
  height: 24px;
  display: flex;
  align-items: center;
  padding: 12px 22px;
`;