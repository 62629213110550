import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { MdEdit } from 'react-icons/md';
import Select from 'react-select';


import NotificationModal from '~/components/NotificationModal/Notification';

import { LightButton } from '../../components/Button';
import IconContainer from '../../components/IconContainer';
import AuthService from '../../services/api/auth';
import Can, { ability } from '../components/Can';
import Header from '../components/Header';
import { ListHeader, Item, Column } from '../components/List';
import PageTitle from '../components/PageTitle';
import Toggle from '../components/Toggle';
import Allocation from '../models/Allocation';
import User from '../models/User';



import AddUserModal from './AddUserModal';
import './react-toggle.scss';

const FILTER_OPTIONS = [
  { value: '', label: 'Todos Status' },
  { value: 'true', label: 'Ativo' },
  { value: 'false', label: 'Inativo' },
];

export default function UsersPage({
  AllocationService,
  NotificationService,
  ProjectService,
  $state,
}) {
  const needToCompleteOnboarding = localStorage.getItem(
    'needToCompleteOnboarding',
  );
  const userModalWasSeenStorage = localStorage.getItem('userModalWasSeen');

  const [loggedUser, setLoggedUser] = useState(null);
  const [allocations, setAllocations] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [blankStateMessage, setBlankStateMessage] = useState('Carregando...');
  const [cookies, setCookie] = useCookies(['labor-user-filter-selected']);
  const [focusedUser, setFocusedUser] = useState();
  const [userModalWasSeen, setRenderUserModal] = useState(
    !needToCompleteOnboarding && !userModalWasSeenStorage,
  );

  useEffect(() => {
    setLoggedUser(AuthService.getLoggedUser());
  }, []);

  async function loadAllocations() {
    if (!loggedUser) return;

    const selectedFilter =
      cookies['labor-user-filter-selected'] || FILTER_OPTIONS[0];

    const response = await AllocationService.getAllocations({
      id: loggedUser.allocations[0].team.id,
      enabled: selectedFilter.value,
    });
    setAllocations(response.data);
  }

  useEffect(() => {
    loadAllocations();
  }, [loggedUser]);

  async function handleFilterChange(filter) {
    const response = await AllocationService.getAllocations({
      id: loggedUser.allocations[0].team.id,
      enabled: filter.value,
    });
    setAllocations(response.data);
    if (response.data.length <= 0)
      setBlankStateMessage(`Nenhum usuário ${filter.label} encontrado`);
    setCookie('labor-user-filter-selected', filter);
  }

  function handleCloseModal(params) {
    if (params && params.json) {
      loadAllocations();
      NotificationService.showNotification('Usuário convidado com sucesso!');
    }

    setModalOpen(false);
  }

  async function handleEnabledChange({ allocation, enabled }) {
    try {
      if (enabled) {
        await AllocationService.restoreAllocation(allocation.team, allocation);
      } else {
        await AllocationService.disableAllocation(allocation.team, allocation);
      }

      setAllocations(
        allocations.map((alloc) => ({
          ...alloc,
          enabled: allocation.id === alloc.id ? enabled : alloc.enabled,
        })),
      );

      NotificationService.showNotification(
        enabled
          ? 'Usuário ativado com sucesso!'
          : 'Usuário desativado com sucesso!',
      );
    } catch (e) {
      NotificationService.showNotification(
        'Ocorreu um erro ao alterar ativação do usuário!',
        'error',
      );
    }
  }

  function redirectToUser(e, user) {
    $state.go('app.main.user', {
      userId: user.id,
    });
  }

  function opactityUserRow(user) {
    if (focusedUser && user !== focusedUser) {
      return '0.5';
    }

    return '1';
  }
  const FilterSelect = () => (
    <div data-testid="select-component">
      <Select
        classNamePrefix="users-select"
        className="users-select"
        isSearchable={false}
        onChange={handleFilterChange}
        value={cookies['labor-user-filter-selected']}
        placeholder="Todos Status"
        options={FILTER_OPTIONS}
      />
    </div>
  );
  function renderUsersList(innerAllocations) {
    return (
      <>
        <ListHeader>
          <Column flex={3}>Nome</Column>
          <Column flex={2}>Email</Column>
          <Column flex={1}>Perfil</Column>
          <Column flex={1} textAlign="right">
            Status
          </Column>
          <Column style={{ flex: '0.2' }} />
        </ListHeader>
        {innerAllocations.map((allocationProps) => {
          const allocation = new Allocation(allocationProps);
          const { user, enabled } = allocation;

          return (
            <Item
              key={user.id}
              onMouseEnter={() => setFocusedUser(user)}
              onMouseLeave={() => setFocusedUser(null)}
              style={{ opacity: opactityUserRow(user) }}
            >
              <Column flex={3}>{user.name}</Column>
              <Column flex={2}>{user.email}</Column>
              <Column flex={1}>{user.role}</Column>
              <Column flex={1} textAlign="right" id="react-toggle">
                <Toggle
                  checked={enabled}
                  disabled={!ability.can('disable', allocation)}
                  onChange={(event) => {
                    handleEnabledChange({
                      allocation,
                      enabled: event.target.checked,
                    });
                  }}
                />
              </Column>
              <Column style={{ flex: '0.2', height: '22px' }}>
                {focusedUser &&
                user === focusedUser &&
                (ability.can(
                  'manage',
                  new User({ allocation: allocationProps }),
                ) ||
                  loggedUser.id === focusedUser.id) ? (
                  <IconContainer>
                    <MdEdit
                      data-testid="edit-icon"
                      onClick={(e) => {
                        redirectToUser(e, user);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </IconContainer>
                ) : (
                  <></>
                )}
              </Column>
            </Item>
          );
        })}
      </>
    );
  }

  return (
    <>
      <Header>
        <PageTitle>
          Usuários
          <FilterSelect />
        </PageTitle>
        <Can do="create" of="User">
          <LightButton hasIcon onClick={() => setModalOpen(true)}>
            <FontAwesomeIcon icon={faPlusCircle} />
            Convidar novo usuário
          </LightButton>
        </Can>
      </Header>
      {userModalWasSeen && (
        <NotificationModal
          header="Nova página de usuários"
          body="Edite as informações de cada usuário clicando no ícone que aparece ao passar com o mouse sobre a lista."
          info="Colaboradores não têm permissão para editar informações"
          buttonText="Saiba mais"
          margin="0 0 0 20px"
          modalWasSeen="userModalWasSeen"
          closeModal={setRenderUserModal}
        />
      )}

      {allocations.length > 0
        ? renderUsersList(allocations)
        : blankStateMessage}

      <Can do="create" of="User">
        <AddUserModal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          minWidth="500px"
          maxHeight="100%"
          maxWidth="100%"
          AllocationService={AllocationService}
          ProjectService={ProjectService}
        />
      </Can>
    </>
  );
}

UsersPage.propTypes = {
  AllocationService: PropTypes.object.isRequired,
  NotificationService: PropTypes.object.isRequired,
  ProjectService: PropTypes.object.isRequired,
  $state: PropTypes.shape({
    go: PropTypes.func,
  }),
};
