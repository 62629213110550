import React from 'react';
import { MdExpandMore } from 'react-icons/md';
import Select from 'react-select';

const check = (isSelected) =>
  isSelected
    ? {
        ':before': {
          content: '"check"',
          fontFamily: 'Material Icons',
          position: 'absolute',
          right: 20,
          color: '#b7b7b7',
          fontSize: 20,
        },
      }
    : {};

const colourStyles = {
  control: () => ({
    display: 'flex',
    width: 'max-content',
    cursor: 'pointer',
    outline: 'none',
    addingBottom: '10px',
    margin: '0 10px 0 0',
    marginBottom: '-1px',
    borderBottom: 'solid 1px transparent',
    '&:hover': {
      borderBottomColor: '#ff3c56',
    },
    '& > div': {
      flex: 'unset',
    },
  }),
  indicatorSeparator: () => null,
  option: (styles, { isSelected }) => ({
    ...styles,
    alignItems: 'center',
    display: 'flex',
    padding: '10px 75px 10px 20px',
    fontSize: '0.9rem',
    color: '#959595',

    ...check(isSelected),
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#959595',
    position: 'unset',
    top: 'unset',
    transform: 'unset',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#959595',
    position: 'unset',
    top: 'unset',
    transform: 'unset',
  }),
  group: (styles) => ({
    ...styles,
    padding: 0,
  }),
  groupHeading: (styles) => ({
    ...styles,
    padding: '10px',
    paddingLeft: '20px',
    fontSize: '0.8em',
    fontWeight: 900,
    margin: 0,
    color: 'rgba(0, 0, 0, 0.54)',
  }),
  menu: (styles) => ({
    ...styles,
    whiteSpace: 'nowrap',
    width: 'auto',
    minWidth: 290,
    maxHeight: 450,
    position: 'absolute',
    top: -45,
    left: 0,
    padding: '10px 0',
    borderRadius: 0,
    boxShadow: '0 2px 18px 4px rgba(0, 0, 0, 0.03)',
    backgroundColor: '#fff',
    zIndex: 100,
  }),
};

const DropdownIndicator = () => <MdExpandMore />;

const DateSelect = (props) => (
  <Select
    isSearchable={false}
    styles={colourStyles}
    placeholder="Selecione um projeto"
    components={{ DropdownIndicator }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export default DateSelect;
