import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';
import React, { useState } from 'react';

import Calendar from '~/app/ProjectsPage/Calendar';
import Input from '~/components/Input';

import { DateFieldContainer, InputBox } from '../NewProjectModal/styles';

interface Props {
  name?: string;
  dateData?: string | null;
  onChange?: ((newDate: string) => void) | Function;
}

const DateField: React.FC<Props> = ({ name, onChange, dateData }) => {
  const [disabled, setDisabled] = useState(true);

  const currentDate = dateData
    ? moment(dateData, 'DD/MM/YYYY').toDate()
    : new Date();

  return (
    <DateFieldContainer>
      <InputBox
        style={{ cursor: 'pointer' }}
        onClick={() => setDisabled(false)}
      >
        <CalendarTodayIcon
          sx={{
            verticalAlign: 'bottom',
            marginLeft: '15px',
          }}
        />
        <Input
          wrapperStyle={{
            width: '50%',
            margin: '0 0 0 15px',
          }}
          inputStyle={{
            padding: '0',
            border: 'none',
            caretColor: 'transparent',
            cursor: 'pointer',
          }}
          type="text"
          name={name || ''}
          placeholder="dd/mm/aaaa"
          value={dateData || ''}
          // Right now, date can only be changed in the calendar
          onChange={() => {}}
        />

        <KeyboardArrowDownIcon
          sx={{
            verticalAlign: 'bottom',
            position: 'absolute',
            right: '16px',
          }}
        />
      </InputBox>
      <Calendar
        name={name}
        className="creating_project"
        disabled={disabled}
        currentTime={currentDate}
        handleDateChangeModal={(newDate: Date) =>
          onChange && onChange(moment(newDate).format('DD/MM/YYYY'))
        }
        onClickOutside={() => {
          setDisabled(true);
        }}
      />
    </DateFieldContainer>
  );
};

export default DateField;
