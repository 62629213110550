import { useEffect } from 'react';

const LogoutPage = ({ LoginService }) => {
  useEffect(() => {
    LoginService.logout();
  }, []);

  return null;
};

export default LogoutPage;
