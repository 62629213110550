import create from 'zustand';
import { persist } from 'zustand/middleware';

import API from '~/services/api';
import useUserStore from '~/store/user';

import { fetchData } from './utils/projectsTasks';

import type { MultipleDailyTasks, Project, Task } from '../models/types';
import type { GetTasksProps } from '~/services/api/user';

export type ProjectSelectType = {
  value: Project;
  label: string;
  color: string;
};
export type ProjectDuration = {
  duration: number;
  name: string;
  id: number;
  color: string;
};

export type ProjectsDurationArray = {
  projectsDuration: Array<ProjectDuration>;
  totalMonthlyDuration: number;
};

type ProjectsTasksState = {
  projectSelect: Array<ProjectSelectType>;
  projectsData: Array<Project>;
  numberOfLoadMorePressed: number;
  projectsDurationInMonth: ProjectsDurationArray;
  projectsTasks: MultipleDailyTasks;
  fetchProjectsTasks: (projectId?: number) => void;
  fetchTasksDurationInMonth: (projectId?: number) => void;
  increaseNumberOfLoadMorePressed: () => void;
  removeNumberOfLoadMorePressed: () => void;
};

const { getState } = useUserStore;

const useProjectsTasksStore = create<ProjectsTasksState>(
  persist(
    (set, get) => ({
      projectSelect: [] as ProjectSelectType[],
      projectsData: [] as Project[],
      numberOfLoadMorePressed: 0,
      projectsTasks: {} as MultipleDailyTasks,
      projectsDurationInMonth: {} as ProjectsDurationArray,
      increaseNumberOfLoadMorePressed: () =>
        set((state) => ({
          numberOfLoadMorePressed: state.numberOfLoadMorePressed + 1,
        })),
      removeNumberOfLoadMorePressed: () => {
        set({ numberOfLoadMorePressed: 0 });
      },
      fetchTasksDurationInMonth: async (projectId?: number) => {
        const firstDayOfMonth = new Date();
        firstDayOfMonth.setDate(1);
        firstDayOfMonth.setHours(0, 0, 0, 0);

        const lastDayOfMonth = new Date();
        lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
        lastDayOfMonth.setDate(0);
        let taskList;
        let projectData;
        if (get().numberOfLoadMorePressed !== 0) {
          const params: GetTasksProps = {
            starting: firstDayOfMonth.toISOString(),
            ending: lastDayOfMonth.toISOString(),
            includes: 'planned_task',
            projectIds: projectId,
          };
          if (!projectId) params.userIds = getState().user.id;
          const responseTasks = await API.user.get_tasks(params);
          const responseProjects = await API.team.get_projects();
          const projectsData = await responseProjects.json();
          const tasksInMonth = await responseTasks.json();
          taskList = tasksInMonth;
          projectData = projectsData;
        } else {
          taskList = get().projectsTasks;
          projectData = get().projectsData;
        }

        let tempProjectDurationArray: ProjectDuration[] = [];
        let tempTotalMonthlyDuration = 0;

        Object.values(taskList).forEach((tasksInDay: { tasks: Task[] }) => {
          tasksInDay.tasks.forEach((task) => {
            if (
              tempProjectDurationArray.find(
                (project) => project.id === task.projectId,
              )
            ) {
              const newProjectDuration = tempProjectDurationArray.map(
                (projectDuration) =>
                  projectDuration.id === task.projectId
                    ? {
                        ...projectDuration,
                        duration: projectDuration.duration + task.duration,
                      }
                    : projectDuration,
              );
              tempTotalMonthlyDuration = +task.duration;
              tempProjectDurationArray = newProjectDuration;
            } else {
              const { name, tagColor } = projectData.find(
                (project) => project.id === task.projectId,
              );
              const newProjectDuration = {
                duration: task.duration,
                name,
                id: task.projectId,
                color: tagColor,
              };
              tempTotalMonthlyDuration = +task.duration;
              tempProjectDurationArray.push(newProjectDuration);
            }
          });
        });

        tempProjectDurationArray.sort((a, b) => b.duration - a.duration);
        set({
          projectsDurationInMonth: {
            projectsDuration: tempProjectDurationArray,
            totalMonthlyDuration: tempTotalMonthlyDuration,
          },
        });
      },

      fetchProjectsTasks: async (projectId?: number) =>
        fetchData(get, set, projectId),
    }),
    {
      name: 'useProjectsTasksStore',
    },
  ),
);

export default useProjectsTasksStore;
