import styled from '@emotion/styled';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import LoggedUserContext from '../../context/LoggedUserContext';
import Header from '../components/Header';
import PageTitle from '../components/PageTitle';
import { PopoverDisclosure, usePopoverState } from '../components/Popover';
import ProjectSelect from '../components/Select/ProjectSelect';
import { dateToString } from '../utils/date';

import BoxContainer from './BoxContainer';
import CalendarContext from './CalendarContext';
import ProfitabilityCharts from './ProfitabilityCharts';
import ProfitabilitySummary from './ProfitabilitySummary';
import PeriodPopover from './popovers/PeriodPopover';

const Picker = styled.div`
  font-size: 0.9em;
  display: inline-block;
  cursor: pointer;
  outline: none;
  -color: #ff3c56;
  svg {
    font-size: 0.8rem;
    margin-left: 10px;
  }
`;

const BorderedHeader = styled(Header)`
  border-bottom: 1px solid #d7d7d7;
  display: flex;
  align-items: flex-end;
`;

const PeriodPopoverDisclosure = styled(PopoverDisclosure)`
  padding-bottom: 10px;
  margin: 0 10px 0 0;
  margin-bottom: -1px;
  border-bottom: solid 1px transparent;
  &:hover {
    border-bottom-color: #ff3c56;
  }
`;

const ProjectSelectContainer = styled.div`
  margin-left: 20px;
`;

const ColumnTitle = styled.h2`
  font-size: 1rem;
  margin: 0 0 30px 0;
`;

const Paragraph = styled.p`
  margin-top: 20px;
  text-align: left;
`;

export default function ProfitabilityPage({ ProjectService, TeamService }) {
  const firstDayInMonth = new Date();
  firstDayInMonth.setDate(1);
  firstDayInMonth.setHours(0, 0, 0, 0);

  const lastDayInMonth = new Date();
  lastDayInMonth.setMonth(lastDayInMonth.getMonth() + 1);
  lastDayInMonth.setDate(0);
  lastDayInMonth.setHours(0, 0, 0, 0);

  const allProjectsOption = {
    value: null,
    label: 'Todos projetos',
    color: '#959595',
  };

  const [startDate, setStartDate] = useState(firstDayInMonth);
  const [endDate, setEndDate] = useState(lastDayInMonth);

  const [projects, setProjects] = useState([allProjectsOption]);
  const [project, setProject] = useState(null);

  const [statistics, setStatistics] = useState(null);

  const periodPopoverState = usePopoverState();
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

  async function fetchProjects() {
    const response = await ProjectService.getAllProjects();

    const newProjects = [
      allProjectsOption,
      ...response.data.map((p) => ({
        value: p,
        label: p.name,
        color: p.tagColor,
      })),
    ];
    setProjects(newProjects);
  }

  async function fetchTeamStatistics() {
    const response = await TeamService.getTeamStatistics(
      loggedUser.team,
      startDate ? startDate.toISOString() : null,
      endDate ? endDate.toISOString() : null,
    );
    setStatistics(response.data);
  }

  async function fetchProjectStatistics() {
    const response = await ProjectService.getProjectStatistics(
      project.value,
      startDate ? startDate.toISOString() : null,
      endDate ? endDate.toISOString() : null,
    );
    setStatistics(response.data);
  }

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (!periodPopoverState.visible && (!project || !project.value)) {
      fetchTeamStatistics();
    } else if (
      project &&
      project.value &&
      !project.value['pendingProfitInfo?'] &&
      project.value.category === 'external'
    ) {
      fetchProjectStatistics();
    }
  }, [periodPopoverState.visible, project]);

  useEffect(() => {
    if (!project) {
      setProject(projects[0]);
    }
  }, [projects]);

  return (
    <>
      <BorderedHeader>
        <LoggedUserContext.Provider value={loggedUser}>
          <CalendarContext.Provider
            value={{ startDate, setStartDate, endDate, setEndDate }}
          >
            <PeriodPopoverDisclosure as={Picker} {...periodPopoverState}>
              <span>
                {startDate && endDate
                  ? `${dateToString(startDate)} - ${dateToString(endDate)}`
                  : 'Duração Total'}
              </span>
              <FontAwesomeIcon icon={faChevronDown} />
            </PeriodPopoverDisclosure>
            <PeriodPopover {...periodPopoverState} />
            <ProjectSelectContainer>
              <ProjectSelect
                options={projects}
                value={project}
                onChange={setProject}
              />
            </ProjectSelectContainer>
          </CalendarContext.Provider>
        </LoggedUserContext.Provider>
      </BorderedHeader>
      <PageTitle style={{ border: 'none', margin: '0 75px 1rem 40px' }}>
        {!project || !project.value
          ? 'Estatísticas do Time'
          : `Estatísticas do projeto ${project.label}`}
      </PageTitle>
      {project &&
      project.value &&
      (project.value['pendingProfitInfo?'] ||
        project.value.category === 'internal') ? (
        <BoxContainer style={{ flexDirection: 'column' }}>
          <ColumnTitle>Este projeto não possui dados suficientes</ColumnTitle>
          <Paragraph>
            Para que possamos mostrar os gráficos de lucratividade deste
            projeto, por favor preencha os seguintes dados na configuração deste
            projeto:
            <ul>
              <li>Data de início</li>
              <li>Data de Término Estimado</li>
              <li>Valor Cobrado</li>
              <li>Quantidade de Horas Estimadas</li>
            </ul>
          </Paragraph>
        </BoxContainer>
      ) : (
        <>
          <ProfitabilitySummary {...statistics} />
          <ProfitabilityCharts
            {...statistics}
            fillColor={project && project.value ? project.color : null}
          />
        </>
      )}
    </>
  );
}

ProfitabilityPage.propTypes = {
  ProjectService: PropTypes.shape({
    getAllProjects: PropTypes.func,
    getProjectStatistics: PropTypes.func,
  }),
  TeamService: PropTypes.shape({
    getTeamStatistics: PropTypes.func,
  }),
};
