import styled from '@emotion/styled';

import Snackbar from '~/components/Snackbar';
import { mq } from '~/helpers/responsive';
import loginImg from '~/site/ResetPage/images/login-noise.png';

export const InnerWraper = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: ['52px', '232px', '123px'],
    backgroundImage: [null, `url(${loginImg})`],
    backgroundRepeat: [null, 'no-repeat'],
    backgroundPosition: [null, 'top right'],
    backgroundSize: [null, null, 'contain'],
    margin: [null, null, '0 36px', '0 72px 0 36px'],
  })}
`;

export const FormWrapper = styled.main`
  ${mq({
    marginTop: ['25px', 0],
    maxWidth: ['auto', '318px', '463px'],
  })}
`;

export const SubmitWrapper = styled.div`
  ${mq({
    marginTop: '30px',
    display: 'flex',
    alignItems: ['flex-start', 'flex-start', 'center'],
    flexDirection: ['column', 'column', 'row'],
  })}
`;

export const StyledSnackbar = styled(Snackbar)`
  position: absolute;
  top: -60px;
  right: 0;
`;
