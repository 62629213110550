import React from 'react';

import api from '~/services/api';
import useReportsStore from '~/store/reports/reports';

import * as S from './styles';

import type { State } from '~/models/types';

interface WeeklyNotificationModalProps {
  closeModal: (status: boolean) => void;
  modalWasSeen: string;
  stringStartDate: string;
  stringEndDate: string;
  startDate: Date;
  endDate: Date;
  setRoute: Function;
  $state: State;
}

const WeeklyNotification: React.FC<WeeklyNotificationModalProps> = ({
  closeModal,
  modalWasSeen,
  stringStartDate,
  stringEndDate,
  startDate,
  endDate,
  setRoute,
  $state,
}) => {
  const { setDateRange, setResetReportsDate } = useReportsStore();
  const navigateFor = (url: string, params = {}) => {
    setRoute(url);
    $state.go(url, params);
  };

  const onClickModalClose = (wasSeen) => {
    localStorage.setItem(wasSeen, 'true');
    closeModal(false);
  };

  const redirectToInfo = async () => {
    closeModal(false);
    await api.report.update_reports_notifications(false);
    await setDateRange({ startDate, endDate });
    await setResetReportsDate(false);
    await navigateFor('app.main.reports');
  };

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <strong>
          Relatório Semanal
          <br />
          {stringStartDate} - {stringEndDate}
        </strong>
      </S.HeaderWrapper>
      <S.ModalClose>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          onClick={() => onClickModalClose(modalWasSeen)}
        >
          <path
            fill="#ffffff"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </S.ModalClose>
      <S.StyledButton onClick={() => redirectToInfo()}>
        Ver relatório
      </S.StyledButton>
    </S.Wrapper>
  );
};

export default WeeklyNotification;
