import { Tooltip } from '@mui/material';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { msToTimeString } from '~/app/utils/duration';
import api from '~/services/api';
import useReplayTaskStore from '~/store/replayTask';

import DeletePlannedTaskModal from './DeletePlannedTaskModal';
import { DragableRow } from './DragableRow';
import * as S from './styles';

type Props = {
  isAdmin: boolean;
  isEmpty: boolean;
  setPlannedTasks: Function;
  plannedTasks: object[];
};
const TaskList = ({
  isAdmin,
  isEmpty,
  setPlannedTasks,
  plannedTasks,
}: Props) => {
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [taskToDelete, setTaskToDelete] = React.useState(-1);
  const projectId = parseInt(window.location.pathname.split('/')[4], 10);
  const { setReplayTask } = useReplayTaskStore();

  const moveRow = async (dragIndex, hoverIndex) => {
    const dragRecord = plannedTasks[dragIndex];
    const newPlannedTasks = plannedTasks.filter(
      (_, index) => index !== dragIndex,
    );
    newPlannedTasks.splice(hoverIndex, 0, dragRecord);
    setPlannedTasks(newPlannedTasks);
  };
  const handleEditTask = async (task: { id: number }) => {
    const response = await api.team.change_planned_task(
      {
        ...task,
      },
      projectId,
      task.id,
    );
    const newTask = await response.json();
    const newPlannedTasks = plannedTasks.map((plannedTask: { id: number }) => {
      if (plannedTask.id === task.id) {
        return newTask;
      }
      return plannedTask;
    });
    setPlannedTasks(newPlannedTasks);
  };
  const handleAddTask = async (task: Object, index) => {
    const response = await api.team.add_planned_task({ ...task }, projectId);
    const newTask = await response.json();
    const newPlannedTasks = plannedTasks.map((elem, idx) =>
      idx === index ? newTask : elem,
    );
    setPlannedTasks(newPlannedTasks);
  };

  if (isEmpty) return null;

  return isAdmin ? (
    <>
      <DndProvider backend={HTML5Backend}>
        <S.Table>
          <S.Row className="header">
            <S.Cell className="description firstCell">
              Tarefas planejadas
            </S.Cell>
            <S.Cell className="duration">
              <Tooltip title="Duração esperada em horas" placement="bottom">
                <S.AssignmentIcon />
              </Tooltip>
            </S.Cell>
            <S.Cell>
              <Tooltip title="Tempo já consumido" placement="bottom">
                <S.AlarmIcon />
              </Tooltip>
            </S.Cell>
            <S.Cell className="actions" />
          </S.Row>

          {plannedTasks.map((task: any) => (
            <DragableRow
              key={task.id}
              task={task}
              index={plannedTasks.indexOf(task)}
              moveRow={moveRow}
              api={api}
              plannedTasks={plannedTasks}
              projectId={projectId}
              handleEditTask={handleEditTask}
              handleAddTask={handleAddTask}
              setReplayTask={setReplayTask}
              setTaskToDelete={setTaskToDelete}
              setOpenDeleteModal={setOpenDeleteModal}
            />
          ))}
          <S.Row>
            <S.Cell className="addTask">
              <S.AddTaskButton
                onClick={() => {
                  const newTask = {
                    description: '',
                    duration: 1,
                  };
                  setPlannedTasks([...plannedTasks, newTask]);
                }}
              >
                <S.AddIcon />
                Adicionar Tarefa Planejada
              </S.AddTaskButton>
            </S.Cell>
            <S.Cell className="duration">
              {plannedTasks.reduce(
                (acc: number, task: any) => acc + task.duration,
                0,
              )}
              h
            </S.Cell>
            <S.Cell className="timeSpent">
              {msToTimeString(
                plannedTasks.reduce(
                  (acc: number, task: any) => acc + task.timeSpent,
                  0,
                ),
              )}
            </S.Cell>
          </S.Row>
        </S.Table>
      </DndProvider>

      <DeletePlannedTaskModal
        modalIsOpen={openDeleteModal}
        setModalOpen={setOpenDeleteModal}
        PlannedTaskId={taskToDelete}
        RemoveTask={async () => {
          const tasks = plannedTasks.filter(
            (task: { id: number }) => task.id !== taskToDelete,
          );
          setPlannedTasks(tasks);
        }}
      />
    </>
  ) : (
    <>
      <S.Table>
        <S.Row className="header">
          <S.Cell className="description firstCell">Tarefas planejadas</S.Cell>
          <S.Cell className="duration">
            <Tooltip title="Duração esperada em horas" placement="bottom">
              <S.AssignmentIcon />
            </Tooltip>
          </S.Cell>
          <S.Cell>
            <Tooltip title="Tempo já consumido" placement="bottom">
              <S.AlarmIcon />
            </Tooltip>
          </S.Cell>
          <S.Cell className="actions" />
        </S.Row>
        {plannedTasks.map(
          (task: {
            description: string;
            duration: number;
            timeSpent: number;
            id: number;
          }) => (
            <S.Row className="bodyRow editable">
              <S.Cell className="description nonAdmin">
                {' '}
                <span>{task.description}</span>
              </S.Cell>
              <S.Cell className="duration">
                <span className="durationInput">{task.duration}h</span>
              </S.Cell>
              <S.Cell>{msToTimeString(task.timeSpent)}</S.Cell>
              <S.Cell>
                <div className="actions">
                  <Tooltip title="Iniciar tarefa planejada" placement="bottom">
                    <S.PlayArrowIcon
                      className="icon"
                      onClick={() => {
                        setReplayTask({
                          description: task.description,
                          projectId,
                          plannedTaskId: task.id,
                        });
                      }}
                    />
                  </Tooltip>
                </div>
              </S.Cell>
            </S.Row>
          ),
        )}
        <S.Row>
          <S.Cell />
          <S.Cell className="duration">
            {plannedTasks.reduce(
              (acc: number, task: { duration: number }) => acc + task.duration,
              0,
            )}
            h
          </S.Cell>
          <S.Cell className="timeSpent">
            {msToTimeString(
              plannedTasks.reduce(
                (acc: number, task: { timeSpent: number }) =>
                  acc + task.timeSpent,
                0,
              ),
            )}
          </S.Cell>
        </S.Row>
      </S.Table>
      <S.Message>
        Caso deseje alterar as tarefas planejadas, peça ao administrador do seu
        escritório no Labor.
      </S.Message>
    </>
  );
};

export default TaskList;
