import * as msal from '@azure/msal-browser';
import React from 'react';

import { loginRequest, msalConfig } from '~/config/authConfig';
import { MicrosoftButtonSvg } from '~/icons';
import SocialButton from '~/site/components/SocialButton';

import type NotificationServiceType from '~/models/ServicesTypes';

interface MicrosoftSignInButtonProps {
  doLoginWithMS: (string) => void;
  NotificationService: NotificationServiceType;
  textArea: string;
}

const MicrosoftSignIn = ({
  doLoginWithMS,
  NotificationService,
  textArea,
}: MicrosoftSignInButtonProps) => {
  const msalInstance = new msal.PublicClientApplication(msalConfig);

  async function handleLogin() {
    try {
      const loginResponse = await msalInstance.loginPopup(loginRequest);
      doLoginWithMS(loginResponse);
    } catch (err) {
      NotificationService.showNotification(
        'Ocorreu um erro. Tente novamente mais tarde.',
        'error',
      );
    }
  }

  return (
    <SocialButton onClick={() => handleLogin()}>
      <MicrosoftButtonSvg />
      {textArea}
    </SocialButton>
  );
};

export default MicrosoftSignIn;
