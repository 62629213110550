import styled from '@emotion/styled';

import theme from '~/theme';

import Button from '../../../components/Button';

const SocialButtonStyle = styled(Button)`
  width: 100%;
  background: #ffffff;
  color: ${theme.colors.gray18};
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${theme.colors.borderButton};
  :focus,
  :hover {
    border: 1px solid ${theme.colors.gray18};
    background: #ffffff;
  }
  svg:first-of-type {
    margin-right: 1em;
  }
`;

export default SocialButtonStyle;
