import React, { useEffect, useState } from 'react';

import Button, { LightButton } from '~/components/Button';
import Modal from '~/components/Modal';
import trello from '~/services/api/trello';

import * as TS from '../styles';

import * as S from './styles';

interface ModalProps {
  isOpen?: boolean;
  onRequestClose: () => void;
  selectedBoard?: any;
  projectId: string;
}
const selectStyles = {
  option: (base) => ({
    ...base,
    backgroundColor: 'white',
    color: '#5F5F5F',
    ':hover': {
      backgroundColor: '#F6F7FB',
    },
  }),
};
const selectComponents = {
  IndicatorSeparator: () => null,
};
const formatOptionLabel = (data, context) =>
  context.context === 'value' || data.id !== context.selectValue['0']?.id ? (
    <div style={{ display: 'flex' }}>
      <div>{data?.name}</div>
    </div>
  ) : (
    <div style={{ display: 'flex' }}>
      <div>
        {data.name}
        <svg
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: '10px' }}
        >
          <path
            d="M4.0001 7.7799L1.2201 4.9999L0.273438 5.9399L4.0001 9.66656L12.0001 1.66656L11.0601 0.726562L4.0001 7.7799Z"
            fill="#959595"
          />
        </svg>
      </div>
    </div>
  );
const ConnectionModal = ({
  isOpen,
  onRequestClose,
  selectedBoard,
  projectId,
}: ModalProps) => {
  const [columns, setColumns] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState<
    { idBoard: string; id: string }[]
  >([
    { id: '', idBoard: '' },
    { id: '', idBoard: '' },
  ]);
  const [valid, setValid] = useState([true, true]);
  const getColumns = async () => {
    const response = await trello.getLists(selectedBoard.id);
    const data = await response.json();
    setColumns(data);
  };
  const handleConnect = async () => {
    const body = {
      board_id: selectedColumn[0]?.idBoard,
      done_list_id: selectedColumn[0]?.id,
      todo_list_id: '5e1d08295c7e4d6bc67b4a3b',
    };
    await trello.createTrelloBoard(parseInt(projectId, 10), body);
    setSelectedColumn([
      { id: '', idBoard: '' },
      { id: '', idBoard: '' },
    ]);
    window.location.reload();
  };
  useEffect(() => {
    getColumns();
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      width="100%"
      maxWidth="95%"
      height="100%"
      maxHeight="90%"
      onRequestClose={() => {
        onRequestClose();
      }}
      backgroundColor="transparent"
    >
      <S.Container>
        <S.Content>
          <S.Title>Conectar ao Trello</S.Title>
          <S.SectionTitle className="board">Quadro</S.SectionTitle>
          <S.Disclaimer>
            Este projeto será conectado ao seguinte quadro:
          </S.Disclaimer>
          <TS.TrelloCell
            style={{ ...selectedBoard?.prefs }}
            className="modalCell"
          >
            <TS.TrelloDetails>
              <TS.TrelloTitle>{selectedBoard?.name}</TS.TrelloTitle>
            </TS.TrelloDetails>
          </TS.TrelloCell>
          <S.SectionTitle className="column">Colunas</S.SectionTitle>
          <S.Description>
            Selecione uma coluna para ser sua coluna de tarefas planejadas a
            serem iniciadas. E outra coluna para ser de tarefas finalizadas.
          </S.Description>
          <S.Row>
            <div>
              <S.ColumnLabel
                style={{ color: valid[0] ? '#5F5F5F' : '#FF0000' }}
              >
                Coluna de tarefas a fazer*
              </S.ColumnLabel>
              <S.Select
                classNamePrefix="columnSelect"
                className="columnSelect"
                styles={selectStyles}
                components={selectComponents}
                defaultValue={columns[0]}
                isSearchable={false}
                formatOptionLabel={formatOptionLabel}
                options={columns}
                placeholder="Selecione*"
                onChange={(e: { idBoard: string; id: string }) => {
                  setSelectedColumn([e, selectedColumn[1]]);
                }}
              />
            </div>
            <div>
              <S.ColumnLabel
                style={{ color: valid[1] ? '#5F5F5F' : '#FF0000' }}
              >
                Coluna de finalizadas*
              </S.ColumnLabel>
              <S.Select
                classNamePrefix="columnSelect"
                className="columnSelect"
                styles={selectStyles}
                components={selectComponents}
                defaultValue={columns[0]}
                isSearchable={false}
                formatOptionLabel={formatOptionLabel}
                options={columns}
                placeholder="Selecione*"
                onChange={(e: { idBoard: string; id: string }) => {
                  setSelectedColumn([selectedColumn[0], e]);
                }}
              />
            </div>
          </S.Row>
          <S.ButtonRow>
            <LightButton
              style={{ padding: '10px 25px' }}
              onClick={() => {
                onRequestClose();
              }}
            >
              Voltar
            </LightButton>
            <Button
              style={{ padding: '10px 25px' }}
              onClick={() => {
                if (selectedColumn[0]?.id && selectedColumn[1]?.id) {
                  handleConnect();
                  onRequestClose();
                } else {
                  const isValid = [
                    !!selectedColumn[0]?.id,
                    !!selectedColumn[1]?.id,
                  ];
                  setValid(isValid);
                }
              }}
            >
              Conectar ao Trello
            </Button>
          </S.ButtonRow>
        </S.Content>
      </S.Container>
    </Modal>
  );
};
export default ConnectionModal;
