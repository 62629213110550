import PropTypes from 'prop-types';
import React from 'react';

import Button, { LightButton } from '../../../components/Button';
import Modal, { ModalTitle } from '../../../components/Modal';

export default function DeleteProjectAllocation(props) {
  const { ProjectAllocation, ...otherProps } = props;

  return (
    <Modal
      {...otherProps}
      modalTrasformPosition="translate(-50%, -115%)"
      width="35%"
      height="80%"
    >
      <ModalTitle>Retirar deste projeto</ModalTitle>

      <LightButton
        style={{ margin: '0 30px 30px 0' }}
        onClick={() => props.onRequestClose()}
      >
        Cancelar
      </LightButton>
      <Button onClick={() => props.deleteProjectAllocation(ProjectAllocation)}>
        Retirar deste projeto
      </Button>
    </Modal>
  );
}

DeleteProjectAllocation.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  ProjectAllocation: PropTypes.func,
  deleteProjectAllocation: PropTypes.func,
};
