import styled from '@emotion/styled';

import Button from '~/components/Button';
import theme from '~/theme';

export const Container = styled.div`
  padding: 0 0 40px 15px;
  display: flex;
  flex-direction: row;
`;

export const ButtonComponent = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 16px 6px 12px;
  width: 160px;
  height: 33px;
  background: ${theme.colors.secondary};
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.gray25};
  left: 100px;
`;

export const ModalText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.containerBg};
`;

export const NextWrapper = styled.a`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.containerBg};
  overflow-wrap: break-word;
  margin-left: 12px;
`;

export const StyledButton = styled(Button)`
  background: ${theme.colors.secondary};
  color: ${theme.colors.gray25};
  width: 92px;
  heght: 33px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 5px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  align-items: center;
`;

export const Modal = {
  tooltip: {
    filter: 'none',
  },
  container: {
    backgroundColor: theme.colors.blue1,
    borderRadius: 6,
    color: theme.colors.containerBg,
    filter: 'none',
    minHeight: 'none',
    width: 295,
    padding: 20,
  },
  arrow: {
    color: theme.colors.blue1,
    length: 8,
    spread: 10,
  },
};
