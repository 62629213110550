import React, { useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import DateRangePicker from '~/app/components/DateRangePicker';
import useReportsStore from '~/store/reports/reports';

const DateRange = () => {
  const { dateRange, setDateRange, hasChangeFlags } = useReportsStore(
    (state) => ({
      dateRange: state.dateRange,
      setDateRange: state.setDateRange,
      hasChangeFlags: state.hasChangeFlags,
    }),
    shallow,
  );

  const [startDate, setStartDate] = useState<null | Date>(dateRange.startDate);
  const [endDate, setEndDate] = useState<null | Date>(dateRange.endDate);
  useEffect(() => {
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
  }, [dateRange]);

  const onClose = () => {
    setDateRange({ startDate, endDate });
  };

  return (
    <DateRangePicker
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      onClose={onClose}
      isPending={hasChangeFlags.date}
    />
  );
};

export default DateRange;
