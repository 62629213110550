import { Formik, Form } from 'formik';
import { omit } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';

import FormikScrollToError from '~/app/components/FormikScrollToError';
import { unmaskPrice, initialCurrencyFormatter } from '~/app/utils/currency';
import Button from '~/components/Button';
import API from '~/services/api';
import useProjectStore from '~/store/projects';
import useTeamStore from '~/store/team';

import InputField, { TypesOfInput } from '../../components/InputField';
import ProjectType from '../../components/NewProjectModal/ProjectType';
import * as S from '../../components/NewProjectModal/styles';

import UserTable from './Table/UserTable';
import UserSelect from './UserSelect';
import { Container, Container2, Hr } from './styles';

import type { IStateProvider } from 'angular-ui-router';
import type NotificationServiceType from '~/models/ServicesTypes';
import type { Project, ProjectAllocationWithCheck } from '~/models/types';

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    tagColor: Yup.string().nullable(),
    client: Yup.string().nullable(),
    estimatedStartAt: Yup.string().nullable(),
    estimatedEndAt: Yup.string()
      .nullable()
      .when('estimatedStartAt', {
        is: (estimatedStartAt: string) => !!estimatedStartAt,
        then: Yup.string()
          .nullable()
          .test(
            'after-estimated-start',
            'Data anterior à data início',
            (value, context) => {
              if (!value) return true;
              const startAt = moment(
                context.parent.estimatedStartAt,
                'DD/MM/YYYY',
              );
              const endAt = moment(value, 'DD/MM/YYYY');
              return endAt.isAfter(startAt);
            },
          ),
      }),
    category: Yup.string()
      .required('Campo obrigatório')
      .matches(/(external|internal)/, 'Tipo de projeto inválido'),
    status: Yup.string()
      .required('Campo obrigatório')
      .matches(/(not_started|running|finished)/, 'Status inválido'),
    estimatedHours: Yup.number().when('category', {
      is: 'internal',
      then: Yup.number().nullable(),
      otherwise: Yup.number()
        .min(1, 'Escolha um número maior que 0')
        .typeError('Número de horas estimadas inválido'),
    }),
    price: Yup.string().when('category', {
      is: 'internal',
      then: Yup.string().nullable(),
      otherwise: Yup.string().matches(
        /^R\$[^a-zA-Z]*$|^\d*$|^\d*.\d*$/,
        'Preço inválido',
      ),
    }),
  });

interface ProjectConfigProps {
  NotificationService: NotificationServiceType;
  $state: IStateProvider;
  $window: Window;
}

const ProjectConfig: React.FC<ProjectConfigProps> = ({
  NotificationService,
  $state,
  $window,
}) => {
  const [projectData, setProjectData] = useState<Project>();

  const { activeProjectId } = useProjectStore();
  const [isLoading, setIsLoading] = useState(true);
  const { teamUsers, fetchTeamUsersData } = useTeamStore();
  const [projectAllocations, setProjectAllocations] = useState<
    ProjectAllocationWithCheck[]
  >([]);

  const getFormInitialValues = () => {
    if (projectData) {
      return {
        name: projectData.name,
        tagColor: projectData.tagColor,
        client: projectData.client,
        estimatedStartAt: projectData.estimatedStartAt
          ? moment(projectData.estimatedStartAt).format('DD/MM/YYYY')
          : '',
        estimatedEndAt: projectData.estimatedEndAt
          ? moment(projectData.estimatedEndAt).format('DD/MM/YYYY')
          : '',
        category: projectData.category,
        status: projectData.status ? projectData.status : 'finished',
        estimatedHours: projectData.estimatedHours,
        price: initialCurrencyFormatter(projectData.price),
      };
    }
    return {};
  };

  // TODO: Maybe use ProjectsStore
  const getProjectData = async () => {
    const response = await API.team.get_projects();
    const data: Project[] = await response.json();
    const project = data.filter((proj) => proj.id === activeProjectId)[0];
    setProjectData(project);
    setIsLoading(false);
  };

  const getProjectAllocations = async () => {
    const response = await API.project.getAllocations(activeProjectId);
    const data = await response.json();
    const newProjectAllocations = data.map((obj) => {
      const allocation = projectAllocations.find(
        (PA) => PA.user.id === obj.user.id,
      );
      return {
        ...obj,
        checked: allocation !== undefined ? allocation.checked : false,
      };
    });
    setProjectAllocations(newProjectAllocations);
  };

  const getAllocationsData = async () => {
    await fetchTeamUsersData();
    getProjectAllocations();
  };

  const handleUserAllocation = async (UserIds: Number[]) => {
    const newProject = {
      ...omit(projectData, [
        'consumedHours',
        'pendingProfitInfo',
        'hasTrelloBoard',
        'format',
      ]),
      user_id_list: UserIds,
    };
    await API.team.change_project(newProject, activeProjectId);
    getAllocationsData();
  };

  const updateProjectData = (value) => ({
    name: value.name,
    tagColor: value.tagColor,
    client: value.client,
    category: value.category,
    estimatedStartAt: value.estimatedStartAt
      ? moment(value.estimatedStartAt, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : null,
    estimatedEndAt: value.estimatedEndAt
      ? moment(value.estimatedEndAt, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : null,
    status: value.status,
    estimatedHours: value.estimatedHours
      ? Number(String(value.estimatedHours).replace('h', ''))
      : null,
    price: value.price ? Number(unmaskPrice(value.price)) / 100 : null,
  });

  const updateProject = async (project) => {
    API.team
      .change_project(updateProjectData(project), activeProjectId)
      .then((response) => {
        if (response.status === 200) {
          getProjectData();
          NotificationService.showNotification(
            'Projeto alterado com sucesso!',
            'success',
          );
        } else
          NotificationService.showNotification(
            'Ocorreu um erro ao alterar o projeto',
            'error',
          );
        return response.json();
      });
  };

  useEffect(() => {
    getProjectData();
    getAllocationsData();
  }, []);

  if (isLoading) return null;

  return (
    <Formik
      enableReinitialize
      initialValues={getFormInitialValues()}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await updateProject(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <FormikScrollToError />
          <Container>
            <S.DataContainer>
              <InputField
                name="name"
                colorName={values.tagColor || ''}
                onChange={setFieldValue}
                title="Nome do Projeto*"
                type={TypesOfInput.textColorPicker}
              />
              <InputField
                name="client"
                onChange={setFieldValue}
                title="Cliente"
                type={TypesOfInput.textClient}
              />
            </S.DataContainer>
            <S.DataContainer>
              <S.DataContainer>
                <InputField
                  name="estimatedStartAt"
                  onChange={(value: string) =>
                    setFieldValue('estimatedStartAt', value)
                  }
                  dateData={values.estimatedStartAt}
                  title="Data de início estimada"
                  type={TypesOfInput.dateField}
                />
                <InputField
                  name="estimatedEndAt"
                  onChange={(value: string) =>
                    setFieldValue('estimatedEndAt', value)
                  }
                  dateData={values.estimatedEndAt}
                  title="Data de término estimada"
                  type={TypesOfInput.dateField}
                />
              </S.DataContainer>
              <ProjectType
                name="category"
                onChange={setFieldValue}
                typeData={values.category || ''}
              />
            </S.DataContainer>
            <S.DataContainer>
              <InputField
                name="status"
                onChange={setFieldValue}
                title="Status"
                type={TypesOfInput.statusList}
              />
              {values.category === 'external' && (
                <InputField
                  name="estimatedHours"
                  onChange={setFieldValue}
                  initialHours={values.estimatedHours || 0}
                  title="Horas estimadas*"
                  type={TypesOfInput.estimatedHours}
                />
              )}
            </S.DataContainer>
            {values.category === 'external' && (
              <>
                <S.SubTitleText>Dados para lucratividade</S.SubTitleText>
                <S.DataContainer>
                  <InputField
                    name="price"
                    onChange={setFieldValue}
                    title="Valor cobrado*"
                    type={TypesOfInput.totalCharged}
                  />
                  <InputField
                    name="hourlyCharged"
                    title="Valor da hora"
                    type={TypesOfInput.hourlyCharged}
                    totalPrice={values.price}
                    estimatedHours={values.estimatedHours}
                  />
                </S.DataContainer>
              </>
            )}

            <S.SubTitleText>Equipe do Projeto</S.SubTitleText>
            <S.DataContainer>
              <UserSelect
                allocations={teamUsers}
                projectAllocations={projectAllocations}
                handleSave={handleUserAllocation}
              />
            </S.DataContainer>
          </Container>

          <Container2>
            <UserTable
              projectAllocations={projectAllocations}
              getProjectAllocations={getProjectAllocations}
              setProjectAllocations={setProjectAllocations}
              $state={$state}
              $window={$window}
            />
            <Hr />
          </Container2>
          <Container>
            <Button disabled={isSubmitting} type="submit">
              Salvar Alterações
            </Button>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectConfig;
