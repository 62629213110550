import React from 'react';
import shallow from 'zustand/shallow';

import formatCurrency from '~/app/utils/currency';
import { msToTime } from '~/app/utils/duration';
import useReportsStore from '~/store/reports/reports';

import * as S from './styles';

import type { ProjectDuration } from '~/store/reports/types';

const ProjectRow = ({
  active,
  project,
  onMouseLeave,
  onMouseOver,
  someoneActive,
  onClick,
}: {
  project: ProjectDuration;
  active: boolean;
  onMouseOver: () => void;
  onMouseLeave: () => void;
  onClick: () => void;
  someoneActive: boolean;
}) => (
  <S.ProjectRow
    active={active}
    someoneActive={someoneActive}
    onClick={onClick}
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
  >
    <td>
      <S.ProjectNameContainer>
        <S.ProjectIcon color={project.color} />
        {project.name}
      </S.ProjectNameContainer>
    </td>
    <td>
      <S.ListTimeContainer>{msToTime(project.duration)}</S.ListTimeContainer>
    </td>
    <td>
      <S.ListTimeContainer>
        {formatCurrency(project.cost)}
      </S.ListTimeContainer>
    </td>
  </S.ProjectRow>
);

const ProjectList = ({
  active,
  setActive,
}: {
  active: number | null;
  setActive: React.Dispatch<React.SetStateAction<number | null>>;
}) => {
  const { projectsDuration, setSelectedProjectsIds, fetchTasksData } =
    useReportsStore(
      (state) => ({
        projectsDuration: state.projectsDuration,
        setSelectedProjectsIds: state.setSelectedProjectsIds,
        fetchTasksData: state.fetchTasksData,
      }),
      shallow,
    );

  const handleClick = (projectId: number) => {
    setSelectedProjectsIds([projectId]);
    fetchTasksData();
  };

  return (
    <S.Table>
      <tbody>
        {projectsDuration.projectsDuration.map((project, index) => (
          <ProjectRow
            onMouseOver={() => setActive(index)}
            onMouseLeave={() => setActive(null)}
            onClick={() => handleClick(project.id)}
            key={project.id}
            project={project}
            active={active === index}
            someoneActive={active !== null}
          />
        ))}
      </tbody>
    </S.Table>
  );
};

export default ProjectList;
