import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { dateToString, stringToDate } from '../../utils/date';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  flex: 1;
  color: #d7d7d7;
  font-weight: 600;
  font-size: 0.9em;
`;

const Input = styled.input`
  flex: 1;
  border: 0;
  border-bottom: 2px solid #f4f4f4;
  padding: 10px 0;
  color: #959595;
  font-size: 0.9em;
  outline: none;
  &:focus {
    outline: none;
    border-color: #b2b2b2;
  }
`;

export default function DateInput({ label, date, onChange }) {
  const [dateString, setDateString] = useState(dateToString(date));

  useEffect(() => {
    setDateString(dateToString(date));
  }, [date]);

  function onChangeString(e) {
    const { value } = e.currentTarget;
    setDateString(value);

    if (/\d{2}\/\d{2}\/\d{4}/.test(value)) {
      onChange(stringToDate(value));
    }
  }

  return (
    <Container>
      <Label>{label}</Label>
      <Input type="text" value={dateString} onChange={onChangeString} />
    </Container>
  );
}

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
};
