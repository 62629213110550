import styled from '@emotion/styled';
import React from 'react';

import {
  mq,
  ShowBeforeLarge,
  HideBeforeLarge,
} from '../../../helpers/responsive';
import Container from '../Container';

import laborLogo from './images/labor-logo.svg';

const StyledHeader = styled.header`
  ${mq({
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    marginTop: ['0', '0', '64px'],
    paddingTop: ['20px', '20px', '0'],
    background: '#f6f7fb',
    display: 'flex',
    margin: 0,
    borderBottom: '1px solid transparent',
  })}
`;

export default function Header() {
  return (
    <StyledHeader>
        <ShowBeforeLarge display="flex" style={{ width: '100%' }}>
          <Container
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <a href={SITE_URL}>
              <img src={laborLogo} style={{ width: '106px' }} />
            </a>
          </Container>
        </ShowBeforeLarge>
        <HideBeforeLarge display="flex" style={{ width: '100%' }}>
          <Container style={{ display: 'flex', flex: 1 }}>
            <a href={SITE_URL}>
              <img style={{ margin: '0 72px 0 36px' }} src={laborLogo} />
            </a>
          </Container>
        </HideBeforeLarge>
      </StyledHeader>
  );
}
