import styled from '@emotion/styled';

import theme from '~/theme';

export const LoginDivider = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 0px 32px 0px;
`;

export const LoginDividerLine = styled.div`
  width: 100%;
  border-bottom: 2px solid ${theme.colors.gray20};
`;

export const Divider = styled.div`
  width: 100%;
  height: 16px;
`;

export const LoginDividerText = styled.div`
  margin: 0px 10px 0px 10px;
`;
