import styled from '@emotion/styled';
import { Field as FormikField } from 'formik';
import React from 'react';

import type { CSSProperties } from 'react';

interface InputStyledProps {
  hasError: boolean;
  disabled: boolean;
}
const InputStyled = styled.input<InputStyledProps>`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  padding: 14px 20px;
  border: solid 1px rgba(149, 149, 149, 0.2);
  background-color: #ffffff;
  outline: none;
  :focus {
    border-color: rgba(70, 128, 254, 0.5);
  }
  ${(p) =>
    p.hasError &&
    `
    color: #ff6340;
    border-color: currentColor;
  `}
  ${(p) =>
    p.disabled &&
    `
    background-color: #F6F7FB;
    color: #7D7D7D;
  `}
`;
const Wrapper = styled.label`
  display: flex;
  margin: 0 0 20px 0;
  flex-direction: column;
`;

const Label = styled.label`
  flex: 1;
  color: #7d7d7d;
  font-weight: 600;
  font-size: 0.9em;
`;

const ErrorMessage = styled.div`
  color: #ff6340;
  font-size: 12px;
  font-weight: 500;
`;

interface InputProps {
  name: string;
  value: string;
  type: string;
  touched?: string;
  errors?: string;
  placeholder: string;
  onFocus?: Function | null;
  onBlur?: Function | null;
  label?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
  wrapperStyle?: CSSProperties;
  inputStyle?: CSSProperties;
}
interface InputHandlerProps {
  type: string;
  error: string;
  disabled: boolean;
  label: string;
  placeholder: string;
  wrapperStyle?: CSSProperties;
  inputStyle?: CSSProperties;
}
const InputHandler = ({
  error,
  wrapperStyle,
  inputStyle,
  label,
  disabled,
  ...otherProps
}: InputHandlerProps) => (
  <Wrapper style={wrapperStyle}>
    <Label>{label}</Label>
    <InputStyled
      style={inputStyle}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      hasError={!!error}
      disabled={!!disabled}
    />
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </Wrapper>
);

const normalize = (value) =>
  typeof value === 'string' || typeof value === 'number' ? value : '';

const Input: React.FC<InputProps> = ({
  name,
  value,
  errors,
  touched,
  ...otherProps
}) => (
  <FormikField name={name}>
    {({ field, form }) => (
      <InputHandler
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        value={normalize(value || field.value)}
        error={(form.touched[name] && form.errors[name]) || (touched && errors)}
      />
    )}
  </FormikField>
);

export default Input;
