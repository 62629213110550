import React, { useState } from 'react';

import CalendarPicker from '~/app/components/CalendarPicker';

import * as S from './styles';

export const getDates = (
  reuseFirstSecondMonth?: boolean,
  startDate?: Date | null,
) => {
  let baseDate: Date;
  if (!reuseFirstSecondMonth || !startDate) {
    baseDate = new Date();
  } else baseDate = startDate;

  const firstDayThisMonth = new Date(baseDate);
  firstDayThisMonth.setDate(1);
  firstDayThisMonth.setHours(0, 0, 0, 0);

  const lastDayThisMonth = new Date(baseDate);
  lastDayThisMonth.setMonth(lastDayThisMonth.getMonth() + 1);
  lastDayThisMonth.setDate(0);
  lastDayThisMonth.setHours(0, 0, 0, 0);

  const firstDayNextMonth = new Date(baseDate);
  firstDayNextMonth.setMonth(firstDayThisMonth.getMonth() + 1);
  firstDayNextMonth.setDate(1);
  firstDayNextMonth.setHours(0, 0, 0, 0);

  return { firstDayThisMonth, lastDayThisMonth, firstDayNextMonth };
};

type Props = {
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  hide?: () => void;
  reuseFirstSecondMonth?: boolean;
};

const DateRangePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  hide,
  reuseFirstSecondMonth = false,
}: Props) => {
  const { firstDayThisMonth, firstDayNextMonth } = getDates(
    reuseFirstSecondMonth,
    startDate,
  );

  const [firstMonth, setFirstMonth] = useState(firstDayThisMonth);
  const [secondMonth, setSecondMonth] = useState(firstDayNextMonth);

  const onFirstMonthChange = (newMonth: Date) => {
    setFirstMonth(newMonth);

    const nextMonth = new Date(newMonth);
    nextMonth.setMonth(newMonth.getMonth() + 1);
    setSecondMonth(nextMonth);
  };

  const onSecondMonthChange = (newMonth: Date) => {
    setSecondMonth(newMonth);

    const previousMonth = new Date(newMonth);
    previousMonth.setMonth(newMonth.getMonth() - 1);
    setFirstMonth(previousMonth);
  };

  const onStartDateChange = (innerStartDate: Date | null) => {
    setStartDate(innerStartDate);

    if (innerStartDate === null || endDate === null) return;

    if (innerStartDate.getMonth() !== endDate.getMonth()) {
      const startMonth = new Date(innerStartDate);
      startMonth.setDate(1);
      startMonth.setHours(0, 0, 0, 0);
      onFirstMonthChange(startMonth);
    }
  };

  const onEndDateChange = (innerEndDate: Date | null) => {
    setEndDate(innerEndDate);

    if (startDate === null || innerEndDate === null) return;

    if (startDate.getMonth() !== innerEndDate.getMonth()) {
      const endMonth = new Date(innerEndDate);
      endMonth.setDate(1);
      endMonth.setHours(0, 0, 0, 0);
      onSecondMonthChange(endMonth);
    }
  };

  const selectPeriodOption = (option: string) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let starting: null | Date = null;
    let ending: null | Date = null;

    switch (option) {
      case 'whole':
        starting = null;
        ending = null;

        break;
      case 'last-7-days':
        starting = new Date(today);
        starting.setDate(today.getDate() - 6);
        ending = new Date(today);

        break;
      case 'month':
        starting = new Date(today.getFullYear(), today.getMonth(), 1);
        ending = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        break;
      case 'last-month':
        starting = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        ending = new Date(today.getFullYear(), today.getMonth(), 0);

        break;
      case 'last-3-months':
        starting = new Date(
          today.getFullYear(),
          today.getMonth() - 3,
          today.getDate(),
        );
        ending = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
        );

        break;
      case 'last-6-months':
        starting = new Date(
          today.getFullYear(),
          today.getMonth() - 6,
          today.getDate(),
        );
        ending = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
        );

        break;
      case 'year':
        starting = new Date(today.getFullYear(), 0, 1);
        ending = new Date(today.getFullYear() + 1, 0, 0);

        break;
      case 'last-year':
        starting = new Date(today.getFullYear() - 1, 0, 1);
        ending = new Date(today.getFullYear(), 0, 0);

        break;
      default:
        break;
    }

    onStartDateChange(starting);
    onEndDateChange(ending);
    if (hide) hide();
  };

  return (
    <div>
      <S.PeriodContainer>
        <S.CalendarContainer style={{ marginLeft: 0 }}>
          <CalendarPicker
            month={firstMonth}
            onChangeMonth={onFirstMonthChange}
            startDate={startDate}
            onChangeStartDate={onStartDateChange}
            endDate={endDate}
            onChangeEndDate={onEndDateChange}
          />
        </S.CalendarContainer>
        <S.CalendarContainer style={{ marginRight: 0 }}>
          <CalendarPicker
            mode="endDate"
            month={secondMonth}
            onChangeMonth={onSecondMonthChange}
            startDate={startDate}
            onChangeStartDate={onStartDateChange}
            endDate={endDate}
            onChangeEndDate={onEndDateChange}
          />
        </S.CalendarContainer>
      </S.PeriodContainer>
      <S.OptionsWrapper>
        <S.OptionsTable>
          <tbody>
            <tr>
              <S.Option onClick={() => selectPeriodOption('whole')}>
                Duração Total do Projeto
              </S.Option>
              <S.Option onClick={() => selectPeriodOption('month')}>
                Este mês
              </S.Option>
              <S.Option onClick={() => selectPeriodOption('last-3-months')}>
                Últimos 3 meses
              </S.Option>
              <S.Option onClick={() => selectPeriodOption('year')}>
                Este ano
              </S.Option>
            </tr>
            <tr>
              <S.Option onClick={() => selectPeriodOption('last-7-days')}>
                Últimos 7 dias
              </S.Option>
              <S.Option onClick={() => selectPeriodOption('last-month')}>
                Último mês
              </S.Option>
              <S.Option onClick={() => selectPeriodOption('last-6-months')}>
                Últimos 6 meses
              </S.Option>
              <S.Option onClick={() => selectPeriodOption('last-year')}>
                Último ano
              </S.Option>
            </tr>
          </tbody>
        </S.OptionsTable>
      </S.OptionsWrapper>
    </div>
  );
};

export default DateRangePicker;
