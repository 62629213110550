import React from 'react';

import * as S from './styles';

import type { TooltipRenderProps } from 'react-joyride';

const Step: React.FC<TooltipRenderProps> = ({
  index,
  step,
  size,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}: TooltipRenderProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <S.Container {...tooltipProps}>
    <S.IndexText>
      Etapa {index + 1} de {size}
    </S.IndexText>
    {step.title && <S.Title>{step.title}</S.Title>}
    <S.Content>{step.content}</S.Content>
    <S.Footer>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {index === 0 && <S.Exit {...primaryProps}>Próximo</S.Exit>}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {index === 0 && <S.Exit {...closeProps}> | Pular tour</S.Exit>}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {index === 1 && <S.Exit {...backProps}>Voltar</S.Exit>}
    </S.Footer>
  </S.Container>
);

export default Step;
