import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Encrypt } from '~/helpers/encryption';
import saveLoginUserDataLocalStorage from '~/helpers/login';
import API from '~/services/api';
import AmplitudeUserStore from '~/services/api/amplitude';
import GoogleSignInButton from '~/site/components/GoogleSignInButton/index';
import LoadingState from '~/site/components/LoadingState/index';
import MicrosoftSignInButton from '~/site/components/MicrosoftSignInButton/index';

import * as S from './style';

import type NotificationServiceType from '~/models/ServicesTypes';

let amplitude;

interface SocialMediaSignInProps {
  AMPLITUDE_API_KEY: string;
  NotificationService: NotificationServiceType;
  googleText: string;
  microsoftText: string;
  codInvite?: string;
}
const SocialMediaSignIn = ({
  AMPLITUDE_API_KEY,
  NotificationService,
  googleText,
  microsoftText,
  codInvite,
}: SocialMediaSignInProps) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    amplitude = new AmplitudeUserStore(null, AMPLITUDE_API_KEY);
  }, []);

  const loginTypes = {
    microsoft: 'microsoft',
    google: 'google',
  };

  const loginFunctions = {
    [loginTypes.microsoft]: (data) =>
      API.auth.loginWithSocialMedia(
        Encrypt(data.accessToken),
        'microsoft',
        codInvite,
      ),
    [loginTypes.google]: (data) =>
      API.auth.loginWithSocialMedia(
        Encrypt(data.accessToken),
        'google',
        codInvite,
      ),
  };

  const validateUserFunctions = {
    [loginTypes.microsoft]: (data) =>
      API.auth.validateUserSocialMedia(Encrypt(data.accessToken), 'microsoft'),
    [loginTypes.google]: (data) =>
      API.auth.validateUserSocialMedia(Encrypt(data.accessToken), 'google'),
  };

  async function validateUser(data, type) {
    const request = get(validateUserFunctions, type, () => {});
    const response = await request(data);

    if (response && !response.ok) {
      localStorage.setItem('socialMediaToken', Encrypt(data.accessToken));
      localStorage.setItem('socialMediaType', type);
      window.location.href = '/cadastrar';
      return false;
    }
    return true;
  }

  async function handleLogin(data, type) {
    setLoading(true);
    if (!codInvite) {
      const validUser = await validateUser(data, type);
      if (!validUser) return;
    } else {
      localStorage.setItem('needToCompleteOnboarding', 'true');
    }
    const login = get(loginFunctions, type, () => {});
    const response = await login(data);
    if (response) {
      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.errors ? json.errors[0] : json.error);
      }
      const { headers } = response;
      saveLoginUserDataLocalStorage(
        json.data,
        json.rules,
        headers,
        () => amplitude.setUserId,
      );
    }
  }

  return (
    <>
      <GoogleSignInButton
        doLoginWithGoogle={(headers) => handleLogin(headers, loginTypes.google)}
        NotificationService={NotificationService}
        textArea={googleText}
      />
      <S.Divider />
      <MicrosoftSignInButton
        doLoginWithMS={(headers) => handleLogin(headers, loginTypes.microsoft)}
        NotificationService={NotificationService}
        textArea={microsoftText}
      />
      <S.LoginDivider>
        <S.LoginDividerLine />
        <S.LoginDividerText> ou </S.LoginDividerText>
        <S.LoginDividerLine />
      </S.LoginDivider>
      <LoadingState RedirectUrl={window.location.search} isOpen={loading} />
    </>
  );
};

export default SocialMediaSignIn;
