import styled from '@emotion/styled';

import Button from '~/components/Button';

export const ModalSubtitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;

  display: flex;
  align-items: center;

  color: #5f5f5f;
`;
export const LinkContainer = styled.div``;
export const TeamContainer = styled.div``;

export const AddMoreButton = styled.button`
  border: none;
  background: transparent;
  text-decoration: underline;
  padding-left: 0px;
  color: #4f587a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const LinkText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: #7d7d7d;
`;

export const InputsTeamContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  > label {
    width: 40%;
  }
  > :first-child {
    width: 60%;
    margin-right: 12px;
    margin-bottom: 1%;
  }
`;

export const InputsLinkContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  > label {
    width: 80%;
    margin-top: 15px;
    margin-right: 12px;
    background-color: #f6f7fb;
    color: #7d7d7d;
  }

  Button {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LinkButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 25%;
`;

export const TeamDivider = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 0px 32px 0px;
`;

export const TeamDividerLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #7d7d7d;
`;

export const SubmitButton = styled(Button)`
  display: flex;
  margin-top: 4%;
`;

export const TeamDividerText = styled.div`
  margin: 0px 10px 0px 10px;
`;

export const LinkCopiedText = styled.p`
  position: absolute;
  margin-top: 40px;
  text-align: start;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  animation: fadeIn 2s;
  color: #29c5a9;
`;
