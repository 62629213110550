import React, { useState } from 'react';

import OptionsSelectDropdown from './OptionsSelectDropdown';
import SelectPopover from './SelectPopover';

import type { OptionsType } from './OptionsSelectDropdown';

/*
  NestedMenu component.
  (Examples in DateRangePicker and Select/SearchableSelect).

  displayName: text to be displayed on the default button of the component.
  CustomAnchorElement: can pass your own component to be the button.
  anchorOrigin: position of dropdown related to button.
  onOpen: executed when dropdown opens.
  onClose: executed when dropdown closes.
  isPending: changes the default button border to blue.

  options: if this prop is present, an OptionSelect menu will appear before
  the DataSelect. You can add custom predefined selection options and
  another option to open the DataSelect.
  onClickAction = 'OPEN_COMPONENT' will call option.onClick() and switch
  the dropdown to option.component or DefaultComponenet.
  onClickAction = 'CLOSE_DROPDOWN' will call option.onClick() and close.
  onClose is not called when an option is clicked.

  setOptionWithCloseClicked: called when an option with 'CLOSE_DROPDOWN'
  is clicked.

  Options example on ReportsPage/HeaderOptions/ProjectSelect
*/

type Props = {
  displayName?: string;
  DefaultComponent?: React.ReactNode;
  CustomAnchorElement?: React.ReactNode;
  anchorOrigin?: {
    vertical: number | 'bottom' | 'top' | 'center';
    horizontal: number | 'left' | 'right' | 'center';
  };
  onOpen?: () => void;
  onClose?: () => void;
  options?: OptionsType[];
  setOptionWithCloseClicked: () => void;
  isPending?: boolean;
};

const ComponentToRender = ({
  options,
  DefaultComponent,
  componentOpenList,
  openComponent,
  setOptionWithCloseClicked,
}): React.ReactNode => {
  if (!options && DefaultComponent) return DefaultComponent;

  const index = componentOpenList?.findIndex((item) => item) || -1;
  if (index > -1) {
    const Component = options?.at(index || 0)?.component;
    if (Component) return Component;
    return DefaultComponent;
  }

  return (
    <OptionsSelectDropdown
      options={options || []}
      openComponent={openComponent}
      handleOptionWithCloseDropdown={setOptionWithCloseClicked}
    />
  );
};

const NestedMenu = ({
  displayName,
  DefaultComponent,
  CustomAnchorElement,
  anchorOrigin,
  onOpen,
  onClose,
  options,
  setOptionWithCloseClicked,
  isPending,
}: Props) => {
  const [componentOpenList, setComponentOpenList] = useState<
    Boolean[] | undefined
  >(options?.map(() => false));

  const openComponent = (index: number) =>
    setComponentOpenList(componentOpenList?.map((item, i) => i === index));

  const newOnOpen = () => {
    if (options) {
      setComponentOpenList(options?.map(() => false));
    }
    if (onOpen) onOpen();
  };

  return (
    <SelectPopover
      displayName={displayName || 'Selecionar'}
      onClose={onClose}
      onOpen={newOnOpen}
      CustomAnchorElement={CustomAnchorElement}
      anchorOrigin={anchorOrigin}
      isPending={isPending}
    >
      {ComponentToRender({
        options,
        DefaultComponent,
        componentOpenList,
        openComponent,
        setOptionWithCloseClicked,
      })}
    </SelectPopover>
  );
};

export default NestedMenu;
export type { OptionsType };
