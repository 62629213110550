export const convertNumberToTime = (value) => {
  if (!value.includes(':') && value.length >= 3) {
    return `${value.slice(0, 2)}:${value.slice(2)}`;
  }
  if (!value.includes(':') && value.length === 2) {
    if (Number(value[0]) > 2) {
      return `0${value[0]}:${value[1]}`;
    }
  }
  return value;
};

export const isHourLess2Dot = (value) =>
  /^(2[0-3]|[01]?[0-9])([0-5]?[0-9])$/.test(value);

export const isValid = (value: string) => {
  const regexp = /^\d{0,2}?:?\d{0,2}$/;

  if (!isHourLess2Dot(value) && !regexp.test(value)) {
    return false;
  }

  const val = isHourLess2Dot(value) ? convertNumberToTime(value) : value;
  const [hoursStr, minutesStr] = !isHourLess2Dot(val)
    ? val.split(':')
    : [val.slice(0, 2), val.slice(2)];

  const hours = Number(hoursStr);
  const minutes = Number(minutesStr);

  const isValidHour = (hour) =>
    Number.isInteger(hour) && hour >= 0 && hour < 24;
  const isValidMinutes = (min) =>
    (Number.isInteger(min) && hours >= 0 && hours < 24) || Number.isNaN(min);

  if (!isValidHour(hours) || !isValidMinutes(minutes)) {
    return false;
  }

  if (minutes < 10 && Number(minutesStr[0]) > 5) {
    return false;
  }

  const valArr = val.indexOf(':') !== -1 ? val.split(':') : [val];

  if (
    valArr[0] &&
    valArr[0].length &&
    (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)
  ) {
    return false;
  }

  if (
    valArr[1] &&
    valArr[1].length &&
    (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)
  ) {
    return false;
  }

  return true;
};
