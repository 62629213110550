import React, { useEffect, useState } from 'react';

import trello from '~/services/api/trello';

import ConnectionModal from './ConnectionModal';
import NewBoardModal from './NewBoard';
import * as S from './styles';

const UnconnectedTrelloSettings = ({ projectId }) => {
  const [boards, setBoards] = useState([
    {
      id: 'new-board',
      name: 'Criar novo',
      prefs: { backgroundColor: '#00000026' },
    },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBoard, setSelectedBoard] = useState<{
    id: string;
    name: string;
    prefs: { backgroundColor: string };
  } | null>(null);
  const getBoards = async () => {
    const newBoards = await trello.getBoards();
    setBoards([
      {
        id: 'new-board',
        name: 'Criar novo',
        prefs: { backgroundColor: '#00000026' },
      },
      ...newBoards,
    ]);
  };
  const getStyle = (board) => {
    const { prefs } = board;
    let style = {
      ...prefs,
    };
    if (prefs.backgroundImageScaled && prefs.backgroundImageScaled.length) {
      const tileImage = prefs.backgroundImageScaled.find(
        (img) => img.width === 480,
      );
      if (tileImage) {
        style = { 'background-image': `url(${tileImage.url})` };
      }
    } else if (prefs.backgroundColor) {
      style = { 'background-color': prefs.backgroundColor };
    }
    return style;
  };
  useEffect(() => {
    getBoards();
  }, []);
  return (
    <>
      <S.Container>
        {boards.map((board) => (
          <S.TrelloCell
            key={board.id}
            style={getStyle(board)}
            onClick={() => {
              setSelectedBoard(board);
              setIsModalOpen(true);
            }}
          >
            <S.TrelloFade className="trelloFade" />
            <S.TrelloDetails>
              <S.TrelloTitle>{board.name}</S.TrelloTitle>
            </S.TrelloDetails>
          </S.TrelloCell>
        ))}
      </S.Container>
      <ConnectionModal
        isOpen={isModalOpen && selectedBoard?.id !== 'new-board'}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        selectedBoard={
          selectedBoard !== null
            ? { ...selectedBoard, prefs: getStyle(selectedBoard) }
            : null
        }
        projectId={projectId}
      />
      <NewBoardModal
        isOpen={isModalOpen && selectedBoard?.id === 'new-board'}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        selectedBoard={
          selectedBoard !== null
            ? { ...selectedBoard, prefs: getStyle(selectedBoard) }
            : null
        }
        projectId={projectId}
      />
    </>
  );
};
export default UnconnectedTrelloSettings;
