import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

export const CheckboxContainer = styled.div`
  cursor: pointer;
`;

export const SearchInput = styled.input`
  border: none;
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: #d7d7d7;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SearchBar = ({
  inputValue,
  setInputValue,
  searchPlaceholder,
}: {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  searchPlaceholder?: string;
}) => (
  <SearchContainer>
    <SearchIcon style={{ color: '#959595', marginRight: '5px' }} />
    <SearchInput
      type="text"
      value={inputValue}
      placeholder={searchPlaceholder || 'Pesquisar'}
      onChange={(e) => setInputValue(e.target.value)}
    />
  </SearchContainer>
);

export default SearchBar;
