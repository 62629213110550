import React from 'react';
import shallow from 'zustand/shallow';

import useReportsStore from '~/store/reports/reports';

import * as S from './styles';

const FilterButton = () => {
  const { fetchTasksData, hasChangeFlags, isFetchingReports } = useReportsStore(
    (state) => ({
      fetchTasksData: state.fetchTasksData,
      hasChangeFlags: state.hasChangeFlags,
      isFetchingReports: state.isFetchingReports,
    }),
    shallow,
  );
  const disabled = Object.values(hasChangeFlags).every((item) => !item) || isFetchingReports;

  return (
    <S.FilterButton
      disabled={disabled}
      onClick={() => fetchTasksData()}
    >
      <S.FilterIcon />
      Filtrar
    </S.FilterButton>
  );
};

export default FilterButton;