import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

import useOnboardingStore from '~/store/onboarding';

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles, // eslint-disable-next-line
    backgroundColor: isDisabled ? null : isFocused ? '#f6f7fb' : null, // eslint-disable-next-line
    color: isFocused ? '#4F587A' : null,
    cursor: isDisabled ? 'not-allowed' : 'default',
    fontSize: '0.8em',
    fontWeight: 'normal',
    padding: '10px 75px 10px 20px',

    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && (isSelected ? data.color : 'none'),
    },
  }),
  control: (provided, { selectProps }) => ({
    ...provided,
    width: '100%',
    maxWidth: '650px',
    marginLeft: '15px',
    height: '60px',
    border: 'none',
    borderRadius: '0px',
    boxShadow: 'none',
    borderBottom: 'solid 1px transparent',
    backgroundColor: '#fff',
    borderBottomColor: selectProps.errorState ? '#E21300' : 'transparent',
    '@media (max-width: 1000px)': {
      margin: 0,
    },
  }),
  input: (styles) => ({
    ...styles,
    '&::after': {
      ...styles['::after'],
      width: '650px',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '16px',
    width: '100%',
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: 0,
    marginLeft: '15px',
    boxShadow: '0 2px 18px 4px rgba(0, 0, 0, 0.03)',
    backgroundColor: '#fff',
    width: '617px',
    paddingRight: '10px',
    '@media (max-width: 1000px)': {
      margin: 0,
      width: '100%',
    },
  }),
  groupHeading: (styles) => ({
    ...styles,
    fontWeight: '900',
  }),
  placeholder: (styles, { selectProps }) => ({
    ...styles,
    color: selectProps.errorState ? '#E21300' : '#959595',
    opacity: selectProps.errorState ? '50%' : '',
  }),
};

const msgStyles = {
  padding: '10px 75px 10px 20px',
  fontWeight: 'normal',
};

const NoOptionsMessage = () => (
  <span style={msgStyles}>Sem descrições existentes para este projeto</span>
);

const components = {
  DropdownIndicator: null,
  NoOptionsMessage,
};

export default function TaskDescriptionSelect({
  isDisabled,
  setValue,
  value,
  ...otherProps
}) {
  const increaseStepIndex = useOnboardingStore(
    (state) => state.increaseStepIndex,
  );
  const stepIndex = useOnboardingStore((state) => state.stepIndex);
  const tourIsRunning = useOnboardingStore((state) => state.isRunning);
  const [menuClose, setMenuClose] = useState(false);
  const selectRef = useRef();

  const handleBlur = (event) => {
    if (event.target.value) {
      setValue({ value: event.target.value, label: event.target.value });
    }
  };
  const handleFocus = () => {
    if (value) {
      selectRef.current.handleInputChange({
        currentTarget: { value: value.label },
      });
      setValue('');
    }
  };

  const handleMenuClose = () => {
    if (menuClose) return;
    setMenuClose((state) => !state);
    increaseStepIndex();
  };

  const handleMenuOpen = () => {
    if (tourIsRunning && stepIndex === 1) increaseStepIndex();
  };

  const tourConfig = () => {
    if (stepIndex > 2 && value === '') {
      const tourValue = {
        value: 'Passando pelo tour do labor!',
        label: 'Passando pelo tour do labor!',
      };
      setValue(tourValue);
      return tourValue;
    }
    return value;
  };

  return (
    <CreatableSelect
      {...otherProps}
      value={value === '' && tourIsRunning ? tourConfig() : value}
      ref={selectRef}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onMenuClose={handleMenuClose}
      onMenuOpen={handleMenuOpen}
      components={components}
      isDisabled={isDisabled}
      styles={otherProps.customStyles ? otherProps.customStyles : customStyles}
      formatCreateLabel={(userInput) => `Criar ${userInput}`}
    />
  );
}

TaskDescriptionSelect.propTypes = {
  customStyles: PropTypes.object,
  errorState: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setValue: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
};
