/* eslint-disable no-nested-ternary */
import { Provider } from '@rollbar/react';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import TaskBar from '~/app/TaskInput/TaskBar/TaskBar';
import NotificationModal from '~/components/NotificationModal/Notification';
import rollbarConfig from '~/config/rollbar';
import OnboardingModal from '~/site/onbordingModal';
import ExtensionModal from '~/site/onbordingModal/ExtensionModal';
import WelcomeModal from '~/site/onbordingModal/WelcomeModal';
import useOnboardingStore from '~/store/onboarding';

import ExtensionReminderToast from '../components/ExtensionReminderToast';
import StepComponent from '../components/StepComponent';

import * as S from './styles';

import 'react-toastify/dist/ReactToastify.css';

import type {
  NotificationServiceType,
  LoginServiceType,
} from '~/models/ServicesTypes';
import type {
  TaskService,
  PlannedTaskService,
  ProjectService,
  RootScope,
} from '~/models/types';

interface TaskInputProps {
  TaskService: TaskService;
  ProjectService: ProjectService;
  PlannedTaskService: PlannedTaskService;
  $rootScope: RootScope;
  NotificationService: NotificationServiceType;
  LoginService: LoginServiceType;
}
const TaskInput: React.FC<TaskInputProps> = ({
  NotificationService,
  ProjectService,
  TaskService,
  PlannedTaskService,
  $rootScope,
  LoginService,
}) => {
  const needToCompleteOnboarding = localStorage.getItem(
    'needToCompleteOnboarding',
  );
  const extensionModalWasSeen = localStorage.getItem('extensionModalSeen');
  const taskBarModalWasSeen = localStorage.getItem('taskBarModalWasSeen');

  const [trialMode, setTrialMode] = useState(false);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  const openWelcomeTourModal = useOnboardingStore(
    (state) => state.openWelcomeTourModal,
  );
  const [shouldRenderTasksModal, setRenderTaskModal] = useState(
    !needToCompleteOnboarding && !taskBarModalWasSeen,
  );

  const handleRenderingOnboardModal = () => {
    if (userIsAdmin && needToCompleteOnboarding) {
      return (
        <OnboardingModal
          minWidth="500px"
          maxHeight="100%"
          maxWidth="100%"
          trialMode={trialMode}
          NotificationService={NotificationService}
          blurBackground
        />
      );
    }
    return null;
  };

  const handleRenderingWelcomeModal = () => {
    if (!userIsAdmin && needToCompleteOnboarding) {
      return <WelcomeModal minWidth="500px" maxHeight="100%" maxWidth="100%" />;
    }
    return null;
  };

  const handleRenderingExtensionModal = () => {
    if (!needToCompleteOnboarding && !extensionModalWasSeen) {
      return <ExtensionModal />;
    }
    return null;
  };

  const fetchProjects = async () => {
    const { headers } = await ProjectService.getAllProjects();
    setTrialMode(!!headers('trial-period'));
  };

  useEffect(() => {
    const { role } = JSON.parse(localStorage.getItem('loggedUser') || '{}');
    const remindExtension = localStorage.getItem('remindExtension');
    const remindDate = new Date(remindExtension || '');

    if (remindExtension && remindDate.getTime() - new Date().getTime() < 0) {
      toast(<ExtensionReminderToast />, { containerId: 1 });
    }

    setUserIsAdmin(role === 'administrador');
  }, []);

  fetchProjects();
  return (
    <Provider config={rollbarConfig}>
      <S.ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        enableMultiContainer
        containerId={1}
      />
      <S.ToastContainer2 enableMultiContainer containerId={2} />
      <S.Wrapper>
        {handleRenderingExtensionModal()}
        {handleRenderingWelcomeModal()}
        {handleRenderingOnboardModal()}
        <TaskBar
          TaskService={TaskService}
          ProjectService={ProjectService}
          PlannedTaskService={PlannedTaskService}
          $rootScope={$rootScope}
          NotificationService={NotificationService}
          LoginService={LoginService}
        />
        {shouldRenderTasksModal && (
          <NotificationModal
            header="Novas regras da barra de cronômetro"
            body="A partir de agora para acompanhar seu tempo de trabalho é obrigatório selecionar um projeto e dar um nome às suas tarefas antes de iniciar o contador."
            info="Conheça mais detalhes sobre essa e outras atualizações clicando no botão"
            buttonText="Saiba mais"
            margin="20px 0 0 250px"
            modalWasSeen="taskBarModalWasSeen"
            closeModal={setRenderTaskModal}
          />
        )}
        {openWelcomeTourModal && <StepComponent />}
      </S.Wrapper>
    </Provider>
  );
};

export default TaskInput;
