import moment from 'moment';
import React, { useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import formatCurrency from '~/app/utils/currency';
import { msToTime } from '~/app/utils/duration';
import useReportsStore from '~/store/reports/reports';

import Chart from './Chart';
import ProjectList from './ProjectList';
import DownloadButton from './ReportButtons/DownloadButton';
import SendButton from './ReportButtons/SendButton';
import * as S from './styles';

import type NotificationServiceType from '~/models/ServicesTypes';

type Props = {
  AMPLITUDE_API_KEY: string;
  NotificationService: NotificationServiceType;
};

const SummaryChart = ({ AMPLITUDE_API_KEY, NotificationService }: Props) => {
  const [active, setActive] = useState<number | null>(null);
  const [showChart, setShowChart] = useState(true);
  const {
    projectsDuration,
    totalCost,
    selectedProjectsIds,
    dateRange,
    hasChangeFlags,
  } = useReportsStore(
    (state) => ({
      projectsDuration: state.projectsDuration,
      totalCost: state.totalCost,
      selectedProjectsIds: state.selectedProjectsIds,
      dateRange: state.dateRange,
      hasChangeFlags: state.hasChangeFlags,
    }),
    shallow,
  );

  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const calculateTitle = () => {
    const start = moment(dateRange.startDate);
    const end = moment(dateRange.endDate);

    if (!start.isValid()) return 'Duração total';

    if (start.isSame(end, 'month')) {
      const monthNumber = start.month();
      const month = months[monthNumber];
      const year = start.year();
      return `${month}, ${year}`;
    }
    return `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`;
  };

  useEffect(() => {
    if (hasChangeFlags.projects) return;
    if (selectedProjectsIds.length === 1) setShowChart(false);
    if (selectedProjectsIds.length !== 1) setShowChart(true);
  }, [hasChangeFlags, selectedProjectsIds]);

  if (!projectsDuration.projectsDuration.length) return null;

  return (
    <S.SummaryContainer>
      <S.SummaryHeader>
        <S.SummaryTitle>{calculateTitle()}</S.SummaryTitle>
        <S.ButtonContainer>
          <SendButton NotificationService={NotificationService} />
          <DownloadButton
            AMPLITUDE_API_KEY={AMPLITUDE_API_KEY}
            NotificationService={NotificationService}
          />
        </S.ButtonContainer>
      </S.SummaryHeader>

      <S.TableTitles>
        <S.TableTitle customWidth="40.8%" customAlign="left">
          Projetos
        </S.TableTitle>
        <S.TableTitle customWidth="13.6%" customAlign="right">
          Horas
        </S.TableTitle>
        <S.TableTitle customWidth="13.6%" customAlign="right">
          Valores
        </S.TableTitle>
        {showChart && (
          <S.TableTitle customWidth="32%" customAlign="center">
            Distribuição
          </S.TableTitle>
        )}
      </S.TableTitles>

      <S.SummaryMiddle>
        <S.SummaryText>
          <S.TableContainer>
            <ProjectList active={active} setActive={setActive} />
          </S.TableContainer>

          <S.TotalContainer>
            <S.TotalItem customWidth="60%" customAlign="left">
              Total
            </S.TotalItem>
            <S.TotalItem customWidth="20%" customAlign="right">
              {msToTime(projectsDuration.totalDuration)}
            </S.TotalItem>
            <S.TotalItem customWidth="20%" customAlign="right">
              {formatCurrency(totalCost)}
            </S.TotalItem>
          </S.TotalContainer>
        </S.SummaryText>

        {showChart && (
          <S.SummaryChart>
            <Chart active={active} setActive={setActive} />
          </S.SummaryChart>
        )}
      </S.SummaryMiddle>
    </S.SummaryContainer>
  );
};

export default SummaryChart;
