import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import Button from '../../components/Button';
import Link from '../../components/Link';
import { mq } from '../../helpers/responsive';
import AmplitudeUserStore from '../../services/api/amplitude';
import AuthService from '../../services/api/auth';

const Wrapper = styled.div`
  ${mq({
    margin: '0 auto',
    width: 'fit-content',
    textAlign: 'center',
  })}
`;

const PlanBoxes = styled.div`
  ${mq({
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    maxWidth: [null, null, 'fit-content'],
    alignSelf: ['auto', 'baseline'],
    alignItems: 'flex-end',
  })}
`;

const YearPlanBox = styled.div`
  ${mq({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: ['25px', '25px 25px 35px 25px', null, '25px 25px 45px 25px'],
    position: 'relative',
    minWidth: [null, null, '320px'],
    alignSelf: 'baseline',
  })}
`;

const MonthPlanBox = styled.div`
  ${mq({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: '25px',
    minWidth: '216px',
    marginLeft: '20px',
  })}
`;

const PlanTitle = styled.p`
  ${mq({
    color: '#5f5f5f',
    fontFamily: 'Bitter, sans-serif',
    fontSize: '15px',
    paddingBottom: '10px',
    borderBottom: 'solid 1px #f0f1f5',
    margin: 0,
    textAlign: ['none', null, null, 'center'],
  })}
`;

const HighlightBox = styled.div`
  ${mq({
    position: 'absolute',
    top: ['-25px', null, null, '25px'],
    right: ['15px', null, null, '-115px'],
    width: '152px',
    backgroundColor: '#4680fe',
    padding: '10px',
    fontSize: '13px',
    color: '#ffffff',
    zIndex: 2,
  })}
`;

const PriceBox = styled.span`
  ${({ featured }) =>
    mq({
      padding: featured ? ['25px 0', null, null, '25px 0 10px 0'] : '15px 0',
      textAlign: 'center',
      borderBottom: ['solid 1px #f0f1f5', null, null, 'none'],
    })}
`;

const SmallPrice = styled.span`
  ${({ featured }) =>
    mq({
      fontFamily: 'Open Sans, sans-serif',
      fontSize: featured
        ? ['20px', null, null, '22px']
        : [null, null, null, '18px'],
      color: '#5f5f5f',
      display: 'inline-block',
      marginRight: '9px',
    })}
`;

const BigPrice = styled.span`
  ${({ featured }) =>
    mq({
      fontFamily: 'Open Sans, sans-serif',
      fontSize: featured
        ? ['62px', null, null, '72px']
        : [null, null, null, '48px'],
      fontWeight: 600,
      color: '#5f5f5f',
      display: 'inline-block',
      marginRight: '5px',
    })}
`;

const RegisterButton = styled(Button)`
  ${mq({
    alignSelf: 'center',
    marginTop: ['25px', null, null, '35px'],
  })}
`;

const HOTMART_YEARLY_CODE = '8lbjzjr7';
const HOTMART_MONTHLY_CODE = 'sgb2f0h7';
let amplitude;

export default function PlanList(props) {
  const [loggedUser, setLoggedUser] = useState({});

  useEffect(() => {
    const user = AuthService.getLoggedUser();
    setLoggedUser(user);
    amplitude = new AmplitudeUserStore(null, props.AMPLITUDE_API_KEY);
    amplitude.setUserId(user.email);
    amplitude.sendData('Accessed subscribe page');
  }, []);

  return (
    <Wrapper>
      <h1>Olá {loggedUser.name}!</h1>
      <h2 style={{ marginBottom: '60px' }}>
        É necessário fazer a assinatura de um dos planos abaixo para continuar:
      </h2>
      <PlanBoxes>
        <YearPlanBox>
          <HighlightBox>
            <strong>Economize 2 meses</strong> com o plano anual!
          </HighlightBox>
          <PlanTitle>Plano anual</PlanTitle>
          <PriceBox featured>
            <SmallPrice featured style={{ marginRight: '6px' }}>
              R$
            </SmallPrice>{' '}
            <BigPrice featured style={{ marginRight: '5px' }}>
              249
            </BigPrice>
            <SmallPrice featured>,00/ano</SmallPrice>
          </PriceBox>
          <RegisterButton
            as="a"
            onClick={() =>
              amplitude.sendData('Clicked Subscribe Button', {
                plan: 'Plano Anual',
              })
            }
            href={`https://pay.hotmart.com/M6389598T?checkoutMode=10&name=${loggedUser.name}&email=${loggedUser.email}&off=${HOTMART_YEARLY_CODE}`}
            target="_blank"
          >
            Assinar Agora
          </RegisterButton>
        </YearPlanBox>
        <MonthPlanBox>
          <PlanTitle>Plano mensal</PlanTitle>
          <PriceBox>
            <SmallPrice style={{ marginRight: '6px' }}>R$</SmallPrice>{' '}
            <BigPrice style={{ marginRight: '5px' }}>24</BigPrice>
            <SmallPrice>,90/mês</SmallPrice>
          </PriceBox>
          <Link
            onClick={() =>
              amplitude.sendData('Clicked Subscribe Button', {
                plan: 'Plano Mensal',
              })
            }
            href={`https://pay.hotmart.com/M6389598T?checkoutMode=10&name=${loggedUser.name}&email=${loggedUser.email}&off=${HOTMART_MONTHLY_CODE}`}
            target="_blank"
            style={{
              alignSelf: 'center',
              marginTop: '15px',
              color: '#ff3c58',
              fontSize: '14px',
              fontWeight: 600,
            }}
          >
            Assinar Agora
          </Link>
        </MonthPlanBox>
      </PlanBoxes>
    </Wrapper>
  );
}

PlanList.propTypes = {
  AMPLITUDE_API_KEY: PropTypes.string,
};
