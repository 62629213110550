import React from 'react';

import DateField from './DateField';
import EstimatedHours from './EstimatedHours';
import HourlyCharged from './HourlyCharged';
import StatusList from './StatusList';
import TextClient from './TextClient';
import TextFieldColorPicker from './TextFieldColorPicker';
import TotalCharged from './TotalCharged';

// TODO: Rework this component to avoid Eslint errors

interface TypesOfQuestions {
  [type: string]: React.ReactNode;
}

export enum TypesOfInput {
  textColorPicker = 'textColorPicker',
  textClient = 'textClient',
  dateField = 'dateField',
  statusList = 'statusList',
  estimatedHours = 'estimatedHours',
  totalCharged = 'totalCharged',
  hourlyCharged = 'hourlyCharged',
}
interface Props {
  title: string;
  // type:
  //   | 'textColorPicker'
  //   | 'textClient'
  //   | 'dateField'
  //   | 'statusList'
  //   | 'estimatedHours'
  //   | 'totalCharged'
  //   | 'hourlyCharged'
  //   | 'selectTeam';
}

interface ProjectValueProps {
  name: string;
  colorName?: string | null;
  statusName?: string;
  dateData?: string | null;
  onChange?: Function;
  value?: string | null;
  initialHours?: number | null;
  totalPrice?: number | string | null;
  estimatedHours?: number | null;
}
interface Props extends ProjectValueProps {
  title: string;
  type: TypesOfInput;
}

const InputField: React.FC<Props> = ({
  title,
  type,
  name,
  colorName,
  statusName,
  dateData,
  onChange,
  value,
  initialHours,
  totalPrice,
  estimatedHours,
}) => {
  const questionProps: ProjectValueProps = {
    name,
    colorName,
    statusName,
    dateData,
    onChange,
    value,
    initialHours,
    totalPrice,
    estimatedHours,
  };
  const TYPES_OF_QUESTIONS: TypesOfQuestions = {
    textColorPicker: React.createElement(TextFieldColorPicker, questionProps),
    textClient: React.createElement(TextClient, questionProps),
    dateField: React.createElement(DateField, questionProps),
    statusList: React.createElement(StatusList, questionProps),
    estimatedHours: React.createElement(EstimatedHours, questionProps),
    totalCharged: React.createElement(TotalCharged, questionProps),
    hourlyCharged: React.createElement(HourlyCharged, questionProps),
  };

  return (
    <div>
      <p>
        <label htmlFor={name}>{title}</label>
      </p>
      {TYPES_OF_QUESTIONS[type]}
    </div>
  );
};

export default InputField;
