import angular from 'angular';
import { react2angular } from 'react2angular';
import angularTokenAuth from 'ng-token-auth';

import LoginPage from '~/site/LoginPage';
import RegisterPage from '~/site/RegisterPage';
import CompleteRegisterPage from '~/site/CompleteRegisterPage'
import ResetPage from '~/site/ResetPage';
import RegisterInvitedPage from '~/site/RegisterInvitedPage'

export default angular
  .module('root.components.site', [angularTokenAuth])
  // REACT COMPONENTS
  .config($stateProvider => {
    'ngInject';

    $stateProvider.state('login', {
      url: '/entrar',
      component: 'login',
      resolve: {
        auth: (UserService, $state) => {
          if (UserService.getLoggedUser()) {
            $state.go(UserService.getUserBaseState());
          }
        },
      },
    });

    $stateProvider.state('register', {
      url: '/cadastrar',
      component: 'register',
    });

    $stateProvider.state('complete-register', {
      url: '/cadastrar/:userData',
      component: 'completeRegister',
      resolve: {
        userData: $stateParams => {
          return $stateParams.userData;
        },
      },
    });

    $stateProvider.state('reset', {
      url: '/reset',
      component: 'reset',
    });
    $stateProvider.state('invite', {
      url: '/invite/:codInvite',
      component: 'invite',
      resolve: {
        codInvite: $stateParams => {
          return $stateParams.codInvite;
        }
      }
    });
  })
  .component('login', react2angular(LoginPage, [], ['AMPLITUDE_API_KEY', 'NotificationService']))
  .component('register', react2angular(RegisterPage, [], [
    'AMPLITUDE_API_KEY', 'TOS_URL', 'NotificationService', '$stateParams']))
  .component('invite', react2angular(RegisterInvitedPage, [], [
    'AMPLITUDE_API_KEY', 'TOS_URL', 'NotificationService', '$stateParams']))
  .component('completeRegister', react2angular(CompleteRegisterPage, [], ['$stateParams', 'AMPLITUDE_API_KEY', 'TOS_URL', 'UserService']))
  .component('reset', react2angular(ResetPage, [], ['$location'])).name;
