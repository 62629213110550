import type {
  FetchInputDto,
  FetchOutputDto,
  IApiService,
  RecordOrUndefined,
} from '~/common/domain/interfaces/services/api';

export type AuthHeaders = {
  'access-token': string;
  'token-type': string;
  client: string;
  expiry: string;
  uuid: string;
};

export class ApiService implements IApiService {
  private readonly AUTH_HEADERS_KEY = 'auth_headers';

  constructor(private readonly API_URL: string) {}

  async fetch<
    Body extends RecordOrUndefined = undefined,
    Data extends RecordOrUndefined = undefined,
    Header extends RecordOrUndefined = undefined,
    Params extends Record<string, string | string[]> | undefined = undefined,
  >(
    params: FetchInputDto<Header, Body, Params>,
  ): Promise<FetchOutputDto<Data>> {
    const { path, method, headers, body, searchParams } = params;
    const url = new URL(path, this.API_URL);

    url.search = this.getUrlSearchParams(searchParams);

    const authHeaders = this.getAuthHeaders();

    const response = await fetch(url.toString(), {
      method: method || 'GET',
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        'Key-Inflection': 'camel',
        ...authHeaders,
        ...headers,
      },
      body: (method || 'GET') !== 'GET' ? JSON.stringify(body) : undefined,
    });

    const newAccessHeaders = this.getAuthHeadersFromResponse(response);
    this.saveAuthHeaders(newAccessHeaders, authHeaders);

    const parsedResponse = await response.json();
    return parsedResponse as Data;
  }

  private getAuthHeaders(): AuthHeaders | undefined {
    try {
      const authHeaders = localStorage.getItem(this.AUTH_HEADERS_KEY);
      if (!authHeaders) return undefined;
      return JSON.parse(authHeaders);
    } catch (e) {
      return undefined;
    }
  }

  private saveAuthHeaders(
    newHeaders: Partial<AuthHeaders>,
    oldHeaders: AuthHeaders | undefined,
  ): void {
    const mergedHeaders = Object.assign(oldHeaders || {}, newHeaders);
    localStorage.setItem(this.AUTH_HEADERS_KEY, JSON.stringify(mergedHeaders));
  }

  private getAuthHeadersFromResponse(response: Response): Partial<AuthHeaders> {
    // Using stringify/parse to remove undefined values
    return JSON.parse(
      JSON.stringify({
        'access-token': response.headers.get('access-token') || undefined,
        'token-type': response.headers.get('token-type') || undefined,
        client: response.headers.get('client') || undefined,
        expiry: response.headers.get('expiry') || undefined,
        uuid: response.headers.get('uuid') || undefined,
      }),
    );
  }

  private getUrlSearchParams(
    searchParams?: Record<string, string | string[]>,
  ): string {
    const sanitizedParams = Object.fromEntries(
      Object.entries(searchParams || {}).filter(
        ([_key, value]) => value && value !== '',
      ),
    );
    const urlSearchParams = new URLSearchParams();
    Object.entries(sanitizedParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          urlSearchParams.append(`${this.toSnakeCase(key)}[]`, item);
        });
      } else {
        urlSearchParams.append(this.toSnakeCase(key), value);
      }
    });

    return urlSearchParams.toString();
  }

  private toSnakeCase(input: string): string {
    return input
      .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
      .replace(/^_/g, '');
  }
}
