import angular from 'angular';
import angularSanitize from 'angular-sanitize';
import angularFilter from 'angular-filter';
import angularMaterial from 'angular-material';
import uiRouter from '@uirouter/angularjs';
import angularCookie from 'angular-cookie';
import duScroll from 'angular-scroll';
import { react2angular } from 'react2angular';
import 'angular-material/angular-material.min.css';
import './i18n/pt_BR';
import { ComponentsModule } from './components/components.module';
import { CommonModule } from './common/common.module';
import InternalPage from '~/app/InternalPage';
import './root.scss';

export const AppModule = angular
  .module('root', [
    angularSanitize,
    uiRouter,
    duScroll,
    angularCookie,
    angularMaterial,
    angularFilter,
    CommonModule,
    ComponentsModule,
  ])
  .config(
    (
      $qProvider,
      $stateProvider,
      $urlRouterProvider,
      $locationProvider,
    ) => {
      'ngInject';

      $qProvider.errorOnUnhandledRejections(false);
      $locationProvider.html5Mode(true);
      $urlRouterProvider.otherwise('/entrar');

      $urlRouterProvider.rule(function ($injector, $location) {
        const path = $location.path();

        // If last charcter is a slash, return the same url without the slash
        if (path[path.length - 1] === '/') {
          return path.substr(0, path.length - 1);
        }
      });

      $stateProvider.state('internal', {
        url: '/internal',
        component: 'internal',
        resolve: {
          auth: $auth => {
            return $auth.validateUser();
          },
        },
      });
    },
  )
  .component('internal', react2angular(InternalPage, [], ['InternalService']))
  .run(
    (
      $window,
      $rootScope,
      $document,
      $log,
      $state,
      $transitions,
      UserService,
      PopoverService,
      LoginService
    ) => {
      'ngInject';

      $transitions.onBefore({}, transition => {
        if (transition.to() == transition.from()) {
          const top = 0;
          const duration = 1000;
          $document.scrollTop(top, duration);
        }
      });

      // $transitions.onError({}, () => {
      //   LoginService.logout();
      // })

      $transitions.onSuccess({}, () => {
        $document[0].documentElement
          .querySelector('body')
          .classList.remove('hide-over');
        PopoverService.closeAll();
      });

      $transitions.onEnter({}, (transition, state) => {
        if (state.abstract) {
          return;
        }

        const user = UserService.getLoggedUser();
        if (user && typeof($crisp) !== "undefined") {
          $crisp.push(['set', 'user:nickname', [user.name]]);
          $crisp.push(['set', 'user:email', [user.email]]);
        }

        // Força ir para o topo da página
        $window.document.body.scrollTop = $window.document.documentElement.scrollTop = 0;
      });
    },
  )
  .value('EventEmitter', payload => ({ $event: payload })).name;
