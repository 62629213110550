import styled from '@emotion/styled';

import Button from '~/components/Button';
import theme from '~/theme';

export const Container = {
  overlay: {
    position: 'unset',
    marginLeft: '19%',
    paddingTop: '60px',
    height: 'min-content',
    width: 'min-content',
  },
  content: {
    position: 'relative',
    minWidth: '300px',
    backgroundColor: theme.colors.blue1,
    borderRadius: '6px',
    padding: '20px',
  },
};

export const HeaderWrapper = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.colors.containerBg};
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BodyWrapper = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.containerBg};
  overflow-wrap: break-word;
`;

export const NextWrapper = styled.a`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.containerBg};
  overflow-wrap: break-word;
  margin-left: 12px;
`;

export const StyledButton = styled(Button)`
  background: ${theme.colors.secondary};
  color: ${theme.colors.gray25};
  width: 92px;
  height: 33px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 5px;
`;

export const ModalClose = styled.div`
  display: flex;
  cursor: pointer;
  width: 20px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  align-items: center;
`;

export const Paragraph = styled.div`
  padding-top: 15px;
`;
