import React, { useState, useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';

import SearchableSelect from '~/app/components/Select/SearchableSelect';
import useProjectsStore from '~/store/projects/projects';
import useReportsStore from '~/store/reports/reports';

import { ListIcon } from '../styles';

import type {
  Item,
  OptionsType,
} from '~/app/components/Select/SearchableSelect';
import type { Project } from '~/models/types';
import type { ProjectsStatus } from '~/store/projects/types';

const ProjectSelect = () => {
  const [projects, setProjects] = useState<(Item & Project)[]>([]);

  const { projectsData, projectsDataNewest, fetchProjectsByNewest } =
    useProjectsStore(
      (state) => ({
        projectsData: state.projects,
        projectsDataNewest: state.projectsByNewest,
        fetchProjectsByNewest: state.fetchProjectsByNewest,
      }),
      shallow,
    );
  const { setSelectedProjectsIds, hasChangeFlags, selectedProjectsIds } =
    useReportsStore(
      (state) => ({
        setSelectedProjectsIds: state.setSelectedProjectsIds,
        hasChangeFlags: state.hasChangeFlags,
        selectedProjectsIds: state.selectedProjectsIds,
      }),
      shallow,
    );

  // Id list can be changed outside this component.
  useEffect(() => {
    setProjects(
      projectsData.map((project) => ({
        ...project,
        checked: selectedProjectsIds.includes(project.id),
      })),
    );
  }, [selectedProjectsIds]);

  useEffect(() => {
    setProjects(
      projectsData.map((project) => ({ ...project, checked: false })),
    );
  }, [projectsData]);

  const selectedProjects = projects.filter((project) => project.checked);

  const calculateDisplayName = (): string => {
    if (
      selectedProjects.length === projects.length ||
      projects.length === 0 ||
      selectedProjects.length === 0
    )
      return 'Todos os projetos';
    if (selectedProjects.length === 1) {
      return selectedProjects[0].name || 'Projeto';
    }
    return 'Múltiplos projetos';
  };

  const onClose = () => {
    setSelectedProjectsIds(selectedProjects.map((project) => project.id));
  };

  const handleRecentProjects = async () => {
    await fetchProjectsByNewest();
    const first5 = projectsDataNewest.map((project) => project.id).slice(0, 5);

    setProjects((currentProjects) =>
      currentProjects.map((project) => ({
        ...project,
        checked: first5.includes(project.id),
      })),
    );
  };

  const applyFilter = (
    filter: 'all' | 'recent' | 'status',
    status?: ProjectsStatus,
  ) => {
    switch (filter) {
      case 'all':
        setProjects((currentProjects) =>
          currentProjects.map((project) => ({ ...project, checked: true })),
        );
        break;
      case 'recent': {
        handleRecentProjects();
        break;
      }
      case 'status':
        setProjects((currentProjects) =>
          currentProjects.map((project) => ({
            ...project,
            checked: project.status === status,
          })),
        );
        break;
      default:
        break;
    }
  };

  const options: OptionsType[] = useMemo(
    () => [
      {
        text: 'Todos os projetos',
        onClick: () => applyFilter('all'),
      },
      {
        text: 'Mais recentes (últimos 5 projetos)',
        onClick: () => applyFilter('recent'),
      },
      {
        text: 'Não iniciados',
        onClick: () => applyFilter('status', 'not_started'),
        disabled: !projectsData
          .map((project) => project.status)
          .includes('not_started'),
      },
      {
        text: 'Em andamento',
        onClick: () => applyFilter('status', 'running'),
        disabled: !projectsData
          .map((project) => project.status)
          .includes('running'),
      },
      {
        text: 'Finalizados',
        onClick: () => applyFilter('status', 'finished'),
        disabled: !projectsData
          .map((project) => project.status)
          .includes('finished'),
      },
      {
        text: 'Selecionar projetos',
        icon: <ListIcon />,
        onClickAction: 'OPEN_COMPONENT',
      },
    ],
    [projectsData, projectsDataNewest, projects],
  );

  const emptyMessage = <div>Nenhum projeto disponível.</div>;

  return (
    <SearchableSelect
      data={projects}
      setData={(data: (Item & Project)[]) => setProjects(data)}
      displayName={calculateDisplayName()}
      onClose={onClose}
      emptyMessage={emptyMessage}
      searchPlaceholder="Pesquisar projetos"
      options={options}
      isPending={hasChangeFlags.projects}
    />
  );
};

export default ProjectSelect;
