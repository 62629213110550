import styled from '@emotion/styled';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import { MdContentCopy } from 'react-icons/md';

import theme from '~/theme';

export const ButtonTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  color: ${theme.colors.gray25};
`;

export const ButtonPrintIcon = styled(DownloadIcon)`
  width: 14px;
  height: 19px;
  color: ${theme.colors.gray25};
  margin-top: 2px;
`;

export const ButtonSendIcon = styled(SendIcon)`
  width: 18.67px;
  height: 16px;
  color: ${theme.colors.gray25};
  margin-top: 2px;
`;

export const SummaryButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 15px 6px 15px;
  margin-left: 20px;
  background-color: ${theme.colors.secondary};
  border: none;
  &:hover:enabled {
    background-color: ${theme.colors.gray25};
    ${ButtonTitle} {
      color: ${theme.colors.white};
    }
    ${ButtonPrintIcon} {
      color: ${theme.colors.white};
    }
    ${ButtonSendIcon} {
      color: ${theme.colors.white};
    }
  }
  :disabled {
    opacity: 0.5;
  }
`;

/* Modal */
export const HeaderText = styled.h2`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  margin-bottom: 10%;
  text-align: center;
`;

export const ModalIcon = styled(MdContentCopy)`
  font-size: 28px;
  color: #7d7d7d;
`;
