import styled from '@emotion/styled';

import { mq } from '../../../helpers/responsive';

export const ListHeader = styled.div`
  ${mq({
    padding: '0.8rem 75px 0.8rem 40px',
    display: ['none', 'flex'],
    flexDirection: 'row',
    fontWeight: 'bold',
  })}
`;

export const Item = styled.div`
  ${mq({
    borderBottom: '1px solid #eee',
    backgroundColor: '#fff',
    padding: '0.8rem 75px 0.8rem 40px',
    '&:first-of-type': {
      borderTop: '1px solid #eee',
    },
    display: 'flex',
    flexDirection: ['column', 'row'],
    '&:hover': {
      outline: 'none',
    },
  })}
`;

export const Column = styled.div`
  ${({ flex, textAlign }) =>
    mq({
      flex: flex || 1,
      textAlign: textAlign || 'left',
      padding: '0 10px',
      alignSelf: 'center',
      ':first-of-type': {
        paddingLeft: 0,
      },
    })}
`;
