import React from 'react';

import DesktopSvg from '~/icons/DesktopSvg';
import * as FS from '~/site/RegisterInvitedPage/styles';
import PageTitle from '~/site/components/PageTitle';

import * as S from './styles';

interface WelcomeProps {}

const Welcome: React.FC<WelcomeProps> = () => (
  <S.WelcomeBox>
    <PageTitle>Boas vindas!</PageTitle>
    <FS.WelcomeText>
      Você recebeu um link de convite para o Labor.
    </FS.WelcomeText>
    <FS.WelcomeText>
      Falta pouco para registrar suas horas. Preencha os dados ao lado para
      concluir o seu cadastro ou entre com sua conta do Google ou Microsoft.
    </FS.WelcomeText>
    <S.WelcomeImage>
      <DesktopSvg />
    </S.WelcomeImage>
  </S.WelcomeBox>
);

export default Welcome;
