import styled from '@emotion/styled';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import Button from '~/components/Button';


export const SelectContainer = styled.header`
  border-bottom: 1px solid #d7d7d7;
  margin-left: 40px;
  margin-right: 75px;
  margin-bottom: 40px;
`;

 export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 300px;
  justify-content: space-around;
`;

export const FilterIcon = styled(FilterAltIcon)`
  font-size: 20px;
`;

export const FilterButton = styled(Button)`
  height: 32px;
  margin: 1px 0;
  font-size: 14px;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  :hover {
    background: #ff3c58;
  }
`;
