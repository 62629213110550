import styled from '@emotion/styled';

import Button from '~/components/Button';
import Input from '~/components/Input';
import theme from '~/theme';

export const ModalContainer = styled.div`
  background-color: ${theme.colors.white};
  box-shadow: 0 0 8px 12px rgba(0, 0, 0, 0.02);
  max-width: 450px;
  min-height: 285px;
`;

export const NavBar = styled.div`
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Container = styled.div`
  color: ${theme.colors.gray20};
  text-align: center;
  padding-bottom: 40px;
  border-radius: 0;
`;

export const Title = styled.p`
  margin-top: 0;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .005em;
  margin-bottom: 40px;
`;

export const Emoji = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 16px;
  margin: 16px 0;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  height: 36px;
  width: 194px;
  display: flex;
  min-width: 288px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  justify-content: center;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  &:hover {
    background-color: ${theme.colors.primary};
  }
`;

export const InputWrapper = styled.div<{ fullWidth?: boolean }>`
  width: 100%; 
  padding: 24px 0;
`;

export const StyledInput = styled(Input)`
  border-width: 0 0 2px;
  padding: 14px 20px 14px 0;
  max-height: 30px;
  &:focus {
    border-color: ${theme.colors.primary};
  }
`;
