import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import ReactModal from 'react-modal';

import { ModalClose } from '~/components/SideBar/TeamModal/styles';

import * as S from './styles';

import type { UserServiceType } from '~/models/ServicesTypes';
import type NotificationServiceType from '~/models/ServicesTypes';

interface SubscriptionCancelConfirmModalProps {
  UserService: UserServiceType;
  NotificationService: NotificationServiceType;
  teamModalCancelConfirm: boolean;
  setTeamModalCancelConfirm: Function;
}

interface Motivation {
  motivation: string;
}

const SubscriptionCancelConfirmModal: React.FC<
  SubscriptionCancelConfirmModalProps
> = ({
  UserService,
  NotificationService,
  teamModalCancelConfirm,
  setTeamModalCancelConfirm,
}: SubscriptionCancelConfirmModalProps) => {
  const [motivation] = useState<string>('');

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(246, 247, 251, 0.97)',
      zIndex: 2,
    },
    content: {
      position: 'relative',
      top: '60%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      backgroundColor: 'transparent',
      borderRadius: 0,
      border: 'none',
      padding: '40px 35px',
      width: '580px',
      transform: 'translate(-50%, -80%)',
    },
  };

  const confirmCancelRequest = (Cancelmotivation: Motivation) => {
    const cancellationRequest = {
      motivation: Cancelmotivation.motivation,
    };
    setTeamModalCancelConfirm(false);
    UserService.requestCancellation(cancellationRequest).then(
      () => {
        NotificationService.showNotification(
          'Seu pedido de cancelamento foi enviado! Em breve enviaremos um email de confirmação.',
          'success',
        );
      },
      () => {
        NotificationService.showNotification(
          'Houve um erro tentar cancelar sua assinatura, por favor nos avise em contato@getlabor.com.br.',
          'error',
        );
      },
    );
  };

  return (
    <ReactModal
      closeTimeoutMS={180}
      isOpen={teamModalCancelConfirm}
      style={customStyles}
      ariaHideApp={false}
      onRequestClose={() => setTeamModalCancelConfirm(false)}
    >
      <S.ModalContainer>
        <S.NavBar>
          <ModalClose>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              onClick={() => setTeamModalCancelConfirm(false)}
            >
              <path
                fill="#959595"
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </ModalClose>
        </S.NavBar>
        <S.Container>
          <S.Title>Deseja realmente cancelar sua assinatura?</S.Title>
          <S.Emoji>:&apos;(</S.Emoji>
          <Formik
            initialValues={{ motivation }}
            onSubmit={(cancelMotivation) => {
              confirmCancelRequest(cancelMotivation);
            }}
          >
            <Form noValidate style={{ padding: '0px 42px' }}>
              <S.InputWrapper>
                <S.StyledInput
                  name="motivation"
                  type="text"
                  value=""
                  placeholder="Algo que possamos melhorar? (Opcional)"
                />
              </S.InputWrapper>

              <S.ButtonWrapper>
                <S.StyledButton type="submit">
                  Sim, cancele minha assinatura!
                </S.StyledButton>
              </S.ButtonWrapper>
            </Form>
          </Formik>
        </S.Container>
      </S.ModalContainer>
    </ReactModal>
  );
};

export default SubscriptionCancelConfirmModal;
