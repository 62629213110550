import React, { useState } from 'react';
import shallow from 'zustand/shallow';

import ToolTip from '~/app/components/Tooltip';
import useReportsStore from '~/store/reports/reports';
import useUserStore from '~/store/user';


import ConfirmSendModal from './ConfirmSendModal';
import * as S from './styles';

import type NotificationServiceType from '~/models/ServicesTypes';

type Props = {
  NotificationService: NotificationServiceType;
};

const SendButton = ({ NotificationService }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { reports, dateRange } = useReportsStore(
    (state) => ({
      reports: state.reports,
      dateRange: state.dateRange,
    }),
    shallow,
  );
  const user = useUserStore((state) => state.user);

  const testReportExists = () => {
    if (!dateRange.startDate || !reports) return false;
    return reports.some((report) => {
      const referenceDate = new Date(report.referenceDate);
      return (
        referenceDate.getMonth() === dateRange.startDate?.getMonth() &&
        referenceDate.getFullYear() === dateRange.startDate?.getFullYear() &&
        report.user.id === user.id
      );
    });
  };

  const testFullMonthSelected = () => {
    if (dateRange.startDate === null || dateRange.endDate === null)
      return false;
    const sameMonth =
      dateRange.startDate.getFullYear() === dateRange.endDate.getFullYear() &&
      dateRange.startDate.getMonth() === dateRange.endDate.getMonth();
    const isFirstMonthDate = dateRange.startDate.getDate() === 1;
    const lastMonthDate = new Date(dateRange.startDate);
    lastMonthDate.setMonth(lastMonthDate.getMonth() + 1);
    lastMonthDate.setDate(0);
    const isLastMonthDate =
      lastMonthDate.getDate() === dateRange.endDate.getDate();

    return sameMonth && isFirstMonthDate && isLastMonthDate;
  };

  const ReportExists = testReportExists();
  const FullMonthSelected = testFullMonthSelected();
  const disableSaveReport = ReportExists || !FullMonthSelected;
  const tooltipMessage = () => {
    if (!disableSaveReport) return 'Fechar relatório do mês selecionado';
    if (!FullMonthSelected) return 'Selecione um mês completo';
    return 'Relatório já existe';
  };

  const handleClick = () => {
    if (!disableSaveReport) setModalOpen(true);
  };

  return (
    <ToolTip tooltip={tooltipMessage()} placement="bottom">
      <S.SummaryButton
        disabled={disableSaveReport}
        onClick={() => handleClick()}
      >
        <S.ButtonSendIcon />
        <S.ButtonTitle>Enviar relatório</S.ButtonTitle>
      </S.SummaryButton>
      <ConfirmSendModal
        modalIsOpen={modalOpen}
        setModalOpen={setModalOpen}
        NotificationService={NotificationService}
      />
    </ToolTip>
  );
};

export default SendButton;
