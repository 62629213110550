import styled from '@emotion/styled';

import theme from '~/theme';

export const Container = styled.div`
  background-color: ${theme.colors.black4};
  width: 329px;
  border-radius: 6px;
  padding: 16px;
`;

export const Content = styled.div`
  margin-bottom: 24px;
`;

export const Footer = styled.div``;

export const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.colors.containerBg};
  margin-bottom: 12px;
`;

export const IndexText = styled.div`
  color: ${theme.colors.gray20};
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 24px;
  text-transform: uppercase;
  opacity: 0.6;
  margin-bottom: 4px;
`;

export const Exit = styled.a`
  color: ${theme.colors.gray20};
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.6;
`;
