import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';

import Button from '../../components/Button';
import PasswordInput from '../../components/PasswordInput';
import { ShowBeforeMedium } from '../../helpers/responsive';
import API from '../../services/api';
import Container from '../components/Container';
import Page from '../components/Page';
import PageTitle from '../components/PageTitle';

import loginMobileImg from './images/login-noise-360.png';
import * as S from './styles';

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('passwordConfirm'), null], 'As senhas devem ser identicas'),
  passwordConfirm: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser identicas'),
});

async function doLogin(payload) {
  const response = await API.auth.resetPassword(payload);
  const json = await response.json();

  if (response.status !== 200) {
    throw new Error('Ocorreu um problema ao redefinir sua senha');
  }

  return json;
}

export default function ResetPage(props) {
  const [message, setMessage] = useState('');
  const { email, token } = props.$location.search();

  return (
    <Page>
      <Container>
        <S.InnerWraper>
          <ShowBeforeMedium>
            <img src={loginMobileImg} style={{ width: '100%' }} />
          </ShowBeforeMedium>
          <S.FormWrapper>
            <PageTitle style={{ marginBottom: '45px' }}>
              Redefinir Senha
            </PageTitle>
            <Formik
              initialValues={{ password: '', passwordConfirm: '' }}
              validationSchema={LoginSchema}
              onSubmit={({ password, passwordConfirm }, { setSubmitting }) => {
                doLogin({ email, token, password, passwordConfirm })
                  .then(() => setMessage('Senha redefinida com sucesso!'))
                  .catch((e) => {
                    setMessage(e.message);
                    setSubmitting(false);
                  });
              }}
            >
              {({ isSubmitting }) => (
                <Form noValidate style={{ position: 'relative' }}>
                  <S.StyledSnackbar isVisible={!!message}>
                    {message}
                  </S.StyledSnackbar>
                  <PasswordInput
                    name="password"
                    value=""
                    placeholder="Nova Senha"
                  />
                  <PasswordInput
                    name="passwordConfirm"
                    value=""
                    placeholder="Confirme a Nova Senha"
                  />

                  <S.SubmitWrapper>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      style={{ minWidth: '168px' }}
                    >
                      Redefinir Senha
                    </Button>
                  </S.SubmitWrapper>
                </Form>
              )}
            </Formik>
          </S.FormWrapper>
        </S.InnerWraper>
      </Container>
    </Page>
  );
}

ResetPage.propTypes = {
  $location: PropTypes.shape({
    search: PropTypes.func,
  }),
};
