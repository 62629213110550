import styled from '@emotion/styled';
import { faFrownOpen, faSmile } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

import formatCurrency from '../utils/currency';

import BoxContainer from './BoxContainer';

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({ align }) =>
    align
      ? `
        align-items: ${align};
        justify-content: ${align};
      `
      : ''}
  &:after {
    content: '';
    width: 1px;
    height: 50%;
    background-color: #e1e1e1;
    position: absolute;
    top: 25%;
    right: 0;
  }
  position: relative;
  padding-left: 2rem;
  padding-right: 2rem;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
    &:after {
      width: 0;
    }
  }
`;

const ColumnTitle = styled.h2`
  flex: 1;
  font-size: 0.7rem;
  margin: 0 0 5px 5px;
  font-weight: normal;
  text-transform: uppercase;
  min-height: auto;
`;

const ProgressBarContainer = styled.div`
  flex: 1;
  background: #e1e1e1;
  border-radius: 1rem;
  overflow: hidden;
  min-height: 15px;
`;

const ProgressBar = styled.span`
  background-color: ${({ tagColor }) => tagColor};
  width: ${({ width }) => width};
  display: block;
  height: 100%;
  min-height: 15px;
  border-radius: 1rem;
`;

const Percentage = styled.p`
  margin: 2px 0 0 5px;
  font-size: 0.8em;
  font-weight: bold;
  color: #333333;
`;

const ColumnSubTitle = styled.h3`
  font-size: 1rem;
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
`;

const ProfitabilityIcon = styled(FontAwesomeIcon)`
  font-size: 35px;
  ${({ isgoodmood }) => (isgoodmood ? 'color: #009245;' : 'color: #e21300;')}
`;

export default function ProfitabilitySummary({
  percentageHoursCompleted,
  currentCost,
  totalHours,
}) {
  return (
    <BoxContainer>
      <Column>
        <ColumnTitle>Porcentagem de conclusão [Horas]</ColumnTitle>
        <ProgressBarContainer>
          <ProgressBar
            tagColor="#8884d8"
            width={`${percentageHoursCompleted}%`}
          />
        </ProgressBarContainer>
        {/* eslint-disable-next-line */}
        <Percentage>{parseInt(percentageHoursCompleted)}%</Percentage>
      </Column>
      <Column align="center">
        <ColumnTitle>Valor Consumido</ColumnTitle>
        <ColumnSubTitle>{formatCurrency(currentCost)}</ColumnSubTitle>
      </Column>
      <Column align="center">
        <ColumnTitle>Total de Horas Executadas</ColumnTitle>
        <ColumnSubTitle>{totalHours} horas</ColumnSubTitle>
      </Column>
      <Column align="center">
        <ColumnTitle>Expectativa de Lucratividade</ColumnTitle>
        <ProfitabilityIcon
          icon={percentageHoursCompleted <= 100 ? faSmile : faFrownOpen}
          isgoodmood={percentageHoursCompleted <= 100}
        />
      </Column>
    </BoxContainer>
  );
}

ProfitabilitySummary.propTypes = {
  percentageHoursCompleted: PropTypes.number,
  currentCost: PropTypes.number,
  totalHours: PropTypes.number,
};
