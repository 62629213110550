import moment from 'moment';
import React, { useState } from 'react';

import { msToTime } from '~/app/utils/duration';
import useProjectsStore from '~/store/projects/projects';
import useReportsStore from '~/store/reports/reports';
import useTeamStore from '~/store/team';

import * as S from './styles';

import type { Task, Project } from '~/models/types';

const SORTING_OPTIONS = [
  { value: 'user', label: 'Por usuário' },
  { value: 'project', label: 'Por projeto' },
  { value: 'description', label: 'Por descrição' },
  { value: 'date', label: 'Por data de adição' },
  { value: 'duration', label: 'Por duração' },
];

const TableHead = () => (
  <S.THead>
    <tr>
      <td>Colaborador</td>
      <td>Projeto</td>
      <td>Descrição</td>
      <td>Data</td>
      <td>Duração</td>
    </tr>
  </S.THead>
);

const SingleTask = ({
  task,
  date,
  projectData,
  userName,
}: {
  task: Task;
  date: string;
  projectData?: Project;
  userName: string;
}) => {
  moment.locale('pt-br');
  const dateString = moment(date, 'YYYYMMDD').format('DD / MM / YYYY');
  const duration = msToTime(task.duration);
  return (
    <tr>
      <td>{userName}</td>
      <td>
        <S.ProjectNameContainer color={projectData?.tagColor || undefined}>
          <S.ProjectIcon />
          <S.ProjectName>{projectData?.name.toUpperCase()}</S.ProjectName>
        </S.ProjectNameContainer>
      </td>
      <td>{task.description}</td>
      <td>{dateString}</td>
      <td>{duration}</td>
    </tr>
  );
};

const TableBody = (order) => {
  const { projects } = useProjectsStore();
  const { teamUsers } = useTeamStore();
  const { tasks } = useReportsStore();

  const translateIdToName = (id: number): string => {
    const name = teamUsers.find((user) => user.id === id)?.name;
    if (name) return name;
    return 'Sem colaborador';
  };
  const compare = (orderToCompare) => {
    switch (orderToCompare.order) {
      case 'user':
        return (a, b) => {
          if (translateIdToName(a.userId) > translateIdToName(b.userId))
            return 1;
          if (translateIdToName(a.userId) < translateIdToName(b.userId))
            return -1;
          return 0;
        };

      case 'project':
        return (a, b) => {
          const projectA: Project | null | undefined = projects?.find(
            (proj) => proj.id === a.projectId,
          );
          const projectB: Project | null | undefined = projects?.find(
            (proj) => proj.id === b.projectId,
          );
          if (
            projectA &&
            projectB &&
            projectA?.name.toLocaleLowerCase() >
              projectB?.name.toLocaleLowerCase()
          )
            return 1;
          if (
            projectA &&
            projectB &&
            projectA?.name.toLocaleLowerCase() <
              projectB?.name.toLocaleLowerCase()
          )
            return -1;
          return 0;
        };
      case 'description':
        return (a, b) => {
          if (
            a.description?.toLocaleLowerCase() >
            b.description?.toLocaleLowerCase()
          )
            return 1;
          if (
            a.description?.toLocaleLowerCase() <
            b.description?.toLocaleLowerCase()
          )
            return -1;
          return 0;
        };
      case 'date':
        return (a, b) => {
          if (
            moment(a.dailyTasksDate, 'YYYYMMDD').toDate() >
            moment(b.dailyTasksDate, 'YYYYMMDD').toDate()
          )
            return -1;
          if (
            moment(a.dailyTasksDate, 'YYYYMMDD').toDate() <
            moment(b.dailyTasksDate, 'YYYYMMDD').toDate()
          )
            return 1;
          return 0;
        };
      case 'duration':
        return (a, b) => {
          if (msToTime(a.duration) > msToTime(b.duration)) return -1;
          if (msToTime(a.duration) < msToTime(b.duration)) return 1;
          return 0;
        };
      default:
        return () => 0;
    }
  };
  interface TaskProps extends Task {
    dailyTasksDate: string;
  }
  interface DailyTasks {
    tasks: Task[];
    // other properties
  }
  const SortedTaskList = (orderToSort, tasksToSort) => {
    const aux: TaskProps[] = [];
    Object.entries(tasksToSort).forEach(
      ([dailyTasksDate, dailyTasks]: [string, DailyTasks]) => {
        dailyTasks.tasks.forEach((task) => {
          aux.push({ ...task, dailyTasksDate });
        });
      },
    );

    return aux
      .sort(compare(orderToSort))
      .map((task) => (
        <SingleTask
          task={task}
          date={task.dailyTasksDate}
          projectData={projects?.find((proj) => proj.id === task.projectId)}
          userName={translateIdToName(task.userId)}
          key={task.id}
        />
      ));
  };

  return <S.Tbody>{SortedTaskList(order, tasks)}</S.Tbody>;
};

const TaskList = () => {
  const { tasks } = useReportsStore();
  const [order, setOrder] = useState(SORTING_OPTIONS[0]);

  const blankState = () => (
    <S.BlankStateTr>
      <td colSpan={6}>
        Até agora nenhuma tarefa foi registrada para o período, projeto e
        usuário selecionados.
      </td>
    </S.BlankStateTr>
  );

  return (
    <>
      <S.TitleContainer>
        <S.Title>Tarefas</S.Title>
        <S.SelectContainer>
          Ordem
          <S.Select
            classNamePrefix="OrderSelect"
            className="OrderSelect"
            isSearchable={false}
            onChange={(
              event: React.SetStateAction<{ value: string; label: string }>,
            ) => {
              setOrder(event);
            }}
            value={order}
            options={SORTING_OPTIONS}
            styles={{
              option: (base) => ({
                ...base,
                backgroundColor: 'white',
                color: '#5F5F5F',
                ':hover': {
                  backgroundColor: '#F6F7FB',
                },
              }),
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </S.SelectContainer>
      </S.TitleContainer>
      <S.TableContainer>
        <S.Table>
          {Object.keys(tasks).length > 0 ? (
            <>
              <TableHead />
              <TableBody order={order.value} />
            </>
          ) : (
            <S.Tbody>{blankState()}</S.Tbody>
          )}
        </S.Table>
      </S.TableContainer>
    </>
  );
};

export default TaskList;
