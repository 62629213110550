import React from 'react';

interface SVGIconProps {
  children: React.ReactNode;
  width: string;
  height: string;
  viewBox: string;
  preserveAspectRatio?: string;
  fill?: string;
}

const SVGIcon: React.FC<SVGIconProps> = ({
  children,
  width,
  height,
  viewBox,
  preserveAspectRatio,
}: SVGIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    preserveAspectRatio={preserveAspectRatio}
  >
    {children}
  </svg>
);

export default SVGIcon;
