import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { LightButton } from '~/components/Button';

import {
  BodyText,
  Title,
  Container,
  LinkButton,
  IconContainer,
  TitleContainer,
} from './styles';

const ExtensionReminderToast = () => (
  <Container>
    <TitleContainer>
      <IconContainer>
        <FontAwesomeIcon color="#4680FE" icon={faBell} />
      </IconContainer>
      <Title>Acompanhe seu andamento</Title>
    </TitleContainer>
    <BodyText>
      {' '}
      É muito mais simples trabalhar com o Labor te acompanhando.{' '}
      <span>Baixe agora</span> a extensão para o Chrome para cronometrar suas
      horas.
    </BodyText>

    <LightButton
      onClick={() => {
        window.open(
          'https://chrome.google.com/webstore/detail/labor/ljdglolfladhpiemjpmiclhhchjfgehe',
        );
        localStorage.removeItem('remindExtension');
      }}
    >
      Baixar a extensão
    </LightButton>
    <LinkButton
      onClick={() => {
        localStorage.removeItem('remindExtension');
      }}
    >
      Não exibir novamente
    </LinkButton>
  </Container>
);

export default ExtensionReminderToast;
