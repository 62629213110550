import apiFetch from '~/helpers/apiFetch';

class TrelloAPI {
  async getBoards() {
    const organizationId = this._getOrganization();
    const requestUrl = organizationId
      ? `organizations/${organizationId}/boards`
      : `members/me/boards`;
    const response = await this._sendTrelloRequest('GET', requestUrl);
    const data = await response.json();
    return response.status === 200 ? data : [];
  }

  async createBoard(name, defaultLists) {
    const organizationId = this._getOrganization();
    const requestUrl = organizationId
      ? `organizations/${organizationId}/boards`
      : `boards`;
    const response = await this._sendTrelloRequest('POST', requestUrl, {
      name,
      defaultLists,
    });
    const data = await response.json();
    return response.status === 200 ? data : [];
  }

  async createList(boardId, name, pos) {
    const response = await this._sendTrelloRequest('POST', `lists`, {
      name,
      idBoard: boardId,
      pos,
    });
    const data = await response.json();
    return response.status === 200 ? data : [];
  }

  getBoard(id, loadNestedElements) {
    let params = {};
    if (loadNestedElements) {
      params = {
        labels: 'all',
        label_fields: 'name,color',
        lists: 'open',
        list_fields: 'name',
      };
    }
    return this._sendTrelloRequest('GET', `boards/${id}`, params);
  }

  _getOrganization(): any {
    const trello = localStorage.getItem('trello');
    const parsedTrello = trello ? JSON.parse(trello) : {};
    return parsedTrello.organizationId;
  }

  async _sendTrelloRequest(method, path, queryData = {}) {
    const token = this._getToken();
    const queryAuthData = { key: TRELLO_API_KEY, token };
    const queryParams = this._toQueryParams({ ...queryData, ...queryAuthData });
    const fullUrl = `${TRELLO_API_URL}/${path}?${queryParams}`;
    return fetch(fullUrl, { method });
  }

  async getLists(boardId) {
    return this._sendTrelloRequest('GET', `boards/${boardId}/lists`);
  }

  _getToken() {
    const trello = localStorage.getItem('trello');
    const parsedTrello = trello ? JSON.parse(trello) : {};
    if (parsedTrello.token) return parsedTrello.token;
    return this._getTrelloConfiguration().token;
  }

  _getTrelloConfiguration() {
    const trello = localStorage.getItem('trello');
    return trello ? JSON.parse(trello) : {};
  }

  _toQueryParams(jsonObj) {
    // be carefull, this expects an one depth object
    return Object.entries(jsonObj)
      .map(([k, v]) => `${k}=${v}`)
      .join('&');
  }

  async createTrelloBoard(projectId, body) {
    return apiFetch(
      `/projects/${projectId}/create_trello_board`,
      'POST',
      null,
      body,
    );
  }

  deleteTrelloFromProject(projectId) {
    return apiFetch(`/projects/${projectId}/destroy_trello_board`, 'DELETE');
  }
}
export default new TrelloAPI();
