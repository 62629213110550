import apiFetch from '~/helpers/apiFetch';

export type GetTasksProps = {
  starting: string | undefined;
  ending: string | undefined;
  userIds?: number | string | null;
  projectIds?: number[] | string[] | string | number | null | Number;
  plannedTaskIds?: number[] | string[] | string | number | null | Number;
  cost?: string | boolean;
  includes?: string | null;
  page?: null | number;
  perPage?: null | number;
};

class UserAPI {
  register(
    {
      name,
      segment,
      segmentDetail,
      teamSize,
      email,
      password,
      phone,
      acceptedTerms,
    },
    codInvite = undefined,
  ) {
    // @ts-ignore
    return fetch(`${API_URL}/users`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        user: {
          name,
          email,
          password,
          password_confirmation: password,
          phone,
          accepted_terms: acceptedTerms,
        },
        team: {
          segment,
          segment_detail: segmentDetail,
          size: teamSize,
          cod_invite: codInvite,
        },
      }),
    });
  }

  completeRegister({ name, password, id }) {
    // @ts-ignore
    return fetch(`${API_URL}/users/${id}/complete_signin`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        user: {
          name,
          password,
          password_confirmation: password,
        },
      }),
    });
  }

  check_email(email) {
    // @ts-ignore
    return fetch(`${API_URL}/users/valid_email`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        'User-Email': email,
      },
    });
  }

  get_user_information() {
    const loggedUser = JSON.parse(localStorage.getItem('loggedUser') || '{}');
    return apiFetch(`/users/${loggedUser.id}`);
  }

  get_tasks({
    starting,
    ending,
    userIds,
    projectIds,
    plannedTaskIds,
    cost = 'false',
    includes = null,
    page,
    perPage,
  }: GetTasksProps) {
    const params: Record<string, unknown> = {
      cost,
      starting,
      ending,
      'includes%5B%5D': includes,
      'project_id%5B%5D': projectIds,
      'planned_task_id%5B%5D': plannedTaskIds,
      page: null,
      per_page: null,
    };
    if (userIds) params['user_id%5B%5D'] = userIds;
    if (page) params.page = page;
    if (perPage) params.per_page = perPage;

    const queryString = Object.keys(params)
      .reduce((_qs, k) => {
        if (params[k] === null || params[k] === undefined) {
          return `${_qs}`;
        }

        return `${_qs}&${k}=${params[k]}`;
      }, '')
      .substring(1);
    if (!userIds) {
      return apiFetch(`/tasks?${queryString}&includes%5B%5D=user`);
    }
    return apiFetch(`/tasks?${queryString}`);
  }

  delete_task(id) {
    return apiFetch(`/tasks/${id}`, 'DELETE');
  }

  edit_task_project(id, projectId) {
    return apiFetch(`/tasks/${id}`, 'PUT', null, {
      projectId,
      plannedTaskId: null,
    });
  }

  getAllocations(id) {
    return apiFetch(`/teams/${id}/allocations`);
  }

  edit_task_description(id, description) {
    return apiFetch(`/tasks/${id}`, 'PUT', null, { description });
  }

  edit_planned_task(id, plannedTaskId) {
    return apiFetch(`/tasks/${id}`, 'PUT', null, { plannedTaskId });
  }

  edit_task_time(id, startTime, endTime) {
    return apiFetch(`/tasks/${id}`, 'PUT', null, {
      start: startTime,
      end: endTime,
    });
  }

  change_project_allocation(
    body,
    userId: number,
    allocationId: number,
  ): Promise<Response> {
    return apiFetch(
      `/users/${userId}/project_allocations/${allocationId}`,
      'PUT',
      null,
      body,
    );
  }

  get_days_off(id: number) {
    // return user's days off remaining in hours
    return apiFetch(`/users/${id}/days_off_remaining`);
  }

  get_pdi_hours_check(id: number) {
    return apiFetch(`/users/${id}/pdi_hours_check`);
  }

  get_exceeding_hours_check(id: number) {
    return apiFetch(`/users/${id}/exceeding_hours_check`);
  }
}

export default new UserAPI();
