import { Group, Star, Person, Cancel } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';

import TeamModal from '~/components/SideBar/TeamModal';
import SubscriptionCancelConfirmModal from '~/components/SideBar/TeamModal/SubscriptionCancelConfirmModal';
import UserProfile from '~/components/SideBar/UserProfileModal';
import useTeamStore from '~/store/team';
import useUserStore from '~/store/user';

import TourButton from '../TourButton';

import * as S from './styles';

import type {
  LoginServiceType,
  UserServiceType,
  TeamServiceType,
  NotificationServiceType,
} from '~/models/ServicesTypes';
import type { RootScope, State } from '~/models/types';

const ITEM_TYPES = {
  TEAM: 'TEAM',
  USER: 'USER',
  LOGOUT: 'LOGOUT',
  SUBSCRIPTION: 'SUBSCRIPTION',
};

interface BottomMenuProps {
  UserService: UserServiceType;
  LoginService: LoginServiceType;
  TeamService: TeamServiceType;
  NotificationService: NotificationServiceType;
  $rootScope: RootScope;
  $state: State;
  setRoute: Function;
}

const BottomMenu: React.FC<BottomMenuProps> = ({
  UserService,
  LoginService,
  TeamService,
  NotificationService,
  $rootScope,
  $state,
  setRoute,
}: BottomMenuProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [teamModal, setTeamModal] = useState<boolean>(false);
  const [userProfileModal, setUserProfileModal] = useState<boolean>(false);
  const [isItemOnFocus, setItemOnFocs] = useState<string | null>(null);
  const [teamModalCancelConfirm, setTeamModalCancelConfirm] =
    useState<boolean>(false);
  const { fetchTeamData, hasIncompleteSubscription } = useTeamStore();
  const { user, fetchUserData, isLoggedUserAdmin } = useUserStore();

  useEffect(() => {
    fetchTeamData();
    fetchUserData();

    setIsLoading(false);
  }, []);

  const navigateFor = (url: string, params = {}) => {
    setRoute(url);
    $state.go(url, params);
  };

  const subscriptionLinkContent = () => {
    if (
      hasIncompleteSubscription() &&
      ($rootScope.trialSubscription || $rootScope.inactiveSubscription)
    ) {
      return (
        <S.Item
          onMouseEnter={() => setItemOnFocs(ITEM_TYPES.SUBSCRIPTION)}
          onMouseLeave={() => setItemOnFocs(null)}
          onClick={() => navigateFor('app.main.subscribe')}
        >
          <S.ItemIcon>
            <Star style={S.Icon(isItemOnFocus === ITEM_TYPES.SUBSCRIPTION)} />
          </S.ItemIcon>
          <S.ItemText>Assinar plano</S.ItemText>
        </S.Item>
      );
    }

    return null;
  };
  const adminContent = () => {
    if (isLoggedUserAdmin()) {
      return (
        <S.Item
          onMouseEnter={() => setItemOnFocs(ITEM_TYPES.TEAM)}
          onMouseLeave={() => setItemOnFocs(null)}
          onClick={() => setTeamModal(!teamModal)}
        >
          <S.ItemIcon>
            <Group style={S.Icon(isItemOnFocus === ITEM_TYPES.TEAM)} />
          </S.ItemIcon>
          <S.ItemText>Meu Time</S.ItemText>
        </S.Item>
      );
    }

    return null;
  };

  if (isLoading) return null;

  return (
    <S.Container>
      <TourButton />
      {subscriptionLinkContent()}
      {adminContent()}
      <S.Item
        onMouseEnter={() => setItemOnFocs(ITEM_TYPES.USER)}
        onMouseLeave={() => setItemOnFocs(null)}
        onClick={() => setUserProfileModal(!userProfileModal)}
      >
        <S.ItemIcon>
          <Person style={S.Icon(isItemOnFocus === ITEM_TYPES.USER)} />
        </S.ItemIcon>
        <S.ItemText>{user.name?.split(' ')[0]}</S.ItemText>
      </S.Item>
      <S.Item
        onMouseEnter={() => setItemOnFocs(ITEM_TYPES.LOGOUT)}
        onMouseLeave={() => setItemOnFocs(null)}
        onClick={() => LoginService.logout()}
      >
        <S.ItemIcon>
          <Cancel style={S.Icon(isItemOnFocus === ITEM_TYPES.LOGOUT)} />
        </S.ItemIcon>
        <S.ItemText> Logout</S.ItemText>
      </S.Item>
      <TeamModal
        isOpen={teamModal}
        setTeamModal={setTeamModal}
        TeamService={TeamService}
        NotificationService={NotificationService}
        $state={$state}
        setTeamModalCancelConfirm={setTeamModalCancelConfirm}
      />
      <UserProfile
        setUserProfileModal={setUserProfileModal}
        isOpen={userProfileModal}
        UserService={UserService}
        NotificationService={NotificationService}
      />
      <SubscriptionCancelConfirmModal
        UserService={UserService}
        NotificationService={NotificationService}
        teamModalCancelConfirm={teamModalCancelConfirm}
        setTeamModalCancelConfirm={setTeamModalCancelConfirm}
      />
    </S.Container>
  );
};

export default BottomMenu;
