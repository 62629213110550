import { Formik, Form } from 'formik';
import React, { useState, useEffect } from 'react';

import Button from '~/components/Button';
import Input from '~/components/Input';
import PasswordInput from '~/components/PasswordInput';
import { logRegister, saveLoginUserDataLocalStorage } from '~/helpers';
import API from '~/services/api';
import AmplitudeUserStore from '~/services/api/amplitude';
import Container from '~/site/components/Container';
import Page from '~/site/components/Page';
import SocialMediaSignIn from '~/site/components/SocialMediaSignIn/index';

import ErrorLink from './ErrorLink';
import Welcome from './Welcome';
import RegisterInviteSchema from './schema';
import * as S from './styles';

let amplitude;

async function doRegister(userData, codInvite) {
  const response = await API.user.register(userData, codInvite);

  const json = await response.json();

  if (response.status !== 201) {
    throw new Error(json.message);
  }

  const { user, rules } = json;

  const { headers } = response;
  localStorage.setItem('needToCompleteOnboarding', 'true');
  logRegister(headers, user, amplitude);
  saveLoginUserDataLocalStorage(user, rules, headers, null);
}

const RegisterPage = ({
  $stateParams,
  NotificationService,
  AMPLITUDE_API_KEY,
  TOS_URL,
}) => {
  const [message, setMessage] = useState('');
  const [isValidInvite, setIsValidInvite] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const renderRegisterPage = () => {
    if (!isValidInvite) {
      return <ErrorLink />;
    }
    return (
      <Page>
        <Container>
          <S.FlexWrapper>
            <Welcome />
            <S.FormBox>
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  password: '',
                  phone: '',
                  acceptedTerms: true,
                }}
                validationSchema={RegisterInviteSchema}
                onSubmit={(values, { setSubmitting }) => {
                  doRegister(values, $stateParams.codInvite).catch((e) => {
                    setMessage(e.message);
                  });
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting }) => (
                  <Form style={{ position: 'relative' }}>
                    <S.StyledSnackbar isVisible={!!message}>
                      {message}
                    </S.StyledSnackbar>
                    <>
                      <SocialMediaSignIn
                        AMPLITUDE_API_KEY={AMPLITUDE_API_KEY}
                        NotificationService={NotificationService}
                        googleText="Cadastrar com Google"
                        microsoftText="Cadastrar com Microsoft"
                        codInvite={$stateParams.codInvite}
                      />
                      <Input
                        type="text"
                        name="name"
                        placeholder="Nome completo"
                        value=""
                      />
                      <Input
                        type="text"
                        name="email"
                        placeholder="E-mail"
                        value=""
                      />
                      <PasswordInput
                        name="password"
                        placeholder="Senha"
                        value=""
                      />
                      <S.TosText>
                        Ao clicar em &quot;Cadastrar&quot; você concorda com os{' '}
                        <a target="_blank" href={TOS_URL} rel="noreferrer">
                          Termos de Serviço.
                        </a>
                      </S.TosText>
                    </>
                    <S.ButtonWrapper>
                      <Button type="submit" disabled={isSubmitting}>
                        Cadastrar
                      </Button>
                    </S.ButtonWrapper>
                  </Form>
                )}
              </Formik>
            </S.FormBox>
          </S.FlexWrapper>
        </Container>
      </Page>
    );
  };
  useEffect(() => {
    async function checkCodInvite(cod_invite) {
      setTimeout(async () => {
        const response = await API.team.check_code(cod_invite);
        setIsValidInvite(response?.status !== 403);
        setIsLoading(false);
      }, 2000);
    }

    checkCodInvite($stateParams.codInvite);
    amplitude = new AmplitudeUserStore(null, AMPLITUDE_API_KEY);
  }, []);

  if (isLoading) return null;

  return renderRegisterPage();
};
export default RegisterPage;
