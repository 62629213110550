import styled from '@emotion/styled';

import Button from '~/components/Button';
import Input from '~/components/Input';
import theme from '~/theme';


export const FormOneStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledLabel = styled.label<{ isOnFocus: boolean }>`
  font-size: 11px;
  color: ${(props) => props.isOnFocus ? theme.colors.primary : theme.colors.black2};
`;

export const StyledButton = styled(Button)`
  height: 36px;
  width: 194px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  justify-content: center;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  &:hover {
    background-color: ${theme.colors.primary};
  }
`;

export const StyledInput = styled(Input)`
  height: 2px;
  border-width: 0 0 1px;
  padding: 14px 20px 14px 0;

  &:focus {
    border-color: ${theme.colors.primary};
  }
`;

export const InputWrapper = styled.div<{ fullWidth?: boolean }>`
  width: ${(props) => props.fullWidth ? '100%' : '48%'};
`;
