import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useState } from 'react';

import UserCard from '~/app/ProjectsPage/components/NewProjectModal/UserCard';
import SearchableSelect from '~/app/components/Select/SearchableSelect';
import useTeamStore from '~/store/team';

import {
  DataContainer,
  InputBox,
  ShowMoreButton,
  TeamIndicator,
  TeamText,
  UserCardsContainer,
  GrayText,
} from '../NewProjectModal/styles';

import type {
  Item,
} from '~/app/components/Select/SearchableSelect';

type Props = {
  onChange: (
    field: string,
    value: number[] | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  name: string;
  values: number[] | undefined; 
};

const SelectTeam = ({ onChange, name }: Props) => {
  const { teamUsers, fetchTeamUsersData } = useTeamStore();
  const [users, setUsers] = useState<Item[]>([]);
  const [showMore, setShowMore] = useState(false);

  const selectedUsers = users.filter((item) => item.checked);
  const selectedUsersIds = selectedUsers.map((item) => item.id);

  const handleFetch = async () => {
    await fetchTeamUsersData();
  };

  useEffect(() => {
    handleFetch();
  }, []);

  useEffect(() => {
    if (teamUsers) {
      setUsers(
        teamUsers.map((item) => ({
          ...item,
          checked: item.enabled,
        })),
      );
    }
  }, [teamUsers]);

  // Sync selected users with the form
  useEffect(() => {
    onChange(name, selectedUsersIds);
  }, [users]);

  const calculateAlocated = () => {
    const len = selectedUsersIds.length;
    if (len === 0) {
      return ['Nenhum', 'membro alocado'];
    }
    if (len === 1) {
      return ['1', 'membro alocado'];
    }
    if (len === users.length) {
      return ['Todos', 'os membros alocados'];
    }
    return [String(len), 'membros alocados'];
  };

  const allocated = calculateAlocated();

  const cardCallback = (index) => {
    const updatedSelected = users.map((user, i) => ({
      ...user,
      checked: index === i ? false : user.checked,
    }));
    setUsers(updatedSelected);
  };

  const cards = users.reduce((acc: React.ReactNode[], curr, index) => {
    if (curr.checked)
      acc.push(
        <UserCard
          key={curr.id}
          title={curr.name || 'Usuário sem nome'}
          userId={index}
          userListCallback={cardCallback}
        />,
      );
    return acc;
  }, []);

  const getInitalCards = cards.slice(0, 8);

  const getMoreCards = cards.slice(8);

  const handleMoreButton = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <DataContainer style={{ marginBottom: '32px' }}>
        <SearchableSelect
          data={users}
          setData={(data) => setUsers(data)}
          searchPlaceholder="Pesquisar membros"
          CustomAnchorElement={
            <InputBox style={{ justifyContent: 'space-between' }}>
              <GrayText>Selecione os membros</GrayText>
              <KeyboardArrowDownIcon
                sx={{
                  verticalAlign: 'bottom',
                  bottom: '22px',
                  color: '#959595',
                  marginRight: '14px',
                }}
              />
            </InputBox>
          }
        />

        <TeamIndicator>
          <TeamText>
            <strong>{allocated[0]}</strong> {allocated[1]}
          </TeamText>
          <TeamText>Total de membros na empresa: {users.length}</TeamText>
        </TeamIndicator>
      </DataContainer>

      <UserCardsContainer>{getInitalCards}</UserCardsContainer>
      {getMoreCards.length > 0 && (
        <ShowMoreButton onClick={handleMoreButton}>
          {showMore ? 'MOSTRAR MENOS' : 'MOSTRAR MAIS'}
        </ShowMoreButton>
      )}
      {showMore && <UserCardsContainer>{getMoreCards}</UserCardsContainer>}
    </>
  );
};

export default SelectTeam;
