import React from 'react';

import ErrorPage from '~/components/ErrorPage';
import { LaborLogoSvg } from '~/icons';
import * as FS from '~/site/RegisterInvitedPage/styles';
import PageTitle from '~/site/components/PageTitle';

import * as S from './styles';

interface ErrorLinkProps {}

const ErrorLink: React.FC<ErrorLinkProps> = () => (
  <ErrorPage>
    <S.HomePageLink href={SITE_URL} callToAction>
      <LaborLogoSvg width="137" height="40" />
    </S.HomePageLink>
    <PageTitle>Algo deu errado!</PageTitle>
    <FS.WelcomeText>
      O link que você tentou acessar parece estar incorreto. Verifique o convite
      que você recebeu e tente novamente.
    </FS.WelcomeText>
    <FS.WelcomeText>
      Se o problema continuar acontecendo, entre em contato com o (a)
      administrador (a) do seu time.
    </FS.WelcomeText>
  </ErrorPage>
);

export default ErrorLink;
