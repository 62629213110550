import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

import DateInput from '../DateInput';

import DatePicker from './DatePicker';
import MonthPicker from './MonthPicker';

const DateInputContainer = styled.div`
  margin-bottom: 20px;
`;

export default function CalendarPicker({
  mode = 'startDate',
  month,
  onChangeMonth,
  startDate,
  onChangeStartDate,
  endDate,
  onChangeEndDate,
}) {
  return (
    <>
      <DateInputContainer>
        {mode === 'startDate' ? (
          <DateInput
            label="Data de Início"
            date={startDate}
            onChange={onChangeStartDate}
          />
        ) : (
          <DateInput
            label="Data de Fim"
            date={endDate}
            onChange={onChangeEndDate}
          />
        )}
      </DateInputContainer>
      <MonthPicker month={month} onChange={onChangeMonth} />
      <DatePicker
        month={month}
        startDate={startDate}
        onChangeStartDate={onChangeStartDate}
        endDate={endDate}
        onChangeEndDate={onChangeEndDate}
      />
    </>
  );
}

CalendarPicker.propTypes = {
  mode: PropTypes.string,
  month: PropTypes.instanceOf(Date).isRequired,
  onChangeMonth: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  onChangeStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date),
  onChangeEndDate: PropTypes.func.isRequired,
};

CalendarPicker.defaultPropTypes = {
  mode: 'startDate',
};
