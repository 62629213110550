import AuthAPI from './auth';
import ProjectAPI from './project';
import ReportAPI from './report';
import TeamAPI from './team';
import TrelloAPI from './trello';
import UserAPI from './user';

// new classes
export * from './api';
export * from './project/projectService';
export * from './user/userService';
export * from './allocation/allocationService';
export * from './team/teamService';
export * from './report/reportService';
export * from './login/loginService';

class API {
  auth = AuthAPI;

  user = UserAPI;

  team = TeamAPI;

  report = ReportAPI;

  project = ProjectAPI;

  trello = TrelloAPI;
}

export default new API();
