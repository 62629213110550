import styled from '@emotion/styled';
import React, { useState } from 'react';

import Input from '../Input';

import visibilityImg from './visibility.svg';
import visibilityOffImg from './visibility_off.svg';

const PasswordInputWrapper = styled.div`
  position: relative;
`;

const EyeButton = styled.span`
  position: absolute;
  top: 18px;
  right: 20px;
  cursor: pointer;
`;

export default function PasswordInput(props) {
  const [type, setType] = useState('password');

  function toggleInputType() {
    setType(type === 'password' ? 'text' : 'password');
  }

  return (
    <PasswordInputWrapper>
      <Input {...props} type={type} />
      <EyeButton onClick={toggleInputType}>
        <img
          src={type === 'password' ? visibilityImg : visibilityOffImg}
          style={{ width: '18px' }}
        />
      </EyeButton>
    </PasswordInputWrapper>
  );
}
