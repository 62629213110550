import templateUrl from './reports.html';
import './reports.scss';
import projectsPopoverHtml from './projects.html';
import plannedTasksPopoverHtml from './planned-tasks.html';
import usersPopoverHtml from './users.html';
import periodPopoverHtml from './period.html';
import downloadPopoverHtml from './download.html'
import AmplitudeUserStore from '~/services/api/amplitude';

function dateToString(date, type = 'iso') {
  const pad = n => n.toString().padStart(2, '0');

  switch (type) {
    case 'iso':
      return [
        date.getFullYear(),
        pad(date.getMonth() + 1),
        pad(date.getDate()),
      ].join('-');
    case 'brazilian':
      return [
        pad(date.getDate()),
        pad(date.getMonth() + 1),
        date.getFullYear(),
      ].join('/');
  }
}

export const ReportsComponent = {
  template: templateUrl,
  controller: class ReportsController {
    constructor(
      $log,
      $scope,
      $filter,
      $document,
      $window,
      $mdDialog,
      ReportService,
      TaskService,
      UserService,
      TeamService,
      AllocationService,
      PlannedTaskService,
      ProjectService,
      NotificationService,
      UtilsService,
      PopoverService,
      NEW_REPORTS_LIST_ACTIVE,
      AMPLITUDE_API_KEY
    ) {
      'ngInject';

      this.$log = $log;
      this.$filter = $filter;
      this.$mdDialog = $mdDialog;
      this.$document = $document;
      this.$scope = $scope;
      this.$window = $window;
      this.ReportService = ReportService;
      this.TaskService = TaskService;
      this.UserService = UserService;
      this.TeamService = TeamService;
      this.AllocationService = AllocationService;
      this.ProjectService = ProjectService;
      this.PlannedTaskService = PlannedTaskService;
      this.NotificationService = NotificationService;
      this.UtilsService = UtilsService;
      this.PopoverService = PopoverService;
      this.NEW_REPORTS_LIST_ACTIVE = NEW_REPORTS_LIST_ACTIVE;
      this.AMPLITUDE_API_KEY = AMPLITUDE_API_KEY;
    }

    $onInit() {
      this.page = 1;
      this.tasks = [];
      this.teams = [];
      this.users = [];
      this.projects = [];
      this.plannedTasks = [];
      this.reports = [];
      this.monthlyHours = 0;
      this.loggedUser = this.UserService.getLoggedUser();
      this.totalAmount = 0;
      this.disableSaveReport = false;
      this.orderByList = [
        { id: 'start', text: 'Ordenação por Data' },
        { id: 'projectId', text: 'Ordenação por Projeto' },
      ];
      this.orderBy = this.orderByList[0];
      // Enable amount only if is Novatics (team_id = 1)
      this.enableAmount = this.loggedUser.allocations.some(
        allocation => allocation.team.id == 1,
      );
      this.selectAllProjects = true;
      this.selectAllPlannedTasks = true;
      this.selectAllUsers = false;

      this.chart = null;

      const startMonth = new Date();
      startMonth.setDate(1);
      startMonth.setHours(0, 0, 0, 0);

      const endMonth = new Date(startMonth.getTime());
      endMonth.setMonth(endMonth.getMonth() + 1);
      endMonth.setDate(0);

      this.starting = startMonth;
      this.ending = endMonth;

      this._loadData();
    }

    openDownload(e) {
      this.PopoverService.open({
        event: e,
        scope: this.$scope.$new(),
        template: downloadPopoverHtml,
        style: {
          width: 180
        },
      });
    }

    openProjects(e) {
      const selectedProjectIds = () =>
        this.projects.filter(p => p.checked).map(p => p.id);
      const idsBefore = selectedProjectIds();
      this.projectStatus = 'running';

      this.PopoverService.open({
        event: e,
        scope: this.$scope.$new(),
        template: projectsPopoverHtml,
        style: {
          width: 345,
        },
      });
      const listener = this.$scope.$on('lb-popover-closed', scope => {
        const idsAfter = selectedProjectIds();

        if (
          idsBefore.length != idsAfter.length ||
          idsBefore.some((value, index) => value !== idsAfter[index])
        ) {
          if (idsAfter.length == 1) {
            this.PlannedTaskService.getPlannedTasks(idsAfter[0]).then(
              response => {
                this.plannedTasks = response.data;
                this.selectAllPlannedTasks = true;
                this.plannedTasks.forEach(p => (p.checked = true));
              },
            );
          } else {
            this.plannedTasks = [];
          }

          this.page = 1;
          this.tasks = [];
          this.getMontlyTasks();
        }

        listener();
      });
    }

    openPlannedTasks(e) {
      const selectedPlannedTaskIds = () =>
        this.plannedTasks.filter(p => p.checked).map(p => p.id);
      const idsBefore = selectedPlannedTaskIds();

      this.PopoverService.open({
        event: e,
        scope: this.$scope.$new(),
        template: plannedTasksPopoverHtml,
        style: {
          width: 300,
        },
      });
      const listener = this.$scope.$on('lb-popover-closed', scope => {
        const idsAfter = selectedPlannedTaskIds();
        if (
          idsBefore.length != idsAfter.length ||
          !idsBefore.every((value, index) => value === idsAfter[index])
        ) {
          this.page = 1;
          this.tasks = [];
          this.getMontlyTasks();
        }
        listener();
      });
    }

    openUsers(e) {
      const selectedUserIds = () =>
        this.users.filter(p => p.checked).map(p => p.id);
      const idsBefore = selectedUserIds();

      this.PopoverService.open({
        event: e,
        scope: this.$scope.$new(),
        template: usersPopoverHtml,
        style: {
          width: 300,
        },
      });
      const listener = this.$scope.$on('lb-popover-closed', scope => {
        const idsAfter = selectedUserIds();
        if (
          idsBefore.length != idsAfter.length ||
          !idsBefore.every((value, index) => value === idsAfter[index])
        ) {
          this.page = 1;
          this.tasks = [];
          this.getMontlyTasks();
        }
        listener();
      });
    }

    openPeriod(e) {
      const startingBefore =
        this.starting == null ? null : new Date(this.starting);
      const endingBefore = this.ending == null ? null : new Date(this.ending);

      this.PopoverService.open({
        event: e,
        scope: this.$scope.$new(),
        template: periodPopoverHtml,
        style: {
          width: 750,
        },
      });
      const listener = this.$scope.$on('lb-popover-closed', scope => {
        const someNull =
          startingBefore == null ||
          this.starting == null ||
          endingBefore == null ||
          this.ending == null;
        const isDifferent =
          someNull &&
          (startingBefore != this.starting || endingBefore != this.ending);

        const everyNotNull =
          startingBefore != null &&
          this.starting != null &&
          endingBefore != null &&
          this.ending != null;
        const isDifferentTime =
          everyNotNull &&
          (startingBefore.getTime() != this.starting.getTime() ||
            endingBefore.getTime() != this.ending.getTime());

        if (isDifferent || isDifferentTime) {
          this.page = 1;
          this.tasks = [];
          this.getMontlyTasks();
        }
        listener();
      });
    }

    _loadData() {
      this.TeamService.getTeams()
        .then(response => {
          this.teams = response.data;
          this.team = this.teams[0];

          return this.teams;
        })
        .then(response => {
          return this.reloadProjectsAndUsers();
        })
        .then(response => {
          return this.getReports();
        });
    }

    getProjectStatusLabel(group) {
      switch (group) {
        case 'not_started':
          return 'Não iniciados';
        case 'running':
          return 'Iniciados';
        case 'finished':
          return 'Finalizados';
        default:
          return 'Todos';
      }
    }

    reloadProjectsAndUsers() {
      return this.ProjectService.getAllProjects(this.team, true)
        .then(response => {
          this.projects = response.data.map(p => {
            p.checked = false;
            return p;
          });
          this.toggleAllProjects();
        })
        .then(() => {
          return this.AllocationService.getAllocations(this.team).then(
            response => {
              this.users = response.data
                .map(a => a.user)
                .map(u => {
                  u.checked = u.id === this.loggedUser.id;
                  return u;
                });
              this.selectAllUsers = false;
            },
          );
        })
        .then(() => {
          this.page = 1;
          this.tasks = [];
          return this.getMontlyTasks();
        });
    }

    getReports() {
      return this.ReportService.getReports(this.team).then(response => {
        this.reports = response.data;
        this.disableSaveReport =
          this._testReportExists() || !this._testFullMonthSelected();
      });
    }

    buildTasksChart(projectsData, totalAmount, monthlyHours) {
      const chartLabels = [];
      const chartColors = [];
      const chartData = [];
      const chartBorderColors = [];
      Object.values(projectsData).forEach(projectData => {
        chartLabels.push(projectData.project.name);
        chartColors.push(projectData.project.tagColor);
        chartBorderColors.push(
          this.$filter('hexToRgba')(projectData.project.tagColor, 0.02),
        );
        chartData.push(projectData.duration);
      });

      this.chartConfig = {
        type: 'doughnut',
        data: {
          labels: chartLabels,
          datasets: [
            {
              backgroundColor: chartColors,
              borderColor: chartBorderColors,
              hoverBackgroundColor: chartColors,
              data: chartData,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
          hover: {
            onHover: (event, chartElement) => {
              const toggleHoverTr = index => {
                const trElements = this.$window.document.querySelectorAll(
                  'tr[id]',
                );
                Array.prototype.forEach.call(trElements, el => {
                  el.classList.remove('hover');
                });

                if (angular.isDefined(index)) {
                  const tr = this.$window.document.querySelector(
                    `tr[id="project-${chartElement_._index}"]`,
                  );
                  tr.className += ' hover';
                }
              };

              const setCenterChartText = text => {
                this.chartConfig.options.elements.center.text = text;
              };

              const alphaOthersIndexes = index => {
                const bgColors = this.chartConfig.data.datasets[0]
                  .backgroundColor;
                Object.keys(bgColors).forEach(key => {
                  if (index == key || !bgColors[index]) {
                    bgColors[key] = this.$filter('hexToRgba')(
                      bgColors[key],
                      1,
                    );
                  } else {
                    bgColors[key] = this.$filter('hexToRgba')(
                      bgColors[key],
                      0.4,
                    );
                  }
                });
              };

              let chartElement_ = chartElement[0];
              if (chartElement_) {
                this.chart = chartElement_._chart;
                toggleHoverTr(chartElement_._index);
                alphaOthersIndexes(chartElement_._index);
                setCenterChartText(
                  this.$filter('msToTime')(
                    this.chartConfig.data.datasets[0].data[
                    chartElement_._index
                    ],
                  ),
                );
              } else {
                toggleHoverTr();
                alphaOthersIndexes(null);
                setCenterChartText('');
              }

              if (this.chart) {
                this.chart.update(0, false);
              }
            },
          },
          elements: {
            center: {
              text: '',
              fontColor: '#7d7d7d',
              fontFamily: '"Open Sans", sans-serif',
              fontSize: 14,
              fontStyle: 'bold',
            },
          },
          legend: {
            display: false,
          },
        },
      };
    }

    tasksFilterParams() {
      const selectedProjects = this.selectAllProjects
        ? null
        : this.projects.filter(p => p.checked).map(p => p.id);
      const selectedUsers = this.selectAllUsers
        ? null
        : this.users.filter(u => u.checked).map(u => u.id);
      const selectedPlannedTasks = this.selectAllPlannedTasks
        ? null
        : this.plannedTasks.filter(p => p.checked).map(p => p.id);

      const ending = new Date(this.ending);
      ending.setDate(ending.getDate())

      return {
        starting: this.starting,
        ending,
        selectedUsers,
        selectedProjects,
        selectedPlannedTasks
      }
    }

    oldGetMontlyTasks() {
      const selectedProjects = this.selectAllProjects
        ? null
        : this.projects.filter(p => p.checked).map(p => p.id);
      const selectedUsers = this.selectAllUsers
        ? null
        : this.users.filter(u => u.checked).map(u => u.id);
      const selectedPlannedTasks = this.selectAllPlannedTasks
        ? null
        : this.plannedTasks.filter(p => p.checked).map(p => p.id);

      const ending = new Date(this.ending);
      ending.setDate(ending.getDate())

      this.TaskService.getTasks(
        this.starting,
        ending,
        selectedUsers,
        selectedProjects,
        selectedPlannedTasks,
        true,
        ['user', 'planned_task'],
      ).then(response => {
        this.chart = null;
        const groupedTasks = response.data;
        const projectsData = {};
        this.monthlyHours = 0;
        this.tasks = [];

        let totalAmount = 0;
        Object.values(groupedTasks).forEach(taskGroup => {
          taskGroup.tasks.forEach(task => {
            const projectId = task.project ? task.project.id : 0;
            if (!Object.keys(projectsData).includes(`${projectId}`)) {
              projectsData[projectId] = {
                project:
                  projectId != 0
                    ? task.project
                    : { name: 'Sem projeto', tagColor: '#959595' },
                duration: 0,
              };
            }

            projectsData[projectId].duration += task.duration;
          });
          this.monthlyHours += taskGroup.duration;
          totalAmount += parseFloat(taskGroup.cost);
        });

        const chartLabels = [];
        const chartColors = [];
        const chartData = [];
        const chartBorderColors = [];
        Object.values(projectsData).forEach(projectData => {
          chartLabels.push(projectData.project.name);
          chartColors.push(projectData.project.tagColor);
          chartBorderColors.push(
            this.$filter('hexToRgba')(projectData.project.tagColor, 0.02),
          );
          chartData.push(projectData.duration);
        });

        this.chartConfig = {
          type: 'doughnut',
          data: {
            labels: chartLabels,
            datasets: [
              {
                backgroundColor: chartColors,
                borderColor: chartBorderColors,
                hoverBackgroundColor: chartColors,
                data: chartData,
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
            hover: {
              onHover: (event, chartElement) => {
                const toggleHoverTr = index => {
                  const trElements = this.$window.document.querySelectorAll(
                    'tr[id]',
                  );
                  Array.prototype.forEach.call(trElements, el => {
                    el.classList.remove('hover');
                  });

                  if (angular.isDefined(index)) {
                    const tr = this.$window.document.querySelector(
                      `tr[id="project-${chartElement_._index}"]`,
                    );
                    tr.className += ' hover';
                  }
                };

                const setCenterChartText = text => {
                  this.chartConfig.options.elements.center.text = text;
                };

                const alphaOthersIndexes = index => {
                  const bgColors = this.chartConfig.data.datasets[0]
                    .backgroundColor;
                  Object.keys(bgColors).forEach(key => {
                    if (index == key || !bgColors[index]) {
                      bgColors[key] = this.$filter('hexToRgba')(
                        bgColors[key],
                        1,
                      );
                    } else {
                      bgColors[key] = this.$filter('hexToRgba')(
                        bgColors[key],
                        0.4,
                      );
                    }
                  });
                };

                let chartElement_ = chartElement[0];
                if (chartElement_) {
                  this.chart = chartElement_._chart;
                  toggleHoverTr(chartElement_._index);
                  alphaOthersIndexes(chartElement_._index);
                  setCenterChartText(
                    this.$filter('msToTime')(
                      this.chartConfig.data.datasets[0].data[
                      chartElement_._index
                      ],
                    ),
                  );
                } else {
                  toggleHoverTr();
                  alphaOthersIndexes(null);
                  setCenterChartText('');
                }

                if (this.chart) {
                  this.chart.update(0, false);
                }
              },
            },
            elements: {
              center: {
                text: '',
                fontColor: '#7d7d7d',
                fontFamily: '"Open Sans", sans-serif',
                fontSize: 14,
                fontStyle: 'bold',
              },
            },
            legend: {
              display: false,
            },
          },
        };

        for (const index in groupedTasks) {
          this.tasks = this.tasks.concat(groupedTasks[index].tasks);
        }

        // Atualiza o valor total
        this.totalAmount = totalAmount;

        // Testa se o report já existe do mês selecionado
        this.disableSaveReport =
          this._testReportExists() || !this._testFullMonthSelected();
      });
    }


    getMontlyTasks() {
      if (process.env.NEW_REPORTS_LIST_ACTIVE !== 'active') {
        return this.oldGetMontlyTasks();
      }

      const filterParams = this.tasksFilterParams()

      this
        .TaskService
        .reportsGraphData(
          filterParams.starting,
          filterParams.ending,
          filterParams.selectedUsers,
          filterParams.selectedProjects,
          filterParams.selectedPlannedTasks,
          true,
        )
        .then((res) => {
          this.buildTasksChart(
            res.data.projects,
            res.data.totalAmount,
            res.data.monthlyHours
          )

          this.totalAmount = res.data.totalAmount;
          this.disableSaveReport = this.isReportAlreadySubmited()
        })

      this.loadTasksList();
    }

    isReportAlreadySubmited() {
      return this._testReportExists() || !this._testFullMonthSelected();
    }

    loadTasksList() {
      const filterParams = this.tasksFilterParams()

      this.TaskService.getTasks(
        filterParams.starting,
        filterParams.ending,
        filterParams.selectedUsers,
        filterParams.selectedProjects,
        filterParams.selectedPlannedTasks,
        true,
        ['user', 'planned_task'],
        this.page,
        10
      ).then(response => {
        const groupedTasks = response.data;
        delete groupedTasks["totalPages"];

        for (const index in groupedTasks) {
          this.tasks = this.tasks.concat(groupedTasks[index].tasks);
        }

        this.page += 1;
      });
    }

    getSelectedUser() {
      if (this.users.find instanceof Function) {
        return this.users.find(user => {
          return user.id === this.user.id;
        });
      }
      return null;
    }

    download(reportOrientation = 'Portrait', format = 'pdf') {
      const { starting } = this;
      const { ending } = this;
      const selectedUsers = this.selectAllUsers
        ? null
        : this.users.filter(u => u.checked);
      const selectedProjects = this.selectAllProjects
        ? null
        : this.projects.filter(p => p.checked);
      const selectedPlannedTasks = this.selectAllPlannedTasks
        ? null
        : this.plannedTasks.filter(p => p.checked);
      const amplitude = new AmplitudeUserStore(null, AMPLITUDE_API_KEY);
      amplitude.sendData('Download Report Click', {
        reportOrientation: reportOrientation,
        selectedPlannedTasks: selectedPlannedTasks,
        selectedProjects: selectedProjects,
        selectedUsers: selectedUsers,
        format: format
      });

      const saveData = response => {
        this.$log.log({ response });
        const blob = new Blob([response.data], { format: `${format == 'csv' ? "text/csv" : "application/pdf"}` });
        this.$log.log({ blob });
        const parts = [`Relatório Labor de ${dateToString(new Date(), 'iso')}`];

        if (starting && ending) {
          parts.push(
            `Período de ${dateToString(starting, 'iso')} a ${dateToString(
              ending,
              'iso',
            )}`,
          );
        }
        this.$log.log({ parts });

        if (selectedUsers && selectedUsers.length < this.users.length) {
          const userNames = selectedUsers.map(p => p.name);
          parts.push(`Usuários ${userNames.join(', ')}`);
        }

        if (
          selectedProjects &&
          selectedProjects.length < this.projects.length
        ) {
          const projectNames = selectedProjects.map(p => p.name);
          parts.push(`Projetos ${projectNames.join(', ')}`);
        }

        if (
          selectedPlannedTasks &&
          selectedPlannedTasks.length < this.plannedTasks.length
        ) {
          const plannedTaskDescriptions = selectedPlannedTasks.map(
            p => p.description,
          );
          parts.push(`Tarefas ${plannedTaskDescriptions.join(', ')}`);
        }

        const a = angular.element('<a/>');
        this.$document
          .find('body')
          .eq(0)
          .append(a);
        const url = this.$window.URL.createObjectURL(blob);
        a.attr({
          style: 'display: none',
          href: url,
          download: `${parts.join(' - ')}.${format}`,
        });
        a[0].click();
        this.$window.URL.revokeObjectURL(url);
      };

      const userIds = selectedUsers ? selectedUsers.map(u => u.id) : null;
      const projectIds = selectedProjects
        ? selectedProjects.map(u => u.id)
        : null;
      const plannedTaskIds = selectedPlannedTasks
        ? selectedPlannedTasks.map(u => u.id)
        : null;

      this.TaskService.download(
        starting,
        ending,
        userIds,
        projectIds,
        plannedTaskIds,
        reportOrientation,
        format
      ).then(
        response => {
          saveData(response);
          amplitude.sendData(`${format} Download Succeeded`, {
            starting: starting,
            ending: ending,
            userIds: userIds,
            projectIds: projectIds,
            plannedTaskIds: plannedTaskIds,
            reportOrientation: reportOrientation,
            format: format
          });
        },
        error => {
          this.$log.log('error', error);
          amplitude.sendData(`${format} Download Failed`, {
            error: error,
            starting: starting,
            ending: ending,
            userIds: userIds,
            projectIds: projectIds,
            plannedTaskIds: plannedTaskIds,
            reportOrientation: reportOrientation,
            format: format
          });
        },
      );
    }

    saveReport() {
      const month = this.starting.getMonth();
      const year = this.starting.getFullYear();
      const confirm = this.$mdDialog
        .confirm()
        .htmlContent(
          `<div class="save-report">
                        <div class="md-title">
                          <i class="material-icons save-report-icon">content_copy</i>
                          ${this.loggedUser.name}_${month + 1}_${year}
                        </div><br><br>
                        Você deseja enviar este relatório para <strong>pagamentos</strong>?
                      </div>`,
        )
        .ariaLabel('Relatório de Horas')
        .ok('Enviar')
        .cancel('close');

      this.$mdDialog.show(confirm).then(() => {
        this.ReportService.saveReport(this.team, year, month)
          .then(response => {
            this.reports.push(response.data);
            this.disableSaveReport =
              this._testReportExists() || !this._testFullMonthSelected();
            this.NotificationService.showNotification(
              'Relatório enviado com sucesso!',
            );
          })
          .catch(error => {
            this.NotificationService.showNotification(
              error.data.message,
              'error',
            );
          });
      });
    }

    toggleAllProjects() {
      this.projects = this.projects.map(p => {
        p.checked = this.selectAllProjects;
        return p;
      });
    }

    projectChanged() {
      const selectedProjects = this.projects
        .filter(p => p.checked)
        .map(p => p.id);
      const allSelected = selectedProjects.length === this.projects.length;

      this.selectAllProjects = allSelected;
    }

    selectedProjectsLabel() {
      const selectedCount = this.projects.filter(p => p.checked).length;

      if (selectedCount === this.projects.length || selectedCount == 0) {
        return 'Todos Projetos';
      }
      if (selectedCount > 1) {
        return 'Múltiplos Projetos';
      }
      if (selectedCount == 1) {
        return this.projects.find(p => p.checked).name;
      }
    }

    selectedPlannedTasksLabel() {
      const selectedCount = this.plannedTasks.filter(p => p.checked).length;

      if (selectedCount === this.plannedTasks.length || selectedCount == 0) {
        return 'Todas Tarefas';
      }
      if (selectedCount > 1) {
        return 'Múltiplas Tarefas';
      }
      if (selectedCount == 1) {
        return this.plannedTasks.find(p => p.checked).description;
      }
    }

    toggleAllPlannedTasks() {
      this.plannedTasks = this.plannedTasks.map(p => {
        p.checked = !this.selectAllPlannedTasks;
        return p;
      });
    }

    plannedTaskChanged() {
      const allSelected = this.plannedTasks.every(p => p.checked);

      this.selectAllPlannedTasks = allSelected;
    }

    toggleAllUsers() {
      this.users = this.users.map(p => {
        p.checked = !this.selectAllUsers;
        return p;
      });
    }

    userChanged() {
      const allSelected = this.users.every(p => p.checked);

      this.selectAllUsers = allSelected;
    }

    selectedUsersLabel() {
      const selectedCount = this.users.filter(p => p.checked).length;

      if (selectedCount === this.users.length || selectedCount == 0) {
        return 'Todos Colaboradores';
      }
      if (selectedCount > 1) {
        return 'Múltiplos Colaboradores';
      }
      if (selectedCount == 1) {
        return this.users.find(p => p.checked).name;
      }
    }

    selectedPeriodLabel() {
      if (!this.starting || !this.ending) return 'Todo Período';

      return `${dateToString(this.starting, 'brazilian')} - ${dateToString(
        this.ending,
        'brazilian',
      )}`;
    }

    selectPeriod(type) {
      let starting = null;
      let ending = null;
      const today = new Date();

      switch (type) {
        case 'whole':
          starting = null;
          ending = null;
          break;
        case 'last-7-days':
          starting = new Date(today);
          starting.setDate(today.getDate() - 6);
          ending = new Date(today);

          break;
        case 'month':
          starting = new Date(today.getFullYear(), today.getMonth(), 1);
          ending = new Date(today.getFullYear(), today.getMonth() + 1, 0);

          break;
        case 'last-month':
          starting = new Date(today.getFullYear(), today.getMonth() - 1, 1);
          ending = new Date(today.getFullYear(), today.getMonth(), 0);

          break;
        case 'last-3-months':
          starting = new Date(
            today.getFullYear(),
            today.getMonth() - 3,
            today.getDate(),
          );
          ending = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
          );

          break;
        case 'last-6-months':
          starting = new Date(
            today.getFullYear(),
            today.getMonth() - 6,
            today.getDate(),
          );
          ending = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
          );

          break;
        case 'year':
          starting = new Date(today.getFullYear(), 0, 1);
          ending = new Date(today.getFullYear() + 1, 0, 0);

          break;
        case 'last-year':
          starting = new Date(today.getFullYear() - 1, 0, 1);
          ending = new Date(today.getFullYear(), 0, 0);

          break;
      }

      if (starting && ending) {
        starting.setHours(0, 0, 0, 0);
        ending.setHours(0, 0, 0, 0);
      }

      this.starting = starting;
      this.ending = ending;

      this.PopoverService.close();
    }

    _testReportExists() {
      if (!this.starting) return false;

      return this.reports.some(report => {
        const referenceDate = new Date(report.referenceDate);
        return (
          referenceDate.getMonth() === this.starting.getMonth() &&
          referenceDate.getFullYear() === this.starting.getFullYear() &&
          report.user.id == this.loggedUser.id
        );
      });
    }

    _testFullMonthSelected() {
      const sameMonth =
        this.starting.getFullYear() === this.ending.getFullYear() &&
        this.starting.getMonth() === this.ending.getMonth();
      const isFirstMonthDate = this.starting.getDate() === 1;
      const lastMonthDate = new Date(this.starting);
      lastMonthDate.setMonth(lastMonthDate.getMonth() + 1);
      lastMonthDate.setDate(0);
      const isLastMonthDate = lastMonthDate.getDate() === this.ending.getDate();

      return sameMonth && isFirstMonthDate && isLastMonthDate;
    }
  },
};
