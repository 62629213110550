import React, { useContext } from 'react';


import { Popover } from '../../components/Popover';
import CalendarContext from '../CalendarContext';

import DateRangePicker from './DateRangePicker';

import type { PopoverStateReturn } from 'reakit/ts';

const PeriodPopover = (props: PopoverStateReturn) => {
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(CalendarContext);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Popover {...props} aria-label="Período de tempo">
      <DateRangePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        // eslint-disable-next-line react/destructuring-assignment
        hide={props.hide}
      />
    </Popover>
  );
};

export default PeriodPopover;
