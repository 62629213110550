import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 60px;
  font-size: 20px;
  position: absolute;
  top: 67px;
`;

export const IconLabel = styled.p`
  height: 24px;
  margin-left: 10px;
  font-size: 10px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const StepBackWrapper = ({ children, label }) => (
  <Wrapper>
    {children}
    {label ? <IconLabel>{label}</IconLabel> : <></>}
  </Wrapper>
);

StepBackWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
};

StepBackWrapper.defaultProps = {
  label: '',
};
