import { Tooltip } from '@mui/material';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { msToTimeString } from '~/app/utils/duration';


import * as S from './styles';

import type api from '~/services/api';
import type { TaskReplayParams } from '~/store/replayTask';

export type DragableRowProps = {
  task: any;
  index: any;
  moveRow(dragIndex: number, hoverIndex: number): any;
  api: typeof api;
  plannedTasks: object[];
  projectId: number;
  handleEditTask(task: { id: number }): Promise<void>;
  handleAddTask(task: Object, index: any): Promise<void>;
  setReplayTask(params: TaskReplayParams): void;
  setTaskToDelete: React.Dispatch<React.SetStateAction<number>>;
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DragableRow: React.FC<DragableRowProps> = ({
  task,
  index,
  moveRow,
  api,
  plannedTasks,
  projectId,
  handleEditTask,
  handleAddTask,
  setReplayTask,
  setTaskToDelete,
  setOpenDeleteModal,
}) => {
  const dropRef = React.useRef<HTMLTableRowElement>(null);
  const dragRef = React.useRef<HTMLTableDataCellElement>(null);
  const [, drop] = useDrop({
    accept: 'Row',
    hover(item: { index: number }, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = dropRef.current.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset?.y || 0 - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveRow(dragIndex, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
    drop: async () => {
      await api.team.reorder_planned_tasks(projectId, {
        list: plannedTasks.map((plannedTask: { id: number }) => ({
          id: plannedTask.id,
          order: plannedTasks.indexOf(plannedTask),
        })),
      });
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    item: { index },
    type: 'Row',
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  preview(drop(dropRef));
  drag(dragRef);

  return (
    <S.Row
      ref={dropRef}
      className="bodyRow editable"
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <S.Cell className="description" ref={dragRef}>
        <S.DragHandleIcon />
        <input
          className="descriptionInput"
          defaultValue={task.description}
          onBlur={(e) => {
            if (task.description !== '') {
              handleEditTask({
                ...task,
                description: e.target.value,
              });
            } else {
              handleAddTask(
                {
                  ...task,
                  description: e.target.value,
                },
                index,
              );
            }
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.currentTarget.blur();
            }
          }}
        />
      </S.Cell>
      <S.Cell className="duration">
        <input
          className="durationInput"
          type="number"
          defaultValue={task.duration}
          onBlur={(e) => {
            if (task.description !== '') {
              handleEditTask({
                ...task,
                duration: e.target.value,
              });
            } else {
              // eslint-disable-next-line no-param-reassign
              task.duration = e.target.value;
            }
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.currentTarget.blur();
            }
          }}
        />
      </S.Cell>
      <S.Cell>{msToTimeString(task.timeSpent)}</S.Cell>
      <S.Cell>
        <div className="actions">
          <Tooltip title="Iniciar tarefa planejada" placement="bottom">
            <S.PlayArrowIcon
              className="icon"
              onClick={() => {
                setReplayTask({
                  description: task.description,
                  projectId,
                  plannedTaskId: task.id,
                });
              }}
            />
          </Tooltip>
          <Tooltip title="Remover tarefa planejada" placement="bottom">
            <S.CloseIcon
              className="icon"
              onClick={() => {
                setTaskToDelete(task.id);
                setOpenDeleteModal(true);
              }}
            />
          </Tooltip>
        </div>
      </S.Cell>
    </S.Row>
  );
};
