import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import TimerCalendar from '~/app/components/TimerCalendar';
import { msToTime } from '~/app/utils/duration';
import API from '~/services/api';
import useProjectsTasksStore from '~/store/projectsTasks';
import useReplayTaskStore from '~/store/replayTask';

import DeleteTaskModal from './DeleteTaskModal';
import {
  TaskContainer,
  TaskDescription,
  TaskDuration,
  TaskInterval,
  IconsContainer,
  CalendarButton,
  CloseIcon,
  PlayIcon,
  LockIcon,
  LockContainer,
  StyledProjectSelect,
  StyledPlannedTasksSelect,
  plannedTaskSelectStyle,
} from './styles';

import type { PlannedTask, Task as TaskType } from '~/models/types';
import type { ProjectSelectType } from '~/store/projectsTasks';

type TaskProps = {
  task: TaskType;
  projectId?: Number;
};

type PlannedTaskOption = {
  value: PlannedTask;
  label: string;
  disabled: boolean;
};
const UserBox = styled.span`
  color: #959595;
  font-size: 0.8rem;
  margin-right: 2rem;
`;
const UserName = styled.span`
  font-weight: 600;
`;
const Task = ({ task, projectId }: TaskProps) => {
  moment.locale('pt-br');
  const [showCalendar, setShowCalendar] = useState(false);
  const [projectSelected, setProjectSelected] = useState<ProjectSelectType>();
  const [plannedTasks, setPlannedTasks] = useState<PlannedTaskOption[]>();
  const [plannedTaskSelected, setPlannedTaskSelected] =
    useState<PlannedTaskOption | null>();
  const [isFetchingPlannedTasks, setIsFetchingPlannedTasks] = useState(false);
  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false);
  const [focused, setFocused] = useState(false);

  const { fetchProjectsTasks } = useProjectsTasksStore();
  const { setReplayTask } = useReplayTaskStore();
  const { projectSelect } = useProjectsTasksStore();

  const isDisabled =
    !!task.reportId ||
    projectSelect.find((p) => p.value.id === task.projectId)?.value.adminOnly;

  const updateTaskTime = async (startTime, endTime) => {
    if (endTime < startTime) {
      moment(endTime.setDate(endTime.getDate() + 1));
    }
    API.user.edit_task_time(task.id, startTime, endTime).then(() => {
      fetchProjectsTasks();
    });
  };

  const fetchPlannedTasks = async () => {
    setIsFetchingPlannedTasks(true);
    const response = await API.team.get_planned_tasks(task.projectId);
    const data = await response.json();
    const emptyOption = {
      value: { id: null },
      label: 'Nenhuma',
      disabled: false,
    };
    const plannedTaskData = [
      emptyOption,
      ...data.map((p) => ({
        value: p,
        label: p.description,
        disabled: p.disabled,
      })),
    ];
    setPlannedTasks(plannedTaskData);
    setIsFetchingPlannedTasks(false);
  };
  const openDeleteTaskModal = () => {
    setIsDeleteTaskModalOpen(true);
  };

  const handlePlannedTaskChange = (optionSelected: PlannedTaskOption) => {
    if (optionSelected.value.id) setPlannedTaskSelected(optionSelected);
    else {
      setPlannedTaskSelected(null);
    }
    API.user.edit_planned_task(task.id, optionSelected.value.id).then(() => {
      fetchProjectsTasks();
    });
  };
  const handleEditTaskDescription = (description) => {
    if (description !== task.description) {
      API.user.edit_task_description(task.id, description).then(() => {
        fetchProjectsTasks();
      });
    }
  };
  const handleProjectChange = (value) => {
    API.user.edit_task_project(task.id, value.value.id).then(() => {
      fetchProjectsTasks();
      setProjectSelected(value);
      setPlannedTaskSelected(null);
    });
  };
  useEffect(() => {
    const projectValue = projectSelect.find(
      (project) => project.value.id === task.projectId,
    );
    if (task.plannedTaskId) {
      setPlannedTaskSelected({
        value: task.plannedTask,
        label: task?.plannedTask?.description,
        disabled: false,
      });
    }

    setProjectSelected(projectValue);
  }, [projectSelect]);

  return (
    <TaskContainer
      onMouseEnter={() => {
        setFocused(true);
      }}
      onMouseLeave={() => {
        setFocused(false);
      }}
    >
      {isDisabled && (
        <Tooltip
          TransitionComponent={Zoom}
          title={`Esta tarefa não pode ser editada por estar vinculada a um ${
            task.reportId
              ? 'pagamento'
              : 'projeto gerenciado pelo administrador'
          }.`}
        >
          <LockContainer>
            <LockIcon size={24} />
          </LockContainer>
        </Tooltip>
      )}
      <TaskDescription
        type="text"
        disabled={isDisabled}
        placeholder="Sem descrição..."
        defaultValue={task.description}
        onBlur={(e) => {
          handleEditTaskDescription(e.currentTarget.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            // handleEditTaskDescription(e.currentTarget.value);
            e.currentTarget.blur();
          }
        }}
      />
      {projectId && (
        <UserBox>
          por <UserName>{task.user?.name}</UserName>
        </UserBox>
      )}

      <StyledProjectSelect
        options={projectSelect.filter((p) => !p.value.adminOnly)}
        value={projectSelected}
        isDisabled={isDisabled}
        onChange={handleProjectChange}
        placeholder="Projetos"
      />
      <StyledPlannedTasksSelect
        style={plannedTaskSelectStyle}
        options={plannedTasks || []}
        value={plannedTaskSelected}
        onChange={handlePlannedTaskChange}
        onMenuOpen={fetchPlannedTasks}
        isDisabled={isDisabled || false}
        noOptionsMessage={() =>
          'Não existem tarefas planejadas para este projeto'
        }
        isLoading={isFetchingPlannedTasks}
      />

      <CalendarButton
        onClick={() => {
          setShowCalendar(!isDisabled);
        }}
      >
        <TaskInterval>{`${moment(task.start).format('LT')} - ${moment(
          task.end,
        ).format('LT')}`}</TaskInterval>
        <TaskDuration>{msToTime(task.duration)}</TaskDuration>
        {showCalendar && (
          <TimerCalendar
            start={moment(task.start).toDate()}
            end={moment(task.end).toDate()}
            inTaskList
            closeCalendar={() => {
              setShowCalendar(false);
            }}
            updateTask={() => {}}
            createRangedTask={updateTaskTime}
          />
        )}
      </CalendarButton>

      <IconsContainer focused={isDisabled ? false : focused}>
        <PlayIcon
          size={24}
          data-testid="play-icon"
          onClick={() => {
            setReplayTask({
              description: task.description,
              projectId: task.projectId,
              plannedTaskId: Number(task.plannedTaskId) || 0,
            });
          }}
          style={{ cursor: 'pointer' }}
        />
        <CloseIcon
          size={24}
          data-testid="close-icon"
          onClick={() => {
            openDeleteTaskModal();
          }}
          disabled={isDisabled || false}
          style={{ cursor: 'pointer' }}
        />
      </IconsContainer>
      <DeleteTaskModal
        modalIsOpen={isDeleteTaskModalOpen}
        setModalOpen={setIsDeleteTaskModalOpen}
        taskId={task.id}
      />
    </TaskContainer>
  );
};

export default Task;
