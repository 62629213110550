import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  text-align: center;
`;

function getDaysByWeek({ year, month }) {
  const result = [];

  const firstMonthDay = new Date(year, month, 1);
  const lastMonthDay = new Date(year, month + 1, 0);
  const firstCalendarDay = new Date(firstMonthDay.getTime());
  firstCalendarDay.setDate(firstMonthDay.getDate() - firstMonthDay.getDay());
  const numberOfWeeks = Math.ceil(
    (firstMonthDay.getDay() + lastMonthDay.getDate()) / 7,
  );

  const date = new Date(firstCalendarDay.getTime());
  for (let i = 0; i < numberOfWeeks; i += 1) {
    result.push([]);
    for (let j = 0; j < 7; j += 1) {
      result[i].push(new Date(date.getTime()));
      date.setDate(date.getDate() + 1);
    }
  }

  return result;
}

function renderTableHeader() {
  const ColumnHeader = styled.th`
    font-weight: bold;
    color: #d6d6d6;
    margin: 0.3em;
    line-height: 2.5em;
  `;
  const weekDays = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];

  return (
    <tr>
      {weekDays.map((weekDay, index) => (
        <ColumnHeader key={index}>{weekDay}</ColumnHeader>
      ))}
    </tr>
  );
}

function renderDays({
  daysByWeek,
  mode,
  setMode,
  month,
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
}) {
  const DayContainer = styled.div`
    ${({ painted, isStartDate, isEndDate }) => {
      const defaultStyle = `
        margin: 0.3em 0;
        height: 2.5em;
      `;

      if (!painted) return defaultStyle;

      if (isStartDate && !isEndDate) {
        return `
          ${defaultStyle}
          background: linear-gradient(to right, transparent 0%, transparent 50%, #ffeaed 50%, #ffeaed 100%);
        `;
      }
      if (isEndDate && !isStartDate) {
        return `
          ${defaultStyle}
          background: linear-gradient(to right, #ffeaed 0%, #ffeaed 50%, transparent 50%, transparent 100%);
        `;
      }
      return `
          ${defaultStyle}
          background: #ffeaed;
        `;
    }}
  `;
  const Day = styled.span`
    display: inline-block;
    cursor: pointer;
    color: #7d7d7d;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    margin: 0 0.3em;
    line-height: 2.5em;
    &: hover {
      background-color: #f3f3f3;
    }
    ${({ today }) => (today ? `background-color: #d7d7d7;` : '')}
    ${({ selected }) =>
      selected
        ? `
        background-color: #fe3b5b;
        color: #ffffff;
        &:hover {
          background-color: #fe3b5b;
          color: #ffffff;
        }
      `
        : ''}
  `;

  function onDayClick(day) {
    if (mode === 'startDate') {
      if (endDate !== null && day.getTime() > endDate.getTime()) {
        onChangeStartDate(endDate);
        onChangeEndDate(day);
        setMode('startDate');
      } else {
        onChangeStartDate(day);
        setMode('endDate');
      }
    } else if (startDate !== null && day.getTime() < startDate.getTime()) {
      onChangeStartDate(day);
      onChangeEndDate(startDate);
      setMode('endDate');
    } else {
      onChangeEndDate(day);
      setMode('startDate');
    }
  }

  function renderDay(day) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (startDate === null && endDate === null) {
      return (
        <DayContainer>
          <Day
            today={day.getTime() === today.getTime()}
            onClick={() => onDayClick(day)}
          >
            {day.getDate()}
          </Day>
        </DayContainer>
      );
    }
    if (endDate === null) {
      return (
        <DayContainer isStartDate={day.getTime() === startDate.getTime()}>
          <Day
            selected={startDate.getTime() === day.getTime()}
            today={day.getTime() === today.getTime()}
            onClick={() => onDayClick(day)}
          >
            {day.getDate()}
          </Day>
        </DayContainer>
      );
    }
    return (
      <DayContainer
        painted={
          day.getTime() >= startDate.getTime() &&
          day.getTime() <= endDate.getTime()
        }
        isStartDate={day.getTime() === startDate.getTime()}
        isEndDate={day.getTime() === endDate.getTime()}
      >
        <Day
          selected={[startDate.getTime(), endDate.getTime()].includes(
            day.getTime(),
          )}
          today={day.getTime() === today.getTime()}
          onClick={() => onDayClick(day)}
        >
          {day.getDate()}
        </Day>
      </DayContainer>
    );
  }

  return (
    <>
      {daysByWeek.map((week, index) => (
        <tr key={index}>
          {week.map((day, weekIndex) => (
            <td key={weekIndex} style={{ padding: 0 }}>
              {day.getMonth() === month.getMonth() ? renderDay(day) : ''}
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}

export default function DatePicker(props) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const { month: monthProp } = props;
  const [year, month] = [monthProp.getFullYear(), monthProp.getMonth()];
  const [mode, setMode] = useState('startDate');

  const daysByWeek = getDaysByWeek({ year, month });
  return (
    <Table>
      <thead>{renderTableHeader()}</thead>
      <tbody>{renderDays({ daysByWeek, mode, setMode, ...props })}</tbody>
    </Table>
  );
}

DatePicker.propTypes = {
  month: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func,
};

DatePicker.defaulPropTypes = {
  endDate: null,
};
