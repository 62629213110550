import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Select from 'react-select';

const colourStyles = {
  control: (_, state) => ({
    display: 'flex',
    cursor: 'pointer',
    height: '32px',
    padding: '6px 6px 6px 0px',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: state.isFocused ? '#4F587A' : 'rgba(149, 149, 149, 0.2)',
  }),

  option: (styles, state) => ({
    ...styles,
    backgroundColor: !state.isDisabled && state.isFocused ? '#f6f7fb' : null,
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 400,
    color: '#5F5F5F',
    padding: '10px 48px 10px 20px',

    ':active': {
      ...styles[':active'],
      backgroundColor: '#F6F7Fb',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: '12px',
    fontWeight: 400,
    color: '#5F5F5F',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '12px',
    fontWeight: 400,
    color: '#5F5F5F',
  }),
  menu: (styles) => ({
    ...styles,
    whiteSpace: 'nowrap',
    width: 'auto',
    right: 0,
    padding: '0 0',
    borderRadius: 0,
    backgroundColor: '#fff',
    zIndex: 100,
  }),
};

const DropdownIndicator = () => (
  <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.8rem' }} />
);

const IndicatorSeparator = () => null;

export default function ProjectsFilterSelect(props) {
  return (
    <Select
      styles={colourStyles}
      components={{ DropdownIndicator, IndicatorSeparator }}
      isOptionDisabled={(option) => option.disabled}
      {...props}
    />
  );
}
