import React, { useState } from 'react';

import Input from '~/components/Input';

interface Props {
  name: string;
  onChange?: Function;
  initialHours?: number | null;
}

const EstimatedHours: React.FC<Props> = ({ name, onChange, initialHours }) => {
  const [hours, setHours] = useState(initialHours ? `${initialHours}h` : '0h');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === hours.slice(0, -1)) {
      e.target.value = e.target.value.slice(0, -1);
    }
    const pattern = /\d+/g;
    let regex: RegExpMatchArray | null | string = e.target.value.match(pattern); // TODO: Fix type
    if (regex === null) {
      setHours('00h');
      regex = '00h';
      return;
    }
    regex = regex.join('').replace(/^0/, '');
    setHours(regex.concat('h'));
    if(onChange)
      onChange(name, e.target.value.replace('h', ''));
  };

  return (
    <Input
      wrapperStyle={{
        width: '100%',
        margin: '0 0 0 0px',
      }}
      type="text"
      name={name}
      value={hours}
      onChange={(e) => handleInputChange(e)}
      placeholder="0h"
    />
  );
};

export default EstimatedHours;
