import React, { useState } from 'react';

import BottomMenu from './BottomMenu';
import Menu from './Menu';
import { SidebarData , SidebarProject } from './data';
import * as S from './styles';

import type {
  UserServiceType,
  LoginServiceType,
  TeamServiceType,
} from '~/models/ServicesTypes';
import type NotificationServiceType from '~/models/ServicesTypes';
import type { RootScope, State , ProjectService } from '~/models/types';




interface SideBarProps {
  NotificationService: NotificationServiceType;
  UserService: UserServiceType;
  LoginService: LoginServiceType;
  TeamService: TeamServiceType;
  $rootScope: RootScope;
  $state: State;
  ProjectService: ProjectService;
}

const SideBar: React.FC<SideBarProps> = ({
  $state,
  UserService,
  NotificationService,
  TeamService,
  LoginService,
  $rootScope,
  ProjectService
}: SideBarProps) => {
  const [route, setRoute] = useState($state.router.globals.$current.name);

  return (
    <S.Container>
      <S.SideBarContainer>
        <Menu
          UserService={UserService}
          $state={$state}
          itemMenu={SidebarData}
          projectMenu = {SidebarProject}
          route={route}
          setRoute={setRoute}
          ProjectService={ProjectService}
        />

        <BottomMenu
          UserService={UserService}
          LoginService={LoginService}
          TeamService={TeamService}
          NotificationService={NotificationService}
          $rootScope={$rootScope}
          $state={$state}
          setRoute={setRoute}
        />
      </S.SideBarContainer>
    </S.Container>
  );
};

export default SideBar;
