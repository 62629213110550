import styled from '@emotion/styled';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { MdExpandMore, MdList } from 'react-icons/md';

import Button from '~/components/Button';
import theme from '~/theme'

export const HeaderContainer = styled.div`
  margin: 0 75px 40px 44px;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 800px) {
    border-bottom: 0;
    flex-wrap: wrap;
  }
`;

export const TitleContainer = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  margin: 0 0 34px 44px;
  color: #4f587a;
`;

export const SelectsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  font-size: 12px;
  font-weight: normal;
  color: #959595;
  gap: 20px;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

export const SingleSelectContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 6px 6px 8px;
  gap: 5px;
  background-color: #ffffff;
  color: #5f5f5f;
  border: 1px solid rgba(149, 149, 149, 0.2);
  height: 20px;
`;

export const ExpandMoreIcon = styled(MdExpandMore)`
  color: #a0a0a0;
  font-size: 16px;
  vertical-align: bottom;
`;


export const ReportsTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  width: 100%;
  margin: 0 50px 34px 0;
  color: ${theme.colors.gray25};
`;

/* Notification Modal */
export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 16.41px;
  height: 20px;
  color: ${theme.colors.gray25};
  &:nth-child(2) {
    display: none
  }
  /* &:hover {
    &:nth-child(1) {
      display: none
    }
    
    &:nth-child(2) {
      display: inline-block
    }
  } */
`;

interface NotificationProps {
  onClick?: () => void
  onMouseOver?: () => void
  onMouseLeave?: () => void
}

export const NotificationIcon = styled(NotificationsNoneIcon)<NotificationProps>`
  height: 22px;
  color: ${theme.colors.gray25};
  `;
  
export const NotificationIconActive = styled(NotificationsIcon)`
  height: 22px;
  color: ${theme.colors.gray25};
  `;

export const ListIcon = styled(MdList)`
  font-size: 20px;
`;

export const FilterIcon = styled(FilterAltIcon)`
  font-size: 20px;
`;

export const FilterButton = styled(Button)`
  height: 32px;
  margin: 1px 0;
  font-size: 14px;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  :disabled:hover,
  :disabled:focus {
    background: #ff3c58;
  }
`;
