import styled from '@emotion/styled';

import { mq } from '~/helpers/responsive';

export const WelcomeImage = styled.div`
  ${mq({
    marginTop: ['36px', '36px', '36px', '36px'],
  })}
`;

export const WelcomeBox = styled.div`
  ${mq({
    width: [null, '282px', null, '485px'],
    marginRight: [null, '72px', null, '80px'],
  })}
`;