import styled from '@emotion/styled';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {
  MdCheck,
  MdPanoramaFishEye,
  MdFiberManualRecord,
  MdFileUpload,
  MdCloudUpload,
  MdHighlightOff,
  MdPictureAsPdf,
  MdFileDownload,
  MdInfoOutline,
} from 'react-icons/md';

import IconContainer from '../../../../components/IconContainer';
import Table from '../../../../components/Table';
import Tooltip from '../../../components/Tooltip';
import formatCurrency from '../../../utils/currency';
import { msToTime, msToHours } from '../../../utils/duration';

const Wrapper = styled(Table)`
  margin-bottom: 30px;

  .report-download {
    top: 0 !important;
    right: -10px !important;
    color: #c7c7c7 !important;
  }

  .circle-icon {
    font-size: 0.9rem;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .highlight-value {
    font-weight: bold;
    color: #555555;
  }

  .nowrap-column {
    white-space: nowrap;
  }

  input {
    display: none;
  }

  button,
  label {
    margin: 0 6px;
    padding: 8px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    border: none;
    background: none;
  }
`;

const isPaymentCompleted = (state) =>
  state === 'paid_without_invoice' || state === 'completed';

const isInvoicePending = (state) =>
  state === 'created' || state === 'paid_without_invoice';

const isInvoiceCompleted = (state) =>
  state === 'invoice_uploaded' || state === 'completed';

const PaymentList = ({
  header,
  isAdmin,
  reportsList,
  loggedUser,
  pay,
  upload,
  download,
}) => {
  const [reportsFile, setReports] = React.useState({});

  const renderInvoiceStatus = React.useCallback(
    (report) => {
      const { state, user, danfeFile, id } = report;

      const uploadFile = (event) => {
        setReports({
          ...reportsFile,
          [report.id]: event.currentTarget.files[0],
        });
      };

      const removeFile = () => {
        setReports({
          ...reportsFile,
          [report.id]: null,
        });
      };

      return (
        <td width="15%" className="center">
          {isInvoicePending(state) && user.id === loggedUser.id && (
            <div>
              {reportsFile[report.id] && reportsFile[report.id] ? (
                <div>
                  <button
                    type="button"
                    onClick={() => upload(report, reportsFile[report.id])}
                  >
                    <Tooltip tooltip="Upload" placement="left">
                      <IconContainer tinted>
                        <MdCloudUpload />
                      </IconContainer>
                    </Tooltip>
                  </button>
                  <button type="button" onClick={() => removeFile()}>
                    <Tooltip tooltip="Remover" placement="right">
                      <IconContainer tinted>
                        <MdHighlightOff />
                      </IconContainer>
                    </Tooltip>
                  </button>
                </div>
              ) : (
                <div>
                  <input
                    id={`input-file-${id}`}
                    type="file"
                    onChange={(e) => uploadFile(e)}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor={`input-file-${id}`}>
                    <Tooltip tooltip="Selecionar Arquivo" placement="left">
                      <IconContainer tinted={!!danfeFile}>
                        <MdFileUpload />
                      </IconContainer>
                    </Tooltip>
                  </label>
                </div>
              )}
            </div>
          )}
          {isInvoiceCompleted(state) && (
            <div style={{ position: 'relative' }}>
              <IconContainer>
                <MdPictureAsPdf />
              </IconContainer>
              {/* eslint-disable-next-line react/no-unknown-property */}
              <div className="actions report-download" layout="row">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <a onClick={() => download(report)}>
                  <Tooltip tooltip="Download" placement="left">
                    <IconContainer>
                      <MdFileDownload />
                    </IconContainer>
                  </Tooltip>
                </a>
              </div>
            </div>
          )}
          {state === 'created' && isAdmin && user.id !== loggedUser.id && (
            <IconContainer>
              <MdFiberManualRecord className="circle-icon" />
            </IconContainer>
          )}
        </td>
      );
    },
    [reportsFile],
  );

  return (
    <Wrapper>
      <thead>
        <tr>
          <th width="20%">
            <b>{header || (isAdmin ? 'Faturas Abertas' : 'Período')}</b>
          </th>
          <th width="10%" className="center">
            Horas
          </th>
          <th width="10%" className="center nowrap-column">
            Valor da Hora
          </th>
          <th width="10%" className="center">
            Subtotal
          </th>
          <th width="10%" className="center">
            <div className="icon-wrapper">
              Desconto
              <Tooltip
                tooltip={
                  'Desconto proveniente de benefícios que estão a cargo' +
                  '\n' +
                  'do colaborador (ex: Plano de Saúde de dependentes).'
                }
                placement="top"
                lineHeight="1.5em"
              >
                <MdInfoOutline />
              </Tooltip>
            </div>
          </th>
          <th width="10%" className="center">
            <div className="icon-wrapper nowrap-column">
              Valor Final
              <Tooltip
                tooltip={
                  'Valor final calculado após os descontos.' +
                  '\n' +
                  'Este valor deverá ser usado na emissão da nota fiscal.'
                }
                placement="top"
                lineHeight="1.5em"
              >
                <MdInfoOutline />
              </Tooltip>
            </div>
          </th>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th width="5%" />
          <th width="15%" className="center">
            Relatório
          </th>
          <th width="15%" className="center nowrap-column">
            Nota Fiscal
          </th>
          <th width="15%" className="center">
            Pagamento
          </th>
        </tr>
      </thead>
      <tbody>
        {!reportsList.length && (
          <tr>
            <td className="center" colSpan="8">
              Até agora nenhum pagamento foi registrado para este período.
            </td>
          </tr>
        )}
        {reportsList.map((report) => {
          const {
            id,
            user,
            referenceDate,
            duration,
            hourValue,
            currentDiscount,
            state,
          } = report;
          return (
            <tr key={id}>
              <td width="20%">
                {isAdmin
                  ? user.name
                  : moment(referenceDate).locale('pt').format('MMMM')}
              </td>
              <td width="10%" className="center">
                {msToTime(duration)}
              </td>
              <td width="10%" className="center">
                {formatCurrency(hourValue)}
              </td>
              <td width="10%" className="center">
                {formatCurrency(msToHours(duration) * hourValue)}
              </td>
              <td width="10%" className="center">
                {formatCurrency(currentDiscount)}
              </td>
              <td width="10%" className="center">
                <strong className="highlight-value">
                  {formatCurrency(
                    msToHours(duration) * hourValue - currentDiscount,
                  )}
                </strong>
              </td>

              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td width="5%" />

              {/* Indicadores de Estado do Pagamento */}
              <td width="15%" className="center">
                <IconContainer>
                  <MdCheck />
                </IconContainer>
              </td>
              {renderInvoiceStatus(report)}
              <td width="15%" className="center">
                <div>
                  {state === 'invoice_uploaded' && (
                    <IconContainer tinted>
                      {isAdmin ? (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                        <a onClick={() => pay(report)}>
                          <MdPanoramaFishEye />
                        </a>
                      ) : (
                        <MdFiberManualRecord className="circle-icon" />
                      )}
                    </IconContainer>
                  )}
                  {isPaymentCompleted(state) && (
                    <IconContainer>
                      <MdCheck />
                    </IconContainer>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Wrapper>
  );
};

PaymentList.propTypes = {
  header: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  reportsList: PropTypes.array.isRequired,
  loggedUser: PropTypes.object.isRequired,
  download: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  pay: PropTypes.func,
};

PaymentList.defaultProps = {
  header: null,
  pay: null,
};

export default PaymentList;
