import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import React from 'react';


import { msToTimeString } from '~/app/utils/duration';
import useProjectsTasksStore from '~/store/projectsTasks';

import { ProjectsBarGraph, ProjectTime } from './styles';

const TaskDurationBar = () => {
  const { projectsDurationInMonth } = useProjectsTasksStore();
  return (
    <ProjectsBarGraph>
      {projectsDurationInMonth?.projectsDuration?.map((project) => (
        <Tooltip
          key={project.id}
          TransitionComponent={Zoom}
          title={`${project.name}: ${msToTimeString(project.duration)}`}
        >
          <ProjectTime
            key={project.id}
            totalTime={projectsDurationInMonth.totalMonthlyDuration}
            project={project}
          >
            <span style={{ display: 'inline-flex' }}>
              {(100 * project.duration) /
                projectsDurationInMonth.totalMonthlyDuration >
                10 && msToTimeString(project.duration)}
            </span>
          </ProjectTime>
        </Tooltip>
      ))}
    </ProjectsBarGraph>
  );
};

export default TaskDurationBar;
